import { create } from "zustand"
import { createWithEqualityFn } from "zustand/traditional"
import { persist } from "zustand/middleware"
import type { RiskBarsProps } from "@levana-protocol/ui/RiskBars"

import type { MarketId } from "@perps/sdk/types"
import type { DeltaPrice } from "@future/market/price/types"
import type { MarketHours } from "@future/market/availability"
import type { MarketVolume } from "@future/market/volume/types"
import type { Amount } from "@future/numerics/amount"
import type { Collateral, LpToken, Percentage, Usd } from "@future/numerics"
import {
  selectedTags,
  type ListOptionsTags,
  formatTags,
  deselectTag,
  type ListOptionsDropdown,
} from "@future/utils/listOptionsSlice"
import type { FullMarketInfo } from "@perps/sdk/client/CosmosClient"
import type { EarnMarket } from "@future/market/earnChart/types"

import type { BoostedItem } from "./Boosted"

export type MarketsOptionsCategory = "all" | "earn" | "trade"

export interface MarketsListItem {
  fullMarketInfo: FullMarketInfo
  marketId: MarketId
  marketPriceDelta: DeltaPrice | undefined
  marketHours: MarketHours
  marketVolume: MarketVolume | undefined
  earnMarket: EarnMarket | undefined
  openLongPositionsCount: number | undefined
  openShortPositionsCount: number | undefined
  activeCollateral: Collateral | undefined
  depositCollateral: Collateral | undefined
  lpCollateral: Collateral
  lpCollateralUsd: Usd
  lpInvested: LpToken | undefined
  lpInvestedCollateral: Collateral | undefined
  lpInvestedUsd: Usd | undefined
  lpApr: Percentage | undefined
  xlpCollateral: Collateral
  xlpCollateralUsd: Usd
  xlpInvested: LpToken | undefined
  xlpInvestedCollateral: Collateral | undefined
  xlpInvestedUsd: Usd | undefined
  xlpApr: Percentage | undefined
  lockedLiquidity: Percentage
  pnl: Collateral | undefined
  totalYield: Collateral | undefined
  totalYieldUsd: Usd | undefined
  withdrawableYield: Collateral | undefined
  withdrawableYieldUsd: Usd | undefined
  sharpeRatio: Amount | undefined
  liquidityRisk: RiskBarsProps["level"]
  boostedItems: BoostedItem[]
  walletBalance: Collateral | undefined
}

interface MarketsStoreProps {
  category: MarketsOptionsCategory
  marketIds: MarketId[]
  items: Record<MarketId, MarketsListItem>
  totalInvested: Usd | undefined
}

export const useMarketsStore = createWithEqualityFn<MarketsStoreProps>()(
  persist(
    (_) => ({
      category: "trade",
      marketIds: [],
      items: {},
      totalInvested: undefined,
    }),
    {
      name: "Markets",
      partialize: (state) => ({
        category: state.category,
      }),
    },
  ),
)

// All

export const marketsAllFilters = ["new", "stable", "crypto"] as const

export type MarketsAllFilter = (typeof marketsAllFilters)[number]

export const marketsAllSorts = [
  "relevance",
  "market",
  "price",
  "change",
  "volume",
  "openInterest",
  "fundingRate",
  "totalLiquidity",
  "roi",
  "risk",
] as const

export type MarketsAllSort = (typeof marketsAllSorts)[number]

export const defaultMarketsAllSort: MarketsAllSort = "relevance"

// Trade

export const marketsTradeFilters = ["new", "stable", "crypto"] as const

export type MarketsTradeFilter = (typeof marketsTradeFilters)[number]

export const marketsTradeSorts = [
  "relevance",
  "market",
  "price",
  "change",
  "volume",
  "openInterest",
  "fundingRate",
  "marketSentiment",
] as const

export type MarketsTradeSort = (typeof marketsTradeSorts)[number]

export const defaultMarketsTradeSort: MarketsTradeSort = "relevance"

// Earn

export const marketsEarnFilters = [
  "new",
  "invested",
  // "boosted"
] as const

export type MarketsEarnFilter = (typeof marketsEarnFilters)[number]

export const marketsEarnSorts = [
  "relevance",
  "market",
  "totalLiquidity",
  "lockedLiquidity",
  "roi",
  "sharpeRatio",
  "risk",
] as const

export type MarketsEarnSort = (typeof marketsEarnSorts)[number]

export const defaultMarketsEarnSort: MarketsEarnSort = "relevance"

export type MarketsOptionsStoreProps = {
  filterText: string
} & ListOptionsTags<"all", MarketsAllFilter> &
  ListOptionsDropdown<"all", MarketsAllSort> &
  ListOptionsTags<"trade", MarketsTradeFilter> &
  ListOptionsDropdown<"trade", MarketsTradeSort> &
  ListOptionsTags<"earn", MarketsEarnFilter> &
  ListOptionsDropdown<"earn", MarketsEarnSort>

export const useMarketsOptionsStore = create<MarketsOptionsStoreProps>()(
  persist(
    (set, get) => {
      return {
        allTags: formatTags([...marketsAllFilters]),
        setAllTags: (tags) => {
          set({ allTags: formatTags(tags) })
        },
        allSelectedTags: (filter) => {
          return selectedTags(get().allTags, filter)
        },
        allDeselectedTags: (selectedTags) => {
          set({ allTags: deselectTag(get().allTags, selectedTags) })
        },
        allDropdown: defaultMarketsAllSort,
        setAllDropdown: (dropdown) => {
          set({ allDropdown: dropdown })
        },

        tradeTags: formatTags([...marketsTradeFilters]),
        setTradeTags: (tags) => {
          set({ tradeTags: formatTags(tags) })
        },
        tradeSelectedTags: (filter) => {
          return selectedTags(get().tradeTags, filter)
        },
        tradeDeselectedTags: (selectedTags) => {
          set({ tradeTags: deselectTag(get().tradeTags, selectedTags) })
        },
        tradeDropdown: defaultMarketsTradeSort,
        setTradeDropdown: (dropdown) => {
          set({ tradeDropdown: dropdown })
        },

        earnTags: formatTags([...marketsEarnFilters]),
        setEarnTags: (tags) => {
          set({ earnTags: formatTags(tags) })
        },
        earnSelectedTags: (filter) => {
          return selectedTags(get().earnTags, filter)
        },
        earnDeselectedTags: (selectedTags) => {
          set({ earnTags: deselectTag(get().earnTags, selectedTags) })
        },
        earnDropdown: defaultMarketsEarnSort,
        setEarnDropdown: (dropdown) => {
          set({ earnDropdown: dropdown })
        },

        filterText: "",
      }
    },
    {
      name: "MarketsListOptions",
      version: 3,
      partialize: (state) => {
        const { filterText, ...options } = state
        return options
      },
    },
  ),
)
