import Box from "@mui/joy/Box"
import Stack from "@mui/joy/Stack"
import { useTranslation } from "react-i18next"

import Form from "@common/form/Form"
import FormCaptcha from "@common/form/FormCaptcha"
import FormSubmitButton from "@common/form/FormSubmitButton"
import type { ContextStoreProp } from "@future/context/store"

import { useController } from "./controller"

export interface FaucetFormProps extends ContextStoreProp<"standard"> {
  submitFromCaptcha?: boolean
  getCw20s: () => string[]
  onSubmit?: () => void
  onSuccess?: () => void
  onFailure?: (error: unknown) => void
}

const FaucetForm = (props: FaucetFormProps) => {
  const { submitFromCaptcha } = props
  const controller = useController(props)
  const { t } = useTranslation("faucet")

  return (
    <Form
      form={controller.form}
      formRef={controller.formRef}
      onSubmit={controller.handleSubmit}
    >
      <Stack gap={2}>
        {/* {!submitFromCaptcha && (
            <FormSection title={t("form.address")}>
              <FormInput state={controller.fields.address} />
            </FormSection>
          )} */}
        <Box sx={{ mx: "auto" }}>
          <FormCaptcha state={controller.fields.captcha} />
        </Box>
        {!submitFromCaptcha && (
          <FormSubmitButton aria-label="faucet submit">
            {t("form.submit")}
          </FormSubmitButton>
        )}
      </Stack>
    </Form>
  )
}

export default FaucetForm
