import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const DropletsIcon = (props: SvgIconProps) => {
  const Svg = () => (
    <g>
      <g clipPath="url(#clip0_8588_5233)">
        <path
          d="M8.88337 1.93602C10.5989 0.306223 13.3804 0.306224 15.096 1.93602L19.4823 6.10298C23.6203 10.0341 23.6203 16.4077 19.4823 20.3389C15.3442 24.2701 8.63514 24.2701 4.49709 20.3389C0.359042 16.4077 0.359044 10.0341 4.49709 6.10298L8.88337 1.93602Z"
          fill="#6039FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.6276 1.43124L20.0139 5.5982C24.4452 9.80786 24.4452 16.6331 20.0139 20.8428C15.5826 25.0524 8.3982 25.0524 3.96698 20.8428C-0.464254 16.6331 -0.464253 9.80786 3.96698 5.59819L8.35324 1.43124C10.362 -0.477081 13.6189 -0.477079 15.6276 1.43124ZM15.0968 1.93554C13.3812 0.305746 10.5997 0.305745 8.8841 1.93554L4.49782 6.1025C0.359775 10.0336 0.359773 16.4072 4.49782 20.3384C8.63588 24.2696 15.345 24.2696 19.483 20.3384C23.6211 16.4072 23.6211 10.0337 19.483 6.1025L15.0968 1.93554Z"
          fill="#6039FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.7769 4.6894C12.2591 4.23374 11.4593 4.23374 10.9414 4.6894L6.9143 8.23267C6.71453 8.40845 6.57534 8.63747 6.51499 8.88971L5.29847 13.9746C5.24856 14.1832 5.25528 14.3922 5.30946 14.5865C5.31184 14.7338 5.3413 14.8837 5.4012 15.0307L6.26704 17.1545C6.38262 17.4379 6.6004 17.6732 6.88157 17.8184L11.2147 20.0557C11.6169 20.2634 12.1025 20.2634 12.5047 20.0557L16.8379 17.8184C17.119 17.6732 17.3368 17.4379 17.4524 17.1545L18.3183 15.0307C18.3787 14.8824 18.4081 14.7311 18.41 14.5825C18.4632 14.3893 18.4695 14.1817 18.42 13.9746L17.2034 8.88971C17.143 8.63747 17.0038 8.40845 16.8041 8.23267L12.7769 4.6894ZM13.123 8.58763C13.6116 9.09539 14.248 9.29838 14.5444 9.041C14.8407 8.78364 14.6849 8.16338 14.1964 7.65562C13.7078 7.14786 13.0714 6.94487 12.775 7.20224C12.4787 7.45961 12.6345 8.07986 13.123 8.58763ZM15.994 10.5014C15.8001 10.6697 15.384 10.537 15.0644 10.205C14.7449 9.87289 14.643 9.46728 14.8369 9.29897C15.0307 9.13066 15.4468 9.2634 15.7663 9.59545C16.0858 9.9275 16.1877 10.3331 15.994 10.5014Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_8588_5233">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </g>
  )

  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <Svg />
    </SvgIcon>
  )
}

export default DropletsIcon
