import { queryOptions, useQuery, useQueries } from "@tanstack/react-query"
import { useStore } from "zustand"

import type CosmosClient from "@perps/sdk/client/CosmosClient"
import type { ContextStoreProp } from "@future/context/store"
import type { MarketId } from "@perps/sdk/types"

import type { QueryOptions } from "./types"

export const positionActionHistoryQueryOptions = (
  chainClient: CosmosClient,
  positionId: string,
  marketAddress: string,
) => {
  return queryOptions({
    queryKey: ["positionActionHistory", positionId, marketAddress],
    retry: true,
    initialData: [],
    queryFn: async (context) => {
      return chainClient.queryPositionActionHistory({
        position: positionId,
        marketAddress,
        abortSignal: context.signal,
      })
    },
  })
}

export const usePositionActionHistoryQuery = (
  props: ContextStoreProp<"standard"> & {
    positionId: string
    marketAddress: string
  },
  options?: QueryOptions,
) => {
  const client = useStore(props.contextStore, (state) => state.chain.client)
  return useQuery({
    ...positionActionHistoryQueryOptions(
      client,
      props.positionId,
      props.marketAddress,
    ),
    ...options,
  })
}

export const usePositionActionHistoryQueries = (
  props: ContextStoreProp<"standard"> & {
    positions: { id: string; marketId: MarketId }[]
  },
) => {
  const client = useStore(props.contextStore, (state) => state.chain.client)
  const markets = useStore(props.contextStore, (state) => state.markets)
  const marketPositions: { positionId: string; marketAddress: string }[] = []

  for (const position of props.positions) {
    const market = markets.get(position.marketId)

    if (market) {
      marketPositions.push({
        positionId: position.id,
        marketAddress: market.config.marketAddress,
      })
    }
  }

  return useQueries({
    queries: marketPositions.map(({ positionId, marketAddress }) =>
      positionActionHistoryQueryOptions(client, positionId, marketAddress),
    ),
  })
}
