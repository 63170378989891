import Box from "@mui/joy/Box"
import Card from "@mui/joy/Card"
import Stack from "@mui/joy/Stack"
import Typography from "@mui/joy/Typography"
import { useTranslation } from "react-i18next"
import { useAccount } from "@levana/chain/account"
import NavigationModal, {
  NavigationModalDynamicItem,
} from "@levana-protocol/ui/NavigationModal"
import type { NavigationModalControlProps } from "@levana-protocol/ui/NavigationModalDialog"

import type { ContextStoreProp } from "@future/context/store"
import ReferralIcon from "@future/header/icons/ReferralIcon"
import ConnectButton from "@future/connect/ConnectButton"

import { useReferralStore } from "./referralCode"
import ReferralLinkSection from "./ReferralLinkSection"
import TotalRewardsSection from "./TotalRewardsSection"
import ReferralCountSection from "./ReferralCountSection"
import ReferrerSection from "./ReferrerSection"

const ReferralModal = (props: ContextStoreProp<"standard">) => {
  const { t } = useTranslation("common")

  return (
    <NavigationModal rootId={ReferralContent.name}>
      {(modal) => (
        <NavigationModalDynamicItem
          id={ReferralContent.name}
          title={t("menu.general.referral")}
          icon={ReferralIcon}
          canClose
        >
          <ReferralContent contextStore={props.contextStore} modal={modal} />
        </NavigationModalDynamicItem>
      )}
    </NavigationModal>
  )
}

interface ReferralContentProps extends ContextStoreProp<"standard"> {
  modal: NavigationModalControlProps
}

const ReferralContent = (props: ReferralContentProps) => {
  const { t } = useTranslation("perpsCommon")
  const { data: account } = useAccount()
  const externalReferralCode = useReferralStore(
    (state) => state.externalReferralCode,
  )

  return (
    <Stack spacing={3}>
      {externalReferralCode && (
        <Typography level="body-lg" sx={{ textAlign: "center" }}>
          {t("referral.externalReferralMessage")}
        </Typography>
      )}

      {!account && <ConnectButton />}

      <Stack
        spacing={2}
        sx={[
          !account && {
            filter: "blur(6px)",
            pointerEvents: "none",
          },
        ]}
      >
        <ReferralLinkSection />

        <Box
          sx={{
            display: "grid",
            gridAutoFlow: "column",
            gridAutoColumns: "1fr",
            gap: 1,
          }}
        >
          <Card variant="soft">
            <TotalRewardsSection
              contextStore={props.contextStore}
              onCloseModal={props.modal.close}
            />
          </Card>
          <Card variant="soft">
            <ReferralCountSection contextStore={props.contextStore} />
          </Card>
        </Box>

        <ReferrerSection contextStore={props.contextStore} />
      </Stack>
    </Stack>
  )
}

export default ReferralModal
