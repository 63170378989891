export var ErrorMessage;
(function (ErrorMessage) {
    ErrorMessage["disableCtrl"] = "Disable the Ctrl Wallet extension, refresh and connect again.";
    ErrorMessage["noAccount"] = "The wallet does not have an account";
    ErrorMessage["noBalance"] = "The account has no balance";
    ErrorMessage["insufficientGas"] = "Insufficient gas";
    ErrorMessage["incorrectGasCalculated"] = "Incorrect gas calculated";
    ErrorMessage["incorrectGasSet"] = "Incorrect gas set";
    ErrorMessage["executeContract"] = "Error executing contract";
    ErrorMessage["viewingExecute"] = "Unable to execute transactions with a view only wallet";
})(ErrorMessage || (ErrorMessage = {}));
export var WalletErrorType;
(function (WalletErrorType) {
    WalletErrorType["noAccount"] = "noAccount";
    WalletErrorType["outOfSyncAccount"] = "outOfSyncAccount";
    WalletErrorType["insufficientFunds"] = "insufficientFunds";
    WalletErrorType["invalidCurrencies"] = "invalidCurrencies";
    WalletErrorType["rejected"] = "rejected";
    WalletErrorType["unknown"] = "unknown";
})(WalletErrorType || (WalletErrorType = {}));
