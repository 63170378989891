var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ChainId as InjectiveChainId, EthereumChainId, } from "@injectivelabs/ts-types";
import { chainIdToInjectiveChainId } from "./chain";
import { useInjectiveStore } from "./store";
import { WalletType } from "../shared/wallet";
import { useAccountStore, useChainStore } from "../shared/store";
import { getChain } from "../shared/chain";
export const walletTypeToInjective = (walletType) => __awaiter(void 0, void 0, void 0, function* () {
    const { Wallet: InjectiveWalletType } = yield import("@injectivelabs/wallet-ts");
    switch (walletType) {
        case WalletType.compass:
            return InjectiveWalletType.Keplr;
        case WalletType.cosmostation:
            return InjectiveWalletType.Cosmostation;
        case WalletType.cosmostationMobile:
            return InjectiveWalletType.WalletConnect;
        case WalletType.keplr:
            return InjectiveWalletType.Keplr;
        case WalletType.keplrMobile:
            return InjectiveWalletType.WalletConnect;
        case WalletType.leap:
            return InjectiveWalletType.Leap;
        case WalletType.leapMobile:
            return InjectiveWalletType.WalletConnect;
        case WalletType.metamask:
            return InjectiveWalletType.Metamask;
        case WalletType.okx:
            return InjectiveWalletType.OkxWallet;
        case WalletType.walletConnect:
            return InjectiveWalletType.WalletConnect;
        case WalletType.viewing:
            return InjectiveWalletType.Keplr;
        case WalletType.xdefi:
            return InjectiveWalletType.Keplr;
    }
});
export const setInjectiveWalletStrategy = (chainId) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c;
    const injectiveChainId = chainIdToInjectiveChainId(chainId);
    if (injectiveChainId) {
        const chain = getChain(chainId);
        const previousChainId = useChainStore.getState().chainId;
        const previousRpc = (_b = (_a = useInjectiveStore.getState().walletStrategy) === null || _a === void 0 ? void 0 : _a.args.ethereumOptions) === null || _b === void 0 ? void 0 : _b.rpcUrl;
        if (chainId !== previousChainId || chain.rpc !== previousRpc) {
            const { WalletStrategy } = yield import("@injectivelabs/wallet-ts");
            const walletType = (_c = useAccountStore.getState().walletType()) !== null && _c !== void 0 ? _c : WalletType.keplr;
            const injectiveWalletType = yield walletTypeToInjective(walletType);
            const walletStrategy = new WalletStrategy({
                chainId: injectiveChainId,
                wallet: injectiveWalletType,
                ethereumOptions: {
                    ethereumChainId: EthereumChainId.Injective,
                    rpcUrl: chain.rpc,
                },
            });
            useInjectiveStore.setState({ walletStrategy });
        }
    }
    else {
        useInjectiveStore.setState({ walletStrategy: undefined });
    }
});
export const updateEthereumChainIfNeeded = (chainId) => __awaiter(void 0, void 0, void 0, function* () {
    const injectiveChainId = chainIdToInjectiveChainId(chainId);
    if (!injectiveChainId) {
        return;
    }
    const { walletStrategy } = useInjectiveStore.getState();
    if (!walletStrategy) {
        return;
    }
    const walletEthereumChainId = yield (() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            return Number.parseInt(yield walletStrategy.getEthereumChainId(), 16);
        }
        catch (_a) {
            // Not an ethereum wallet
            return;
        }
    }))();
    if (walletEthereumChainId === undefined) {
        return;
    }
    const ethereumChainId = injectiveChainId === InjectiveChainId.Mainnet
        ? EthereumChainId.Mainnet
        : EthereumChainId.Sepolia;
    if (ethereumChainId !== walletEthereumChainId) {
        console.log(`Switching chain from '${ethereumChainId}' to '${walletEthereumChainId}'`);
        try {
            const { UtilsWallets } = yield import("@injectivelabs/wallet-ts/exports");
            yield UtilsWallets.updateMetamaskNetwork(ethereumChainId);
        }
        catch (error) {
            const knownError = error;
            // This error code indicates that the chain has not been added to MetaMask.
            if (knownError.code === 4902) {
                console.log("This network is not available in your metamask, please add it");
            }
            console.warn("Failed to switch to the network");
        }
    }
});
