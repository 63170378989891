import Box from "@mui/joy/Box"
import Modal from "@mui/joy/Modal"
import { useTheme } from "@mui/joy/styles"
import type { BasePlacement } from "@popperjs/core"
import {
  useEffect,
  useRef,
  useCallback,
  useMemo,
  type PropsWithChildren,
  Fragment,
} from "react"
import { create } from "zustand"
import {
  ModalPresenter,
  useModal,
  useModalTopKey,
} from "@levana-protocol/utils/modal"

import { localStorageStateBuilder } from "@common/storage/localStorage"
import SpotlightDialog from "@common/ui/SpotlightDialog"
import SpotlightDialogTitle from "@common/ui/SpotlightDialogTitle"
import SpotlightDialogButton from "@common/ui/SpotlightDialogButton"
import SpotlightDialogContent from "@common/ui/SpotlightDialogContent"

export type OnboardCategory = string
export type OnboardStep = string | number

interface UseOnboardStoreProps {
  queue: Map<OnboardCategory, OnboardStep[]>
  setQueue: (category: OnboardCategory, steps: OnboardStep[]) => void
  start: (category: OnboardCategory) => void
  pause: (category: OnboardCategory) => void
  next: (category: OnboardCategory) => void
  stop: (category: OnboardCategory) => void
  /**
   * The most recent completed step for a given category
   */
  completedStep: (category: OnboardCategory) => OnboardStep | undefined
  /**
   * The active step
   *
   * It's also helpful for rendering on changes.
   */
  active: { category: OnboardCategory; step: OnboardStep } | undefined
  /**
   * Is a category able to onboard
   *
   * If so, the value will be in the array
   */
  enabled: OnboardCategory[]
}

export const useOnboardStore = create<UseOnboardStoreProps>((set, get) => {
  const enable = (category: OnboardCategory) => {
    const enabled = [...get().enabled]

    if (!enabled.includes(category)) {
      set({ enabled: [...enabled, category] })
    }
  }

  const disable = (category: OnboardCategory) => {
    const enabled = [...get().enabled]
    const index = enabled.indexOf(category)

    if (index > -1) {
      enabled.splice(index, 1)
      set({ enabled: enabled })
    }
  }

  const nextStep = (category: OnboardCategory) => {
    const onboard = onboardState.get()
    const steps = get().queue.get(category) ?? []
    const index = category in onboard ? steps.indexOf(onboard[category]) + 1 : 0

    if (steps.length > index) {
      return steps[index]
    }
  }

  const lastStep = (category: OnboardCategory) => {
    const steps = get().queue.get(category)
    return steps?.at(steps.length - 1)
  }

  const setActive = (category: OnboardCategory, step: OnboardStep) => {
    set({ active: { category, step } })
    enable(category)
  }

  return {
    queue: new Map(),
    setQueue: (category: OnboardCategory, steps: OnboardStep[]) => {
      set({ queue: new Map([...get().queue, [category, steps]]) })
    },
    start: (category: OnboardCategory) => {
      const step = nextStep(category)

      if (step) {
        setActive(category, step)
      }
    },
    pause: (category: OnboardCategory) => {
      disable(category)
    },
    next: (category: OnboardCategory) => {
      let step = nextStep(category)

      if (step) {
        onboardState.set({ ...onboardState.get(), [category]: step })
      }

      step = nextStep(category)

      if (step) {
        setActive(category, step)
      } else {
        disable(category)
      }
    },
    stop: (category: OnboardCategory) => {
      const step = lastStep(category)

      if (step) {
        onboardState.set({ ...onboardState.get(), [category]: step })
      }

      disable(category)
    },
    completedStep: (category: OnboardCategory) => {
      const onboard = onboardState.get()

      if (category in onboard) {
        return onboard[category]
      }
    },
    active: undefined,
    enabled: [],
  }
})

const onboardState = localStorageStateBuilder<
  Record<OnboardCategory, OnboardStep>
>("LevanaOnboard", {})

export const useCreateOnboardCategory = <Step extends string>(
  category: OnboardCategory,
  steps: Step[],
) => {
  const categoryRef = useRef(category)
  const stepsRef = useRef(steps)

  const setQueue = useOnboardStore((store) => store.setQueue)
  const start = useOnboardStore((store) => store.start)
  const pause = useOnboardStore((store) => store.pause)
  const next = useOnboardStore((store) => store.next)
  const stop = useOnboardStore((store) => store.stop)
  const completedStep = useOnboardStore((store) => store.completedStep)(
    category,
  )
  const active = useOnboardStore((store) => store.active)
  const enabled = useOnboardStore((store) => store.enabled).includes(category)

  return {
    setup: useCallback(
      () => setQueue(categoryRef.current, stepsRef.current),
      [setQueue],
    ),
    start: useCallback(() => start(categoryRef.current), [start]),
    pause: useCallback(() => pause(categoryRef.current), [pause]),
    next: useCallback(() => {
      if (enabled) {
        next(categoryRef.current)
      }
    }, [next, enabled]),
    stop: useCallback(() => {
      if (enabled) {
        stop(categoryRef.current)
      }
    }, [stop, enabled]),
    step: active?.category === category ? (active.step as Step) : undefined,
    enabled,
    done: completedStep === steps[steps.length - 1],
  }
}

export const useOnboardStepLayout = () => {
  const theme = useTheme()

  const zIndex = useMemo(() => {
    return theme.zIndex.tooltip - 1
  }, [theme.zIndex.tooltip])

  const updateOnboardStepLayout = useCallback(
    (element: HTMLElement, active: boolean, scroll = false) => {
      if (active) {
        element.style.zIndex = `${zIndex}`

        if (scroll) {
          const pageHeight = window.innerHeight
          const spotlightRect = element.getBoundingClientRect()

          window.scrollBy({
            top: spotlightRect.top - pageHeight / 2 + spotlightRect.height / 2,
            left: 0,
            behavior: "smooth",
          })
        }
      } else {
        element.style.zIndex = "unset"
      }
    },
    [zIndex],
  )

  return { updateOnboardStepLayout, zIndex }
}

interface OnboardStepPresenterProps<Step extends string>
  extends PropsWithChildren {
  onboard: ReturnType<typeof useCreateOnboardCategory<Step>>
  step: Step
  title?: string
  content: string
  button: string
  buttonAriaLabel: string
  anchorPlacement: BasePlacement
}

export const OnboardStepPresenter = <Step extends string>(
  props: OnboardStepPresenterProps<Step>,
) => {
  const { present, dismiss } = useModal()
  const topKey = useModalTopKey()
  const ref = useRef<HTMLElement>()
  const { updateOnboardStepLayout } = useOnboardStepLayout()

  useEffect(() => {
    if (props.onboard.enabled && props.onboard.step === props.step) {
      present(props.step, { priority: "onboard" })
    } else {
      dismiss(props.step)
    }
  }, [props.onboard.enabled, props.onboard.step, props.step, present, dismiss])

  useEffect(() => {
    if (!ref.current) {
      return
    }

    updateOnboardStepLayout(ref.current, topKey === props.step, true)
  }, [topKey, props.step, updateOnboardStepLayout])

  const cancel = () => {
    props.onboard.stop()
    dismiss(props.step)
  }

  return (
    <>
      <Box ref={ref}>{props.children}</Box>

      <ModalPresenter queueKey={props.step}>
        <Modal open disableScrollLock>
          <Fragment>
            <SpotlightDialog
              anchorRef={ref}
              anchorPlacement={props.anchorPlacement}
              onClose={(event) => {
                event.stopPropagation()
                cancel()
              }}
            >
              {props.title && (
                <SpotlightDialogTitle>{props.title}</SpotlightDialogTitle>
              )}
              <SpotlightDialogContent>{props.content}</SpotlightDialogContent>
              <SpotlightDialogButton
                onClick={(event) => {
                  event.stopPropagation()
                  props.onboard.next()
                }}
                aria-label={props.buttonAriaLabel}
              >
                {props.button}
              </SpotlightDialogButton>
            </SpotlightDialog>
          </Fragment>
        </Modal>
      </ModalPresenter>
    </>
  )
}
