import Box from "@mui/joy/Box"
import IconButton from "@mui/joy/IconButton"
import MenuButton from "@mui/joy/MenuButton"
import List from "@mui/joy/List"
import ListItem, { listItemClasses } from "@mui/joy/ListItem"
import { useTheme } from "@mui/joy/styles"
import useMediaQuery from "@mui/system/useMediaQuery"
import { useMatches } from "react-router-dom"
import { useEffect } from "react"
import { create } from "zustand"

import type { ContextStoreProp } from "@future/context/store"
import { useMenuOnboard } from "@future/pages/tradeMarket/onboard"
import { RouteRootId } from "@future/router/types"
import { Event } from "@perps/analytics/events"
import { track } from "@perps/analytics/track"

import { moreNavItemId, useMainNavItems } from "./navItems"
import NavActionButton from "./NavActionButton"
import NavParentButton from "./NavParentButton"
import HeaderNavDropdown from "./HeaderNavDropdown"
import MenuIcon from "./icons/MenuIcon"

const allMenuId = "all"

const HeaderNav = (props: ContextStoreProp<"initial">) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"))

  return isSmallScreen ? (
    <>
      <Box sx={{ flexGrow: 1 }} />
      <HeaderNavButton contextStore={props.contextStore} />
    </>
  ) : (
    <HeaderNavMenu contextStore={props.contextStore} />
  )
}

const useHeaderNavItems = (props: ContextStoreProp<"initial">) => {
  const navItems = useMainNavItems(props)
  const match = useMatches().at(0)

  const selectedId = (() => {
    if (
      match?.data &&
      typeof match.data === "object" &&
      "route" in match.data &&
      typeof match.data.route === "string"
    ) {
      return match.data.route
    }
  })()

  const { step, enabled, zIndex } = useMenuOnboard()

  useEffect(() => {
    if (!enabled) {
      return
    }

    const ids = (() => {
      if (step === "earnNavItem") {
        return [RouteRootId.markets, allMenuId]
      }
      if (step === "discordMenu") {
        return [moreNavItemId, allMenuId]
      }
    })()

    if (ids) {
      useNavDropdownStore.getState().setOpen(ids, true, true)

      return () => {
        useNavDropdownStore.getState().setOpen(ids, false, true)
      }
    }
  }, [step, enabled])

  return { navItems, selectedId, zIndex }
}

const HeaderNavMenu = (props: ContextStoreProp<"initial">) => {
  const { navItems, selectedId, zIndex } = useHeaderNavItems(props)
  const open = useNavDropdownStore((state) => state.open)

  return (
    <List
      orientation="horizontal"
      role="menubar"
      size="sm"
      sx={{
        "--ListItem-radius": "calc(var(--ListItem-minHeight) / 2)",
        [`& > .${listItemClasses.root}`]: {
          marginInlineStart: "calc(-1 * var(--ListItem-paddingLeft))",
        },
      }}
    >
      {navItems.map((navItem) => (
        <ListItem key={navItem.title} variant="plain">
          {((): JSX.Element => {
            switch (navItem.type) {
              case "parent":
                return (
                  <NavParentButton
                    navItem={navItem}
                    selected={selectedId === navItem.id}
                    open={navItem.id ? open.has(navItem.id) : undefined}
                    onOpenChange={(_event, open) => {
                      if (navItem.id) {
                        useNavDropdownStore.getState().setOpen(navItem.id, open)

                        if (open) {
                          switch (navItem.id) {
                            case RouteRootId.markets:
                              track(Event.openMarketsMenu())
                              break
                            case moreNavItemId:
                              track(Event.openMoreMenu())
                              break
                          }
                        }
                      }
                    }}
                    zIndex={zIndex}
                  />
                )
              case "action":
                return (
                  <NavActionButton
                    navItem={navItem}
                    selected={selectedId === navItem.id}
                  />
                )
            }
          })()}
        </ListItem>
      ))}
    </List>
  )
}

const HeaderNavButton = (props: ContextStoreProp<"initial">) => {
  const { navItems, selectedId, zIndex } = useHeaderNavItems(props)
  const open = useNavDropdownStore((state) => state.open)

  return (
    <HeaderNavDropdown
      subitems={navItems}
      placement="bottom-end"
      selectedId={selectedId}
      open={open.has(allMenuId)}
      onOpenChange={(_event, open) => {
        useNavDropdownStore.getState().setOpen(allMenuId, open)

        if (open) {
          track(Event.openAllMenu())
        }
      }}
      zIndex={zIndex}
      button={
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{
            root: {
              variant: "outlined",
              sx: { ...(open && zIndex !== undefined && { zIndex }) },
            },
          }}
          aria-label="main nav"
        >
          <MenuIcon />
        </MenuButton>
      }
    />
  )
}

interface NavDropdownStoreProps {
  open: Map<string, boolean>
  setOpen: (id: string | string[], isOpen: boolean, onboard?: boolean) => void
}

const useNavDropdownStore = create<NavDropdownStoreProps>((set, get) => ({
  open: new Map(),
  setOpen: (id, isOpen, onboard = false) => {
    let canChange = false
    const ids = typeof id === "string" ? [id] : id
    const open = new Map(get().open)

    for (const id of ids) {
      if (open.has(id) !== isOpen) {
        if (isOpen) {
          canChange = true
          open.set(id, onboard)
        } else {
          canChange = canChange || open.get(id) === onboard
          open.delete(id)
        }
      }
    }

    if (canChange) {
      set({ open })
    }
  },
}))

export default HeaderNav
