var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Long from "long";
import { WalletErrorType } from "../../shared/error";
import { metaMaskWallet } from "../metaMask";
import { base64Image } from "./icon";
const walletInfo = Object.assign(Object.assign({}, metaMaskWallet.info), { name: "MetaMask", icon: base64Image, isChainSupported: (chainId) => {
        switch (chainId) {
            case "injective-1":
            case "injective-888":
                return false;
            default:
                return true;
        }
    }, errors: Object.assign(Object.assign({}, metaMaskWallet.info.errors), { generic: [
            ...metaMaskWallet.info.errors.generic,
            {
                message: "User rejected the request.",
                type: WalletErrorType.rejected,
            },
        ] }) });
export const leapMetaMaskWallet = {
    provider: () => {
        const installed = typeof window.ethereum !== "undefined";
        if (!installed) {
            return { installed };
        }
        const metaMaskSnap = getMetaMaskSnap();
        return {
            installed,
            init: metaMaskSnap.init,
            enable: metaMaskSnap.enable,
            getKey: metaMaskSnap.getKey,
            getOfflineSigner: metaMaskSnap.getOfflineSigner,
            getOfflineSignerOnlyAmino: metaMaskSnap.getOfflineSignerOnlyAmino,
        };
    },
    info: walletInfo,
};
const getMetaMaskSnap = () => {
    const metaMaskSnap = {
        id: "npm:@leapwallet/metamask-cosmos-snap",
        params: undefined,
    };
    const ethereum = window.ethereum;
    const init = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const clientVersion = yield (ethereum === null || ethereum === void 0 ? void 0 : ethereum.request({
            method: "web3_clientVersion",
        }));
        const isMetamask = clientVersion.includes("MetaMask");
        if (!isMetamask) {
            throw new Error("Metamask is not installed");
        }
        if (typeof window.okxwallet !== "undefined") {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            if (window.okxwallet.isOkxWallet) {
                throw new Error("You have OKX Wallet installed. Please disable and reload the page to use Metamask Snap.");
            }
        }
        const version = (_a = clientVersion
            .split("MetaMask/v")[1]) === null || _a === void 0 ? void 0 : _a.split(".")[0];
        const isSupportMMSnap = Number(version) >= 11;
        if (!isSupportMMSnap) {
            throw new Error("Metamask Snap is not supported in this version");
        }
        yield enable();
    });
    const enable = () => __awaiter(void 0, void 0, void 0, function* () {
        const installedSnap = yield getSnap();
        if (!installedSnap) {
            yield requestSnaps();
        }
    });
    const getSnaps = () => __awaiter(void 0, void 0, void 0, function* () {
        return (yield (ethereum === null || ethereum === void 0 ? void 0 : ethereum.request({
            method: "wallet_getSnaps",
        })));
    });
    const getSnap = (version) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const snaps = yield getSnaps();
            return Object.values(snaps).find((snap) => {
                return (snap.id === metaMaskSnap.id && (!version || snap.version === version));
            });
        }
        catch (_a) {
            return undefined;
        }
    });
    const requestSnaps = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        yield (ethereum === null || ethereum === void 0 ? void 0 : ethereum.request({
            method: "wallet_requestSnaps",
            params: {
                [metaMaskSnap.id]: (_a = metaMaskSnap.params) !== null && _a !== void 0 ? _a : {},
            },
        }));
    });
    const metamaskSnapLeapKeysMap = {};
    const getKey = (chainId) => __awaiter(void 0, void 0, void 0, function* () {
        if (typeof metamaskSnapLeapKeysMap[chainId] !== "undefined") {
            return metamaskSnapLeapKeysMap[chainId];
        }
        const response = yield (ethereum === null || ethereum === void 0 ? void 0 : ethereum.request({
            method: "wallet_invokeSnap",
            params: {
                snapId: metaMaskSnap.id,
                request: {
                    method: "getKey",
                    params: {
                        chainId,
                    },
                },
            },
        }));
        if (!response) {
            throw new Error("No response from MetaMask");
        }
        const key = response;
        // getKey from @leapwallet/cosmos-snap-provider return type is wrong
        if ("pubkey" in key && key.pubkey && typeof key.pubkey === "object") {
            const pubKey = Uint8Array.from(Object.values(key.pubkey));
            const { pubkey } = key, values = __rest(key, ["pubkey"]);
            const fixedKey = Object.assign(Object.assign({}, values), { pubKey });
            metamaskSnapLeapKeysMap[chainId] = fixedKey;
            return fixedKey;
        }
        throw new Error("Unhandled getKey case");
    });
    const getAccount = (chainId) => __awaiter(void 0, void 0, void 0, function* () {
        const key = yield getKey(chainId);
        return {
            address: key.bech32Address,
            algo: key.algo,
            pubkey: key.pubKey,
        };
    });
    const requestSignAmino = (chainId, signerAddress, signDoc) => __awaiter(void 0, void 0, void 0, function* () {
        const signResponse = (yield (ethereum === null || ethereum === void 0 ? void 0 : ethereum.request({
            method: "wallet_invokeSnap",
            params: {
                snapId: metaMaskSnap.id,
                request: {
                    method: "signAmino",
                    params: {
                        chainId,
                        signerAddress,
                        signDoc,
                    },
                },
            },
        })));
        return signResponse;
    });
    const signAmino = (...args) => __awaiter(void 0, void 0, void 0, function* () {
        const [chainId, signer, signDoc, _signOptions] = args;
        const result = yield requestSignAmino(chainId, signer, signDoc);
        return result;
    });
    const requestSignDirect = (chainId, signerAddress, signDoc) => __awaiter(void 0, void 0, void 0, function* () {
        const signature = (yield (ethereum === null || ethereum === void 0 ? void 0 : ethereum.request({
            method: "wallet_invokeSnap",
            params: {
                snapId: metaMaskSnap.id,
                request: {
                    method: "signDirect",
                    params: {
                        chainId,
                        signerAddress,
                        signDoc,
                    },
                },
            },
        })));
        const accountNumber = signDoc.accountNumber;
        if (!accountNumber) {
            throw new Error("Missing account number");
        }
        const modifiedAccountNumber = new Long(accountNumber.low, accountNumber.high, accountNumber.unsigned);
        return {
            signature: signature.signature,
            signed: Object.assign(Object.assign({}, signature.signed), { accountNumber: modifiedAccountNumber.toString(), authInfoBytes: new Uint8Array(Object.values(signature.signed.authInfoBytes)), bodyBytes: new Uint8Array(Object.values(signature.signed.bodyBytes)) }),
        };
    });
    const signDirect = (...args) => __awaiter(void 0, void 0, void 0, function* () {
        const [chainId, signer, signDoc] = args;
        const res = yield requestSignDirect(chainId, signer, signDoc);
        return res;
    });
    const getOfflineSignerOnlyAmino = (chainId) => {
        return {
            getAccounts: () => __awaiter(void 0, void 0, void 0, function* () { return [yield getAccount(chainId)]; }),
            signAmino: (signerAddress, signDoc) => signAmino(chainId, signerAddress, signDoc),
        };
    };
    const getOfflineSigner = (chainId) => {
        return {
            getAccounts: () => __awaiter(void 0, void 0, void 0, function* () { return [yield getAccount(chainId)]; }),
            signDirect: (signerAddress, signDoc) => signDirect(chainId, signerAddress, signDoc),
            signAmino: (signerAddress, signDoc) => signAmino(chainId, signerAddress, signDoc),
        };
    };
    return {
        init,
        enable,
        getKey,
        getOfflineSigner,
        getOfflineSignerOnlyAmino,
    };
};
