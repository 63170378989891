import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const NeutronIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      <path
        d="M16.9399 16.3939C15.1217 14.2727 13.3338 12.1515 11.5156 10H29.6671C29.9096 10 30.0914 10.1818 30.0914 10.4242V28.6061C27.9096 26.7273 25.8187 24.9091 23.6974 23.0909C25.5459 20.6667 24.849 18.0303 23.4247 16.6061C22.0914 15.2424 19.4853 14.4545 16.9399 16.3939Z"
        fill="white"
      />
      <path
        d="M22.6061 23.9999C24.2727 25.9696 25.9394 27.909 27.6667 29.9696C27.3939 29.9696 27.2121 29.9999 27.0303 29.9999C21.5152 29.9999 16.0303 29.9999 10.5152 29.9999C10.1212 29.9999 10 29.909 10 29.5151C10 23.9393 10 18.3939 10 12.8181C10 12.6666 10.0303 12.5454 10.0303 12.303C10.7273 12.909 11.3636 13.4545 11.9697 13.9696C13.2121 15.0303 14.4242 16.0909 15.6667 17.1515C15.9091 17.3333 15.9394 17.4848 15.8182 17.7878C14.8788 19.6969 15.2121 21.8484 16.6364 23.303C18.0909 24.7575 20.2424 25.0909 22.2121 24.1515C22.3636 24.1212 22.4545 24.0606 22.6061 23.9999Z"
        fill="white"
      />
    </SvgIcon>
  )
}

export default NeutronIcon
