import Dropdown, { type DropdownProps } from "@mui/joy/Dropdown"
import Menu from "@mui/joy/Menu"
import MenuItem from "@mui/joy/MenuItem"
import List from "@mui/joy/List"
import ListDivider from "@mui/joy/ListDivider"
import ListItem from "@mui/joy/ListItem"
import ListItemContent from "@mui/joy/ListItemContent"
import type { PopperOwnProps } from "@mui/base/Popper"
import React from "react"

import type { ParentNavItem, ActionNavItem } from "./navItems"
import NavActionButton from "./NavActionButton"

interface HeaderNavDropdownProps
  extends Pick<DropdownProps, "open" | "onOpenChange"> {
  button: JSX.Element
  subitems: (ParentNavItem | ActionNavItem)[]
  placement?: PopperOwnProps["placement"]
  selectedId?: string
  zIndex?: number
}

const HeaderNavDropdown = (props: HeaderNavDropdownProps) => {
  const { placement = "bottom-start" } = props

  return (
    <Dropdown open={props.open} onOpenChange={props.onOpenChange}>
      {props.button}
      <Menu
        placement={placement}
        sx={{
          "--ListItem-minHeight": "3rem",
          "--ListDivider-gap": "0",
          minWidth: 230,
          ...(props.open &&
            props.zIndex !== undefined && {
              zIndex: props.zIndex,
            }),
        }}
      >
        {props.subitems.map((subitem, index) => (
          <React.Fragment key={index}>
            {index > 0 && <ListDivider />}

            {((): JSX.Element => {
              switch (subitem.type) {
                case "parent":
                  return (
                    <HeaderNavParentSection
                      navItem={subitem}
                      selectedId={props.selectedId}
                    />
                  )
                case "action":
                  return (
                    <NavActionButton
                      root={MenuItem}
                      navItem={subitem}
                      selected={isSelected(subitem.id, props.selectedId)}
                    />
                  )
              }
            })()}
          </React.Fragment>
        ))}
      </Menu>
    </Dropdown>
  )
}

interface HeaderNavParentSectionProps
  extends Pick<HeaderNavDropdownProps, "selectedId"> {
  navItem: ParentNavItem
}

const HeaderNavParentSection = (props: HeaderNavParentSectionProps) => {
  const { navItem } = props
  const isParentSelected = isSelected(navItem.id, props.selectedId)

  return (
    <>
      <ListItem
        color={isParentSelected ? "primary" : "neutral"}
        sx={({ vars }) => ({
          ...(!isParentSelected && {
            color: vars.palette.text.secondary,
          }),
          "&:not(:first-of-type)": {
            mt: 2,
          },
        })}
      >
        <ListItemContent>{navItem.title}</ListItemContent>
      </ListItem>
      <ListItem
        nested
        sx={{
          "&:not(:last-of-type)": {
            mb: 2,
          },
        }}
      >
        <List>
          {navItem.subitems.map((subitem) => (
            <NavActionButton
              key={subitem.title}
              root={MenuItem}
              navItem={subitem}
              selected={isSelected(subitem.id, props.selectedId)}
            />
          ))}
        </List>
      </ListItem>
    </>
  )
}

const isSelected = (navItemId?: string, selectedId?: string) => {
  return navItemId && selectedId ? navItemId === selectedId : false
}

export default HeaderNavDropdown
