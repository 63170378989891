import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const SeiIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      <circle cx="20" cy="20" r="11" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.971 31C23.2013 31 26.1074 29.6149 28.1244 27.4077L27.5877 26.8169C26.9235 26.0858 25.7912 26.0233 25.0498 26.6769L23.9048 27.6861C22.3473 29.0591 19.9798 28.9725 18.5278 27.4895L17.8119 26.7583C17.1245 26.0561 15.998 26.0317 15.2804 26.7034L13.1805 28.6685C15.0526 30.1293 17.4098 31 19.971 31ZM29.1393 25.4224L29.3999 25.7094C30.4152 24.045 31 22.0907 31 20C31 17.465 30.1402 15.1303 28.6956 13.27C28.4271 13.3387 28.1704 13.4701 27.9487 13.6655L26.8039 14.6747C25.2463 16.0477 22.8789 15.9611 21.4269 14.4781L20.711 13.7469C20.0235 13.0448 18.8971 13.0203 18.1794 13.6919L15.2337 16.4487L13.8007 14.934L16.7464 12.1772C18.2949 10.7279 20.7257 10.7808 22.2092 12.2959L22.925 13.0271C23.598 13.7143 24.695 13.7544 25.4169 13.1182L26.5617 12.109C26.7539 11.9396 26.9582 11.7925 27.1715 11.6675C25.2393 10.0052 22.7228 9 19.971 9C14.4382 9 9.85694 13.0634 9.06357 18.3613C10.5733 17.4945 12.5337 17.7211 13.8018 19.0163L14.6297 19.8619C15.2732 20.5192 16.3113 20.5885 17.0374 20.0227L19.4916 18.1104C20.9647 16.9626 23.0501 17.0181 24.4593 18.2426L27.8011 21.1466L26.4253 22.7128L23.0834 19.8089C22.4304 19.2414 21.4639 19.2157 20.7813 19.7477L18.3271 21.66C16.7602 22.8809 14.5201 22.7313 13.1315 21.313L12.3036 20.4674C11.6161 19.7653 10.4897 19.7408 9.77203 20.4124L9 21.1349C9.23876 23.4583 10.2026 25.5675 11.662 27.2337L13.8473 25.1885C15.3959 23.7393 17.8267 23.7921 19.3101 25.3073L20.026 26.0385C20.6989 26.7257 21.7961 26.7658 22.5178 26.1296L23.6627 25.1204C25.2626 23.71 27.7061 23.8448 29.1393 25.4224Z"
        fill="url(#paint0_linear_84_1713)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_84_1713"
          x1="20"
          y1="9"
          x2="24.3248"
          y2="31"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#780000" />
          <stop offset="1" stopColor="#C1121F" />
        </linearGradient>
      </defs>
    </SvgIcon>
  )
}

export default SeiIcon
