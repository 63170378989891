import Box from "@mui/joy/Box"
import List from "@mui/joy/List"
import ListItem from "@mui/joy/ListItem"
import ListItemContent from "@mui/joy/ListItemContent"
import Typography from "@mui/joy/Typography"
import Skeleton from "@mui/joy/Skeleton"
import { useStore } from "zustand"
import GainsTypography from "@levana-protocol/ui/GainsTypography"
import { useTranslation } from "react-i18next"

import type { ContextStoreProp } from "@future/context/store"
import { useMarketsLpInfoQuery } from "@future/network/marketsLpInfo"
import { useMarketsTradeHistorySummaryQuery } from "@future/network/marketsTradeHistorySummary"
import { Usd } from "@future/numerics"
import { useOpenPositions } from "@future/pages/tradeMarket/openPositions/useOpenPositions"
import { useEarnMarketsQuery } from "@future/network/earnMarkets"

const AccountSummary = (props: ContextStoreProp<"standard">) => {
  return (
    <>
      <AccountTradeSummary contextStore={props.contextStore} />
      <AccountEarnSummary contextStore={props.contextStore} />
    </>
  )
}

export interface AccountSummaryStat {
  title: string
  amount: Usd | undefined
  gains: boolean | undefined
}

export const useAccountTradeSummary = (
  props: ContextStoreProp<"standard">,
): AccountSummaryStat[] => {
  const { t } = useTranslation("perpsCommon")
  const markets = useStore(props.contextStore, (state) => state.markets)
  const marketsTradeHistorySummaryResult =
    useMarketsTradeHistorySummaryQuery(props)
  const openPositions = useOpenPositions(props)

  const stats: {
    tradeTotal: Usd | undefined
    realizedPnl: Usd | undefined
    unrealizedPnl: Usd | undefined
  } = {
    tradeTotal: undefined,
    realizedPnl: undefined,
    unrealizedPnl: undefined,
  }

  for (const marketId of markets.keys()) {
    const tradeHistorySummary =
      marketsTradeHistorySummaryResult.data?.get(marketId)

    if (tradeHistorySummary) {
      stats.tradeTotal = new Usd(stats.tradeTotal ?? 0).plus(
        tradeHistorySummary.tradeVolume,
      )
      stats.realizedPnl = new Usd(stats.realizedPnl ?? 0).plus(
        tradeHistorySummary.realizedPnl,
      )
    }
  }

  if (openPositions) {
    stats.unrealizedPnl = new Usd(0)

    for (const position of openPositions) {
      stats.unrealizedPnl = stats.unrealizedPnl.plus(position.pnlUsd)
    }
  }

  return [
    {
      title: t("account.tradeSummary.totalTraded"),
      amount: stats.tradeTotal,
      gains: false,
    },
    {
      title: t("account.tradeSummary.realizedPnl"),
      amount: stats.realizedPnl,
      gains: true,
    },
    {
      title: t("account.tradeSummary.unrealizedPnl"),
      amount: stats.unrealizedPnl,
      gains: true,
    },
  ]
}

const AccountTradeSummary = (props: ContextStoreProp<"standard">) => {
  const { t } = useTranslation("perpsCommon")
  const stats = useAccountTradeSummary(props)

  return <SummaryList title={t("account.tradeSummary.title")} stats={stats} />
}

export const useAccountEarnSummary = (
  props: ContextStoreProp<"standard">,
): AccountSummaryStat[] => {
  const { t } = useTranslation("perpsCommon")
  const markets = useStore(props.contextStore, (state) => state.markets)
  const marketsLpInfoResult = useMarketsLpInfoQuery(props)
  const earnMarketsResult = useEarnMarketsQuery(props)

  const stats: {
    earnTotal: Usd | undefined
    earnYield: Usd | undefined
    accumulatedEarn: Usd | undefined
  } = {
    earnTotal: undefined,
    earnYield: undefined,
    accumulatedEarn: undefined,
  }

  for (const marketId of markets.keys()) {
    const market = markets.get(marketId)
    const lpInfo = marketsLpInfoResult.data?.get(marketId)
    const earnMarket = earnMarketsResult.data?.get(marketId)

    if (lpInfo) {
      stats.earnTotal = new Usd(stats.earnTotal ?? 0).plus(
        lpInfo.history.depositUsd,
      )
      stats.earnYield = new Usd(stats.earnYield ?? 0).plus(
        lpInfo.history.yieldUsd,
      )

      if (market && earnMarket) {
        const earnPnl = lpInfo.lpCollateral.plus(lpInfo.xlpCollateral)
        const earnPnlUsd = market.price.collateralToUsd(earnPnl)
        stats.accumulatedEarn = new Usd(stats.accumulatedEarn ?? 0).plus(
          earnPnlUsd,
        )
      }
    }
  }

  return [
    {
      title: t("account.earnSummary.totalDeposited"),
      amount: stats.earnTotal,
      gains: false,
    },
    {
      title: t("account.earnSummary.yieldWithdrawn"),
      amount: stats.earnYield,
      gains: true,
    },
    {
      title: t("account.earnSummary.accumulatedEarn"),
      amount: stats.accumulatedEarn,
      gains: true,
    },
  ]
}

const AccountEarnSummary = (props: ContextStoreProp<"standard">) => {
  const { t } = useTranslation("perpsCommon")
  const stats = useAccountEarnSummary(props)

  return <SummaryList title={t("account.earnSummary.title")} stats={stats} />
}

interface SummaryListProps {
  title: string
  stats: AccountSummaryStat[]
}

const SummaryList = (props: SummaryListProps) => {
  return (
    <Box>
      <Typography level="body-md">{props.title}</Typography>
      <List sx={{}}>
        {props.stats.map((stat) => (
          <ListItem
            key={stat.title}
            sx={({ vars, spacing }) => ({
              "--List-gap": spacing(0.5),
              background: vars.palette.background.level2,
              borderRadius: vars.radius.md,
            })}
          >
            <ListItemContent>
              <Typography level="title-sm" textColor="text.secondary">
                {stat.title}
              </Typography>
              <Skeleton
                loading={!stat.amount}
                variant="rectangular"
                height={30}
              >
                {stat.gains ? (
                  <GainsTypography gains={stat.amount?.toNumber()} level="h4">
                    {stat.amount?.toPersonalPrice()}
                  </GainsTypography>
                ) : (
                  <Typography level="h4">
                    {stat.amount?.toPersonalPrice()}
                  </Typography>
                )}
              </Skeleton>
            </ListItemContent>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default AccountSummary
