import type { MarketId, MarketType } from "@perps/sdk/types"
import {
  Base,
  Collateral,
  Notional,
  type Percentage,
  Quote,
  Usd,
} from "@future/numerics"
import { Amount, type ConstructableAmount } from "@future/numerics/amount"

export class MarketPrice {
  priceBase: Quote
  priceUsd: Usd
  marketType: MarketType
  quoteAssetId: string
  marketId: MarketId

  constructor({
    priceBase,
    priceUsd,
    marketType,
    quoteAssetId,
    marketId,
  }: {
    priceBase: Quote
    priceUsd: Usd
    marketType: MarketType
    quoteAssetId: string
    marketId: MarketId
  }) {
    this.priceBase = priceBase
    this.priceUsd = priceUsd
    this.marketType = marketType
    this.quoteAssetId = quoteAssetId
    this.marketId = marketId
  }

  // return a plain object that's friendly for serialization
  // helpful for creating debuggable tests
  toPlainObject() {
    return {
      priceBase: this.priceBase.toString(),
      priceUsd: this.priceUsd.toString(),
      marketType: this.marketType,
      quoteAssetId: this.quoteAssetId,
      marketId: this.marketId,
    }
  }

  // convert from an object created via toPlainObject
  // helpful for creating debuggable tests
  static fromPlainObject(obj: ReturnType<MarketPrice["toPlainObject"]>) {
    return new MarketPrice({
      priceBase: new Quote(obj.priceBase),
      priceUsd: new Usd(obj.priceUsd),
      marketType: obj.marketType,
      quoteAssetId: obj.quoteAssetId,
      marketId: obj.marketId,
    })
  }

  static modifiedPriceBase(
    marketPrice: MarketPrice,
    newPriceBase: ConstructableAmount,
  ): MarketPrice {
    return new MarketPrice({
      priceBase: new Quote(newPriceBase),
      marketId: marketPrice.marketId,
      marketType: marketPrice.marketType,
      priceUsd: marketPrice.priceUsd,
      quoteAssetId: marketPrice.quoteAssetId,
    })
  }

  priceNotionalInCollateral(): Amount {
    return this.notionalToCollateral(new Notional(1))
  }

  priceCollateralInNotional(): Amount {
    return this.collateralToNotional(new Collateral(1))
  }

  priceUsdInNotional(): Amount {
    return this.usdToNotional(new Usd(1))
  }

  priceNotionalInUsd(): Amount {
    return new Amount(1).divide(this.priceUsdInNotional())
  }

  collateralToUsd(collateral: Collateral): Usd {
    return new Usd(collateral.toAmount().times(this.priceUsd))
  }

  usdToCollateral(usd: Usd): Collateral {
    const collateral = new Collateral(usd.toAmount().divide(this.priceUsd))
    return collateral.isInfinite() ? new Collateral(usd.toAmount()) : collateral
  }

  usdToNotional(usd: Usd): Notional {
    if (
      this.quoteAssetId === usd.suffix() &&
      this.marketType === "collateral_is_base"
    ) {
      return new Notional(usd)
    }
    return this.collateralToNotional(this.usdToCollateral(usd))
  }

  usdToBase(usd: Usd): Base {
    switch (this.marketType) {
      case "collateral_is_base":
        return new Base(this.usdToCollateral(usd))
      case "collateral_is_quote":
        return new Base(this.usdToNotional(usd))
    }
  }

  baseToUsd(base: Base): Usd {
    return this.collateralToUsd(this.baseToCollateral(base))
  }

  baseToCollateral(base: Base): Collateral {
    switch (this.marketType) {
      case "collateral_is_base":
        return new Collateral(base)
      case "collateral_is_quote":
        return new Collateral(this.baseToQuote(base))
    }
  }

  baseToQuote(base: Base): Quote {
    return new Quote(base.toAmount().times(this.priceBase))
  }

  quoteToBase(quote: Quote): Base {
    const base = new Base(quote.toAmount().divide(this.priceBase))
    return base.isInfinite() ? new Base(quote.toAmount()) : base
  }

  collateralToBase(collateral: Collateral): Base {
    switch (this.marketType) {
      case "collateral_is_base":
        return new Base(collateral)
      case "collateral_is_quote":
        return this.quoteToBase(new Quote(collateral))
    }
  }

  collateralToQuote(collateral: Collateral): Quote {
    switch (this.marketType) {
      case "collateral_is_base":
        return this.baseToQuote(new Base(collateral))
      case "collateral_is_quote":
        return new Quote(collateral)
    }
  }

  notionalToCollateral(notional: Notional): Collateral {
    switch (this.marketType) {
      case "collateral_is_base": {
        const collateral = new Collateral(
          notional.toAmount().divide(this.priceBase),
        )
        return collateral.isInfinite()
          ? new Collateral(notional.toAmount())
          : collateral
      }
      case "collateral_is_quote":
        return new Collateral(notional.toAmount().times(this.priceBase))
    }
  }

  collateralToNotional(collateral: Collateral): Notional {
    switch (this.marketType) {
      case "collateral_is_base":
        return new Notional(collateral.toAmount().times(this.priceBase))
      case "collateral_is_quote": {
        const notional = new Notional(
          collateral.toAmount().divide(this.priceBase),
        )
        return notional.isInfinite()
          ? new Notional(collateral.toAmount())
          : notional
      }
    }
  }
}

export type PriceFeedId = string

export type DeltaPriceMap = Map<MarketId, DeltaPrice>

export interface DeltaPrice {
  percentage: Percentage
}
