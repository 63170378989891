import { useStore } from "zustand"

import type { MarketConfig } from "@future/market/config/types"
import {
  isStandardContextState,
  type ContextStoreProp,
} from "@future/context/store"

import { elementsValues } from "./elements/utils"
import type { ElementsContentProps } from "./elements/ElementsContent"

export interface BridgeSelectorProps extends ContextStoreProp<"initial"> {
  marketConfig?: MarketConfig
}

export type BridgeSelection = BridgeInternalSelection | BridgeExternalSelection

export type BridgeInternalSelection = BridgeElementsSelection

export interface BridgeElementsSelection {
  type: "elements"
  values: ElementsContentProps["values"]
}

export interface BridgeExternalSelection {
  type: "external"
  url: string
}

export const useBridgeSelector = (
  props: BridgeSelectorProps,
): BridgeSelection | undefined => {
  const { marketConfig } = props
  const chainNetworkId = useStore(props.contextStore, (state) => {
    if (isStandardContextState(state)) {
      return state.chain.config.chainNetworkId
    }
  })

  switch (chainNetworkId) {
    case "osmosis-mainnet":
      if (!marketConfig) {
        return {
          type: "elements",
          values: elementsValues({
            fromChainName: "ethereum",
            fromToken: "eth",
            toChainName: "osmosis",
            toMarketConfig: undefined,
          }),
        }
      }

      switch (marketConfig.id) {
        case "SEI_USD":
          return {
            type: "external",
            url: "https://app.sei.io/bridge",
          }
        default:
          return {
            type: "elements",
            values: elementsValues({
              fromChainName: "ethereum",
              fromToken: "eth",
              toChainName: "osmosis",
              toMarketConfig: marketConfig,
            }),
          }
      }
    case "injective-mainnet":
      return {
        type: "external",
        url: "https://hub.injective.network/bridge/",
      }

    default:
      return undefined
  }
}
