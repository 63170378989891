import type { SvgIconProps } from "@mui/joy/SvgIcon"

import type { ChainName, TargetId } from "@common/target/types"
import DymensionIcon from "@future/chain/icons/DymensionIcon"
import InjectiveIcon from "@future/chain/icons/InjectiveIcon"
import NeutronIcon from "@future/chain/icons/NeutronIcon"
import OsmosisIcon from "@future/chain/icons/OsmosisIcon"
import SeiIcon from "@future/chain/icons/SeiIcon"

export type TargetSlug = string

export type TargetCategory =
  | "mainnet"
  | "beta"
  | "competition"
  | "local"
  | "other"

export interface TargetInfo {
  /**
   * A unique string representing a specific target
   */
  id: TargetId
  /**
   * A user friendly name
   */
  name: string
  /**
   * A string used for URL's
   */
  slug: TargetSlug
  /**
   * A category representing how this target is used
   */
  category: TargetCategory
  /**
   * If `true`, the contract uses direct price updates instead of the Pyth oracle
   */
  directPriceUpdates: boolean
  /**
   * Should we hide this network from the network dropdown list?
   *
   * When enabled, the network will still appear on the stats page,
   * and will also support direct URLs.
   *
   */
  hideInNetworkList: boolean
  icon: React.JSXElementConstructor<SvgIconProps>
  /**
   * A CSS supported background color.
   */
  iconBackgroundColor: string
}

interface MakeTargetInfoProps {
  id: TargetId
  slug: TargetSlug
  chain: ChainName
  category?: TargetCategory
  name?: string
  directPriceUpdates?: boolean
  hideInNetworkList?: boolean
}

const makeTargetInfo = (props: MakeTargetInfoProps): TargetInfo => {
  const { id, slug } = props

  const { icon, iconBackgroundColor } = ((): Pick<
    TargetInfo,
    "icon" | "iconBackgroundColor"
  > => {
    switch (props.chain) {
      case "dymension":
        return { icon: DymensionIcon, iconBackgroundColor: "#24201F" }
      case "injective":
        return { icon: InjectiveIcon, iconBackgroundColor: "#FFF" }
      case "neutron":
        return { icon: NeutronIcon, iconBackgroundColor: "#000" }
      case "osmosis":
        return { icon: OsmosisIcon, iconBackgroundColor: "#FFF" }
      case "sei":
        return { icon: SeiIcon, iconBackgroundColor: "#F4E3CC" }
    }
  })()

  return {
    id,
    slug,
    icon,
    iconBackgroundColor,
    category: props.category ?? "other",
    name: props.name ?? id,
    directPriceUpdates: props.directPriceUpdates ?? false,
    hideInNetworkList: props.hideInNetworkList ?? false,
  }
}

/**
 * Organized for convenient display
 */
export const targetInfos: TargetInfo[] = [
  // Mainnet
  makeTargetInfo({
    id: "osmomainnet1",
    slug: "osmosis",
    chain: "osmosis",
    category: "mainnet",
    name: "Osmosis",
  }),
  makeTargetInfo({
    id: "seimainnet1",
    slug: "sei",
    chain: "sei",
    category: "mainnet",
    name: "Sei",
    hideInNetworkList: true,
  }),
  makeTargetInfo({
    id: "injmainnet1",
    slug: "injective",
    chain: "injective",
    category: "mainnet",
    name: "Injective",
  }),
  makeTargetInfo({
    id: "ntrnmainnet1",
    slug: "neutron",
    chain: "neutron",
    category: "mainnet",
    name: "Neutron",
  }),

  // Betas
  makeTargetInfo({
    id: "injbeta",
    slug: "injective-testnet",
    chain: "injective",
    category: "beta",
    name: "Injective Testnet",
  }),
  makeTargetInfo({
    id: "seibeta",
    slug: "sei-testnet",
    chain: "sei",
    category: "beta",
    name: "Sei Testnet",
  }),
  makeTargetInfo({
    id: "osmobeta",
    slug: "osmosis-testnet",
    chain: "osmosis",
    category: "beta",
    name: "Osmosis Testnet",
  }),
  makeTargetInfo({
    id: "ntrnbeta",
    slug: "neutron-testnet",
    chain: "neutron",
    category: "beta",
    name: "Neutron Testnet",
  }),

  // Internal development
  makeTargetInfo({
    id: "osmodev",
    slug: "osmodev",
    chain: "osmosis",
  }),
  makeTargetInfo({
    id: "seidev",
    slug: "seidev",
    chain: "sei",
  }),
  makeTargetInfo({
    id: "seidebug",
    slug: "seidebug",
    chain: "sei",
  }),
  makeTargetInfo({
    id: "seici",
    slug: "seici",
    chain: "sei",
  }),
  makeTargetInfo({
    id: "osmodebug",
    slug: "osmodebug",
    chain: "osmosis",
  }),
  makeTargetInfo({
    id: "osmoqa",
    slug: "osmoqa",
    chain: "osmosis",
    directPriceUpdates: true,
  }),
  makeTargetInfo({
    id: "osmoci",
    slug: "osmoci",
    chain: "osmosis",
  }),
  makeTargetInfo({
    id: "injdebug",
    slug: "injdebug",
    chain: "injective",
  }),
  makeTargetInfo({
    id: "ntrndev",
    slug: "ntrndev",
    chain: "neutron",
  }),
  makeTargetInfo({
    id: "dymbeta",
    slug: "dymbeta",
    chain: "dymension",
  }),
]
