import { create } from "zustand"
import type { OrderId } from "@perps/sdk/types/.generated/market_response_to_status"

import type { MarketId } from "@perps/sdk/types"
import type { LimitOrder } from "@future/network/marketsLimitOrders/types"

interface ClosedLimitOrdersStoreProps {
  data: Record<MarketId, Record<OrderId, LimitOrder>>
  add: (marketId: MarketId, limitOrder: LimitOrder) => void
  remove: (marketId: MarketId, orderId: OrderId) => void
  has: (marketId: MarketId, orderId: OrderId) => boolean
  clear: () => void
}

export const useClosedLimitOrdersStore = create<ClosedLimitOrdersStoreProps>(
  (set, get) => {
    const getLimitOrders = (marketId: MarketId) => {
      const { data } = get()
      return marketId in data ? data[marketId] : {}
    }

    return {
      data: {},
      add: (marketId, limitOrder) => {
        const items = getLimitOrders(marketId)

        if (!items[limitOrder.orderId]) {
          set({
            data: {
              ...get().data,
              [marketId]: { ...items, [limitOrder.orderId]: limitOrder },
            },
          })
        }
      },
      remove: (marketId, orderId) => {
        const limitOrders = getLimitOrders(marketId)

        if (limitOrders[orderId]) {
          const { [orderId]: _, ...remainingPositions } = limitOrders

          set({
            data: {
              ...get().data,
              [marketId]: remainingPositions,
            },
          })
        }
      },
      has: (marketId, orderId) => {
        return orderId in getLimitOrders(marketId)
      },
      clear: () => {
        set({ data: {} })
      },
    }
  },
)
