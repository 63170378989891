import { ErrorMessage, WalletErrorType } from "../shared/error";
import { useAccountStore, useChainStore } from "../shared/store";
import { getWallet } from "../wallet";
export { WalletErrorType, ErrorMessage } from "../shared/error";
export const getWalletErrorType = (message) => {
    var _a, _b, _c;
    const walletType = (_b = (_a = useAccountStore.getState().connectIssue) === null || _a === void 0 ? void 0 : _a.walletType) !== null && _b !== void 0 ? _b : useAccountStore.getState().walletType();
    if (!walletType) {
        return WalletErrorType.unknown;
    }
    const { errors } = getWallet(walletType).info;
    for (const definition of errors.generic) {
        if (isErrorMessageMatch(definition.message, message)) {
            return definition.type;
        }
    }
    const { chainId } = useChainStore.getState();
    const chainErrorDefinitions = (_c = errors.chain) === null || _c === void 0 ? void 0 : _c[chainId];
    if (chainErrorDefinitions) {
        for (const definition of chainErrorDefinitions) {
            if (isErrorMessageMatch(definition.message, message)) {
                return definition.type;
            }
        }
    }
    switch (message) {
        case ErrorMessage.noAccount:
            return WalletErrorType.noAccount;
    }
    return WalletErrorType.unknown;
};
const isErrorMessageMatch = (errorMessage, value) => {
    if (typeof errorMessage === "string") {
        return errorMessage === value;
    }
    else {
        return errorMessage.test(value);
    }
};
