import ListItemButton, {
  type ListItemButtonProps,
} from "@mui/joy/ListItemButton"
import type { PropsWithChildren } from "react"

import type { NavItem } from "./navItems"
import NavItemContent from "./NavItemContent"
import { useActionButton, type UseActionButtonProps } from "./useActionButton"

interface NavActionButtonProps extends UseActionButtonProps {
  root?: React.ElementType<ListItemButtonProps>
  selected?: boolean
}

const NavActionButton = (props: NavActionButtonProps) => {
  const { root: Root = ListItemButton, navItem } = props
  const { handleAction } = useActionButton(props)

  return (
    <OnboardWrapperDecider navItem={navItem}>
      <Root
        color={props.selected ? "primary" : navItem.color}
        onClick={handleAction}
        slotProps={{
          root: {
            sx: {
              marginBlockStart: "var(--ListItemButton-marginBlock)",
            },
          },
        }}
        aria-label={`nav ${navItem.title.toLowerCase()}`}
      >
        <NavItemContent navItem={navItem} />
      </Root>
    </OnboardWrapperDecider>
  )
}

const OnboardWrapperDecider = (
  props: PropsWithChildren<{ navItem: NavItem }>,
) => {
  const { onboardWrapper: OnboardWrapper } = props.navItem

  if (OnboardWrapper) {
    return <OnboardWrapper>{props.children}</OnboardWrapper>
  }

  return props.children
}

export default NavActionButton
