import { useConnectedWallet } from "@levana/chain/wallet"

import type { ContextStoreProp } from "@future/context/store"
import ConnectButton from "@future/connect/ConnectButton"

import AccountDrawer, { useAccountDrawerStore } from "./AccountDrawer"
import ConnectedButton from "./ConnectedButton"

const AccountButton = (props: ContextStoreProp<"standard">) => {
  const { data: connectedWallet } = useConnectedWallet()

  if (!connectedWallet) {
    return <ConnectButton />
  }

  return (
    <>
      <ConnectedButton
        account={connectedWallet.account}
        onClick={() => useAccountDrawerStore.setState({ open: true })}
      />
      <AccountDrawer
        contextStore={props.contextStore}
        connectedWallet={connectedWallet}
      />
    </>
  )
}

export default AccountButton
