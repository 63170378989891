import { Buffer } from "buffer"
import React from "react"
import ReactDOM from "react-dom/client"

import { initSentry } from "@future/sentry"
import { initI18n } from "@future/i18n"
import { initConsole } from "@future/libs/debug/console"
import { initMonkeyPatch } from "@future/utils/monkeyPatch"
import Router from "@future/router/Router"

initSentry()
initI18n()
initConsole()
initMonkeyPatch()

globalThis.Buffer = Buffer

const root = document.getElementById("root")

if (root) {
  ReactDOM.createRoot(root).render(
    <React.StrictMode>
      <Router />
    </React.StrictMode>,
  )
}
