export const isCosmosAccount = (account) => {
    return "publicKey" in account;
};
export const isEthereumAccount = (account) => {
    return "ethereumAddress" in account && !("publicKey" in account);
};
export var ConnectedAgentType;
(function (ConnectedAgentType) {
    ConnectedAgentType["generic"] = "generic";
    ConnectedAgentType["cosmos"] = "cosmos";
    ConnectedAgentType["ethereum"] = "ethereum";
})(ConnectedAgentType || (ConnectedAgentType = {}));
