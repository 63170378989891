import { useEffect } from "react"
import NavigationModal from "@levana-protocol/ui/NavigationModal"

import { track } from "@perps/analytics/track"
import { Event } from "@perps/analytics/events"

import BridgeContent, { type BridgeContentProps } from "./BridgeContent"
import { BridgeContentId } from "./constants"

export type BridgeModalProps = Omit<BridgeContentProps, "modal">

const BridgeModal = (props: BridgeModalProps) => {
  useEffect(() => {
    track(Event.openedBridge(props.marketId))
  }, [props.marketId])

  return (
    <NavigationModal rootId={BridgeContentId}>
      {(modal) => <BridgeContent {...props} modal={modal} />}
    </NavigationModal>
  )
}

export default BridgeModal
