import { useQuery } from "@tanstack/react-query"
import { useStore } from "zustand"
import { useCallback } from "react"
import { useAccount } from "@levana/chain/account"

import { SECOND } from "@common/utils/date"
import type { ContextStoreProp } from "@future/context/store"
import type { Collateral, NativeToken } from "@future/numerics"
import type { MarketId } from "@perps/sdk/types"
import { Amount } from "@future/numerics/amount"

import type { QueryOptions } from "./types"
import { useSkipAssetsQuery } from "./skipAssets"

export type WalletBalanceMap = Map<MarketId, WalletBalance>

interface WalletBalance {
  collateral: Collateral
  gas: NativeToken
}

export const useWalletNativeCoinBalancesQuery = (
  props: ContextStoreProp<"standard">,
  options?: QueryOptions,
) => {
  const chainClient = useStore(
    props.contextStore,
    (state) => state.chain.client,
  )
  const chainId = useStore(
    props.contextStore,
    (state) => state.chain.config.chainId,
  )
  const { data: account } = useAccount()
  const walletAddress = account?.address
  const skipAssetsQuery = useSkipAssetsQuery(chainId)

  return useQuery({
    queryKey: ["walletNativeCoinBalances", walletAddress],
    refetchInterval: SECOND * 30,
    enabled: walletAddress !== undefined && skipAssetsQuery.data !== undefined,
    queryFn: async (context) => {
      if (!walletAddress) {
        return {}
      }

      return chainClient.queryAllNativeCoinBalances(
        walletAddress,
        context.signal,
      )
    },
    select: useCallback(
      (data: Record<string, string>) => {
        const balances = new Map<
          string,
          { nominated: Amount; denominated: Amount }
        >()

        for (const denom in data) {
          const denominated = new Amount(data[denom])

          const assets = skipAssetsQuery.data ?? []
          const asset = assets.find((asset) => asset.denom === denom)

          if (asset?.decimals === undefined) {
            continue
          }

          const nominated = Amount.denominatedToNominated(
            denominated,
            asset.decimals,
          )

          if (!nominated) {
            continue
          }

          balances.set(denom, { nominated, denominated })
        }

        return balances
      },
      [skipAssetsQuery.data],
    ),
    ...options,
  })
}
