import ListItemContent from "@mui/joy/ListItemContent"
import ListItemDecorator from "@mui/joy/ListItemDecorator"

import type { NavItem } from "./navItems"

interface NavItemContentProps {
  navItem: NavItem
}

const NavItemContent = (props: NavItemContentProps) => {
  return (
    <>
      <ListItemDecorator>
        <props.navItem.icon fontSize="xl2" />
      </ListItemDecorator>
      <ListItemContent>{props.navItem.title}</ListItemContent>
    </>
  )
}

export default NavItemContent
