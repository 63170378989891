import { Link } from "react-router-dom"
import { useStore } from "zustand"

import { renderRoute } from "@future/router/Router"
import LevanaLogo from "@common/ui/LevanaLogo"
import type { ContextStoreProp } from "@future/context/store"
import { RouteRootId } from "@future/router/types"

const HeaderLogo = (props: ContextStoreProp<"initial">) => {
  const targetSlug = useStore(
    props.contextStore,
    (state) => state.route.targetSlug,
  )
  const marketSlug = useStore(
    props.contextStore,
    (state) => state.route.marketSlug ?? state.route.fallbackMarketSlug,
  )

  return (
    <Link
      to={renderRoute({ route: RouteRootId.trade, targetSlug, marketSlug })}
      aria-label="logo"
    >
      <LevanaLogo />
    </Link>
  )
}

export default HeaderLogo
