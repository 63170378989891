import { queryOptions, useQuery } from "@tanstack/react-query"
import { useStore } from "zustand"
import { useAccount } from "@levana/chain/account"

import { MINUTE } from "@common/utils/date"
import { httpGet } from "@future/utils/http"
import { indexerUrl } from "@future/target/domain"
import {
  type EarnMarketMap,
  type EarnMarketResp,
  earnMarketFromResp,
} from "@future/market/earnChart/types"
import type { ContextStoreProp } from "@future/context/store"
import type { MarketId } from "@perps/sdk/types"
import type { MarketLookup } from "@future/context/createMarketLookup"
import type { ChainNetworkId } from "@common/target/types"

import type { QueryOptions } from "./types"

export const earnMarketsQueryOptions = (
  factoryAddress: string,
  chainNetworkId: ChainNetworkId,
  walletAddress: string | undefined,
  marketLookup: MarketLookup,
) => {
  return queryOptions({
    queryKey: [
      "earnMarkets",
      factoryAddress,
      chainNetworkId,
      walletAddress,
      marketLookup,
    ],
    refetchInterval: MINUTE,
    queryFn: async (context) => {
      const params = new URLSearchParams({
        network: chainNetworkId,
        factory: factoryAddress,
        ...(walletAddress && { wallet: walletAddress }),
      })

      const resp = await httpGet<Record<MarketId, EarnMarketResp>>(
        `${indexerUrl}/v2/markets-earn-data?${params.toString()}`,
        { signal: context.signal },
      )

      const data: EarnMarketMap = new Map()

      for (const marketAddress of marketLookup.addresses) {
        const marketId = marketLookup.addressToId(marketAddress)

        if (marketId && marketAddress in resp.data) {
          data.set(marketId, earnMarketFromResp(resp.data[marketAddress]))
        } else {
          console.warn(
            `Chart data not found for market ${marketId} (${marketAddress})`,
          )
        }
      }

      return data
    },
  })
}

export const useEarnMarketsQuery = (
  props: ContextStoreProp<"standard">,
  options?: QueryOptions,
) => {
  const factoryAddress = useStore(
    props.contextStore,
    (state) => state.chain.config.factoryAddress,
  )
  const chainNetworkId = useStore(
    props.contextStore,
    (state) => state.chain.config.chainNetworkId,
  )
  const { data: account } = useAccount()
  const marketLookup = useStore(
    props.contextStore,
    (state) => state.marketLookup,
  )
  return useQuery({
    ...earnMarketsQueryOptions(
      factoryAddress,
      chainNetworkId,
      account?.address,
      marketLookup,
    ),
    ...options,
  })
}
