import { queryOptions, useQuery } from "@tanstack/react-query"
import { useStore } from "zustand"
import { useAccount } from "@levana/chain/account"

import type CosmosClient from "@perps/sdk/client/CosmosClient"
import { SECOND } from "@common/utils/date"
import type { MarketId } from "@perps/sdk/types"
import type { ContextStoreProp } from "@future/context/store"
import { Collateral, LpToken, Usd } from "@future/numerics"
import {
  type LpInfo,
  lpInfoFromResp,
  type LpInfoMap,
} from "@future/market/lp/types"
import type { FullMarketInfo } from "@perps/sdk/client/CosmosClient"

import type { QueryOptions } from "./types"

export const marketsLpInfoQueryOptions = (
  chainClient: CosmosClient,
  walletAddress: string | undefined,
  markets: Map<MarketId, FullMarketInfo>,
) => {
  return queryOptions({
    queryKey: ["marketsLpInfo", walletAddress, markets],
    refetchInterval: SECOND * 20,
    queryFn: async (context) => {
      if (!walletAddress) {
        const map: LpInfoMap = new Map()

        for (const market of markets.values()) {
          const defaultLpInfo: LpInfo = {
            availableCrankRewards: new Collateral(0),
            availableYield: new Collateral(0),
            availableYieldLp: new Collateral(0),
            availableYieldXlp: new Collateral(0),
            history: {
              deposit: new Collateral(0),
              depositUsd: new Usd(0),
              yield: new Collateral(0),
              yieldUsd: new Usd(0),
            },
            lpAmount: new LpToken(0),
            lpCollateral: new Collateral(0),
            xlpAmount: new LpToken(0),
            xlpCollateral: new Collateral(0),
          }

          map.set(market.config.id, defaultLpInfo)
        }

        return map
      }

      const allLpInfosRaw = await Promise.all(
        [...markets.values()].map(async (market) => {
          const resp = await chainClient.queryLpInfo({
            marketAddress: market.config.marketAddress,
            walletAddress,
            abortSignal: context.signal,
          })
          return { resp, marketId: market.config.id }
        }),
      )

      return allLpInfosRaw.reduce<LpInfoMap>((acc, { resp, marketId }) => {
        acc.set(marketId, lpInfoFromResp(resp))
        return acc
      }, new Map())
    },
  })
}

export const useMarketsLpInfoQuery = (
  props: ContextStoreProp<"standard">,
  options?: QueryOptions,
) => {
  const client = useStore(props.contextStore, (state) => state.chain.client)
  const { data: account } = useAccount()
  const markets = useStore(props.contextStore, (state) => state.markets)
  return useQuery({
    ...marketsLpInfoQueryOptions(client, account?.address, markets),
    ...options,
  })
}
