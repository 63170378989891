import type { TargetConfigCw20 } from "@future/target/config"
import type CosmosClient from "@perps/sdk/client/CosmosClient"
import type { TapEligibleResponse } from "@perps/sdk/types"

/**
 * Throws an exception if anything fails
 */
export const tryCanTapFaucet = async (props: {
  contractClient: CosmosClient
  faucetAddress?: string
  cw20s: TargetConfigCw20[]
  walletAddress: string
}): Promise<string | undefined> => {
  const { contractClient, faucetAddress, cw20s, walletAddress } = props
  if (faucetAddress === undefined) {
    throw new Error("No faucet address found")
  }

  const cw20Addresses = cw20s.map((cw20) => cw20.address)
  const abortController = new AbortController()

  const canTapResponse: TapEligibleResponse =
    await contractClient.queryCanTapFaucet({
      faucetAddress,
      walletAddress,
      cw20Addresses,
      abortSignal: abortController.signal,
    })

  return "ineligible" in canTapResponse
    ? canTapResponse.ineligible.message
    : undefined
}
