import type {
  LiquidityCooldown,
  LpInfoResp,
  Timestamp,
} from "@perps/sdk/types/.generated/market_response_to_lp_info"

import { Collateral, LpToken, Usd } from "@future/numerics"
import type { MarketId } from "@perps/sdk/types"

import type { LiquidityStats } from "../status/types"

export type LpInfoMap = Map<MarketId, LpInfo>

export interface LpInfoAndStats {
  lpInfo: LpInfo
  liquidityStats: LiquidityStats
}

export interface LpInfo {
  /**
   * Available crank rewards
   */
  availableCrankRewards: Collateral
  /**
   * Total available yield, sum of the available LP, xLP, and crank rewards.
   */
  availableYield: Collateral
  /**
   * Available yield from LP tokens
   */
  availableYieldLp: Collateral
  /**
   * Available yield from xLP tokens
   */
  availableYieldXlp: Collateral
  /**
   * Historical information on LP activity
   */
  history: LpHistorySummary
  /**
   * This LP amount includes both actual LP tokens and xLP unstaked to LP but not yet collected.
   */
  lpAmount: LpToken
  /**
   * Collateral backing the LP tokens
   */
  lpCollateral: Collateral
  /**
   * Current status of an unstaking, if under way
   *
   * This will return `Some` from the time the provider begins an unstaking process until either:
   *
   * 1. They either cancel it, _or_ 2. They unstake all request xLP into LP _and_ collect that LP within the contract.
   */
  unstaking?: UnstakingStatus
  /**
   * This shows the balance of xLP minus any xLP already unstaked.
   */
  xlpAmount: LpToken
  /**
   * Collateral backing the xLP tokens
   */
  xlpCollateral: Collateral
  liquidityCooldown?: LiquidityCooldown
}

export interface LpHistorySummary {
  /**
   * How much collateral was deposited in total
   */
  deposit: Collateral
  /**
   * Value of the collateral in USD at time of deposit
   */
  depositUsd: Usd
  /**
   * Cumulative yield claimed by the provider
   */
  yield: Collateral
  /**
   * Cumulative yield expressed in USD at time of claiming
   */
  yieldUsd: Usd
}

export interface UnstakingStatus {
  /**
   * Total amount of LP tokens that have been unstaked and not yet collected
   */
  available: LpToken
  /**
   * Total amount of LP tokens that have been unstaked and collected
   */
  collected: LpToken
  /**
   * This will be in the future if unstaking is incomplete
   */
  end: Timestamp
  /**
   * Total amount of xLP tokens that are still pending unstaking
   */
  pending: LpToken
  /**
   * When the unstaking began
   */
  start: Timestamp
  /**
   * Total amount requested to be unstaked
   *
   * Note that this value must be the sum of collected, available, and pending.
   */
  xlpUnstaking: LpToken
  /**
   * Collateral, at current exchange rate, underlying the [UnstakingStatus::xlp_unstaking]
   */
  xlpUnstakingCollateral: Collateral
}

export const lpInfoFromResp = (lpInfo: LpInfoResp): LpInfo => {
  return {
    availableCrankRewards: new Collateral(lpInfo.available_crank_rewards),
    availableYield: new Collateral(lpInfo.available_yield),
    availableYieldLp: new Collateral(lpInfo.available_yield_lp),
    availableYieldXlp: new Collateral(lpInfo.available_yield_xlp),
    history: {
      deposit: new Collateral(lpInfo.history.deposit),
      depositUsd: new Usd(lpInfo.history.deposit_usd),
      yield: new Collateral(lpInfo.history.yield),
      yieldUsd: new Usd(lpInfo.history.yield_usd),
    },
    lpAmount: new LpToken(lpInfo.lp_amount),
    lpCollateral: new Collateral(lpInfo.lp_collateral),
    unstaking: lpInfo.unstaking
      ? {
          available: new LpToken(lpInfo.unstaking.available),
          collected: new LpToken(lpInfo.unstaking.collected),
          end: lpInfo.unstaking.end,
          pending: new LpToken(lpInfo.unstaking.pending),
          start: lpInfo.unstaking.start,
          xlpUnstaking: new LpToken(lpInfo.unstaking.xlp_unstaking),
          xlpUnstakingCollateral: new Collateral(
            lpInfo.unstaking.xlp_unstaking_collateral,
          ),
        }
      : undefined,
    xlpAmount: new LpToken(lpInfo.xlp_amount),
    xlpCollateral: new Collateral(lpInfo.xlp_collateral),
    liquidityCooldown: lpInfo.liquidity_cooldown
      ? lpInfo.liquidity_cooldown
      : undefined,
  }
}
