var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import { useChainStore, useAccountStore, } from "../shared/store";
import { WalletType } from "../shared/wallet";
import { useConnect } from "./account";
import { setInjectiveWalletStrategy } from "../injective/wallet";
import { getWallet } from "../wallet";
export const ChainProvider = (props) => {
    useEffect(() => {
        useChainStore.setState((prev) => {
            var _a, _b, _c, _d, _e, _f, _g;
            return ({
                chains: (_c = (_b = (_a = props.options) === null || _a === void 0 ? void 0 : _a.cosmos) === null || _b === void 0 ? void 0 : _b.chains) !== null && _c !== void 0 ? _c : prev.chains,
                chainsConfig: (_f = (_e = (_d = props.options) === null || _d === void 0 ? void 0 : _d.cosmos) === null || _e === void 0 ? void 0 : _e.chainsConfig) !== null && _f !== void 0 ? _f : prev.chainsConfig,
                options: (_g = props.options) !== null && _g !== void 0 ? _g : prev.options,
            });
        });
    }, [props.options]);
    return (_jsxs(_Fragment, { children: [(props.onConnected || props.onDisconnected || props.onConnectError) && (_jsx(ConnectCallback, Object.assign({}, props))), _jsx(ChainDependantComponents, {}), props.children] }));
};
export const setChainInfo = (args) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const chainId = (_a = args.chainId) !== null && _a !== void 0 ? _a : useChainStore.getState().chainId;
    if (args.rpc) {
        const chains = [...useChainStore.getState().chains];
        const index = chains.findIndex((chain) => chain.chainId === chainId);
        if (index > -1 && chains[index].rpc !== args.rpc) {
            chains[index] = Object.assign(Object.assign({}, chains[index]), { rpc: args.rpc });
            useChainStore.setState({ chains });
        }
    }
    if (args.chainId) {
        yield setInjectiveWalletStrategy(args.chainId);
        // Must be set last
        useChainStore.setState({ chainId: args.chainId });
    }
});
const ConnectCallback = (props) => {
    const propsRef = useRef(props);
    useEffect(() => {
        propsRef.current = props;
    }, [props]);
    useEffect(() => {
        return useAccountStore.subscribe((state, previousState) => {
            var _a;
            if (propsRef.current.onConnected &&
                state.status === "connected" &&
                previousState.status !== "connected") {
                const { chainId } = useChainStore.getState();
                const account = state.activeAccount(chainId);
                if (account) {
                    propsRef.current.onConnected(account);
                }
            }
            if (propsRef.current.onDisconnected &&
                state.status === "disconnected" &&
                previousState.status === "connected") {
                propsRef.current.onDisconnected();
            }
            if (propsRef.current.onConnectError &&
                state.connectIssue &&
                state.connectIssue.error !== ((_a = previousState.connectIssue) === null || _a === void 0 ? void 0 : _a.error)) {
                propsRef.current.onConnectError(state.connectIssue.error);
            }
        });
    }, []);
    return null;
};
/**
 * The `chainId` might be an empty string. In this case, related components
 * should wait to load until there's a valid value.
 */
const ChainDependantComponents = () => {
    const chainId = useChainStore((state) => state.chainId);
    if (chainId.length === 0) {
        return null;
    }
    return (_jsxs(_Fragment, { children: [_jsx(InitialViewingConnectStatus, {}), _jsx(ChangeAccountAutoConnect, {}), _jsx(CrossChainAutoConnect, {})] }));
};
const InitialViewingConnectStatus = () => {
    useEffect(() => {
        const { chainId } = useChainStore.getState();
        const account = useAccountStore.getState().activeAccount(chainId);
        if (account && account.walletType === WalletType.viewing) {
            useAccountStore.setState({ status: "connected" });
        }
    }, []);
    return null;
};
const ChangeAccountAutoConnect = () => {
    const walletType = useAccountStore((state) => state.walletType());
    const { connect } = useConnect();
    const connectRef = useRef(connect);
    useEffect(() => {
        connectRef.current = connect;
    }, [connect]);
    useEffect(() => {
        var _a;
        if (!walletType || walletType === WalletType.viewing) {
            return;
        }
        const provider = getWallet(walletType).provider();
        return (_a = provider.subscription) === null || _a === void 0 ? void 0 : _a.call(provider, () => connectRef.current({ walletType }));
    }, [walletType]);
    return null;
};
const CrossChainAutoConnect = () => {
    const { connect } = useConnect();
    const connectRef = useRef(connect);
    useEffect(() => {
        connectRef.current = connect;
    }, [connect]);
    const chainId = useChainStore((state) => state.chainId);
    useEffect(() => {
        if (chainId.length === 0) {
            return;
        }
        const walletType = useAccountStore.getState().walletType();
        if (walletType && walletType !== WalletType.viewing) {
            connectRef.current({ walletType });
        }
    }, [chainId]);
    return null;
};
