import { queryOptions, useQuery } from "@tanstack/react-query"
import { useStore } from "zustand"
import { useAccount } from "@levana/chain/account"

import { MINUTE } from "@common/utils/date"
import type { ContextStoreProp } from "@future/context/store"
import type { ChainNetworkId } from "@common/target/types"
import { AppError } from "@future/libs/error/AppError"
import { Usd } from "@future/numerics"
import { Amount } from "@future/numerics/amount"
import { queryQuerier } from "@perps/sdk/client/query"

import type { QueryOptions } from "./types"

export interface ReferralStats {
  /**
   * Rewards generated by this wallet
   */
  generatedRewards: Usd
  /**
   * Rewards received by this wallet
   */
  receivedRewards: Usd
  /**
   * The total number of referees associated with this wallet
   */
  refereesCount: Amount
  /**
   * A wallet address of the referrer
   */
  referrerAddress?: string
}

interface ReferralStatsResponse {
  generated_usd: string
  received_usd: string
  referees: number
  referrer?: string
}

export const referralStatsQueryOptions = (
  factoryAddress: string,
  chainNetworkId: ChainNetworkId,
  walletAddress: string | undefined,
) => {
  return queryOptions({
    queryKey: ["referralStats", factoryAddress, chainNetworkId, walletAddress],
    refetchInterval: MINUTE,
    enabled: walletAddress !== undefined,
    queryFn: async (context) => {
      if (!walletAddress) {
        throw AppError.fromText("No wallet address for referral stats")
      }

      const response = await queryQuerier<ReferralStatsResponse>(
        "/v1/perps/referral-stats",
        "Unable to fetch referral stats",
        context.signal,
        {
          network: chainNetworkId,
          factory: factoryAddress,
          wallet: walletAddress,
        },
      )

      const referralStats: ReferralStats = {
        generatedRewards: new Usd(response.generated_usd),
        receivedRewards: new Usd(response.received_usd),
        refereesCount: new Amount(response.referees),
        referrerAddress: response.referrer,
      }

      return referralStats
    },
  })
}

export const useReferralStatsQuery = (
  props: ContextStoreProp<"standard">,
  options?: QueryOptions,
) => {
  const factoryAddress = useStore(
    props.contextStore,
    (state) => state.chain.config.factoryAddress,
  )
  const chainNetworkId = useStore(
    props.contextStore,
    (state) => state.chain.config.chainNetworkId,
  )
  const { data: account } = useAccount()
  return useQuery({
    ...referralStatsQueryOptions(
      factoryAddress,
      chainNetworkId,
      account?.address,
    ),
    ...options,
  })
}
