import Button, { type ButtonProps } from "@mui/joy/Button"
import { useTheme } from "@mui/joy/styles"
import useMediaQuery from "@mui/system/useMediaQuery"
import { WalletType } from "@levana/chain/wallet"
import { type Account, truncateAddress } from "@levana/chain/account"
import { forwardRef } from "react"
import type { Breakpoint } from "@mui/system"

import WalletAvatar from "@common/ui/WalletAvatar"

interface ConnectedButtonProps extends ButtonProps {
  account: Account
  resizeBreakpoint?: Breakpoint
}

const ConnectedButton = forwardRef<HTMLButtonElement, ConnectedButtonProps>(
  (props, ref) => {
    const { account, resizeBreakpoint = "sm", ...buttonProps } = props
    const theme = useTheme()
    const smallQuery = theme.breakpoints.between("xs", resizeBreakpoint)
    const isSmallScreen = useMediaQuery(smallQuery)

    return (
      <Button
        {...buttonProps}
        ref={ref}
        variant="outlined"
        color="neutral"
        startDecorator={
          <WalletAvatar
            address={account.address}
            variant="outlined"
            viewing={account.walletType === WalletType.viewing}
          />
        }
        sx={{
          "--Avatar-size": { xs: "2.25rem", [resizeBreakpoint]: "1.5rem" },
          "--Button-gap": { xs: 0, [resizeBreakpoint]: "0.75rem" },
          "--variant-borderWidth": { xs: 0, [resizeBreakpoint]: "2px" },
          paddingInline: { xs: 0, [resizeBreakpoint]: "0.3rem 0.5rem" },

          [smallQuery]: {
            paddingBlock: 0,
          },
        }}
        aria-label="connected menu"
      >
        {!isSmallScreen && truncateAddress(account.address)}
      </Button>
    )
  },
)

export default ConnectedButton
