import type { DirectionToBase } from "@perps/sdk/types/.generated/market_execute"
import type { MarketType } from "@perps/sdk/types/.generated/market_response_to_status"
import { useStore } from "zustand"

import {
  Amount,
  type ConstructableAmount,
  round,
} from "@future/numerics/amount"
import type { OpenPosition } from "@future/network/marketsOpenPositions/types"
import type { MarketConfig } from "@future/market/config/types"
import { MarketPrice } from "@future/market/price/types"
import {
  type Base,
  Collateral,
  Notional,
  Quote,
  Usd,
  MaxGains,
  MaxGainsPercentage,
  TakeProfit,
} from "@future/numerics"
import type { MarketStatus } from "@future/market/status/types"
import type { ContextStore } from "@future/context/store"

export const directionToNumber = (direction: DirectionToBase) => {
  return direction === "long" ? 1 : -1
}

export interface CalculateDeltaNeutralityFeeProps {
  oldNotional: Notional
  newNotional: Notional
  marketPrice: MarketPrice
  netNotional: Notional
  deltaNeutralityFeeFund: Collateral
  deltaNeutralityFeeCap: ConstructableAmount
  deltaNeutralityFeeSensitivity: ConstructableAmount
  deltaNeutralityFeeTax: ConstructableAmount
}

interface DnfDetails {
  amount: Usd
  newDnfFund: Collateral
  newNetNotional: Notional
}

export const calculateDnfDetails = (
  props: CalculateDeltaNeutralityFeeProps,
): DnfDetails => {
  const calculateDNF = (
    cap: Amount,
    sensitivity: Amount,
    netNotional: Notional,
    deltaNotional: Notional,
  ) => {
    const notionalLowCap = new Notional(cap.negate().times(sensitivity))
    const notionalHighCap = new Notional(cap.times(sensitivity))

    const deltaNotionalAtLowCap = netNotional
      .plus(deltaNotional)
      .min(notionalLowCap)
      .minus(netNotional.min(notionalLowCap))
    const deltaNotionalAtHighCap = netNotional
      .plus(deltaNotional)
      .max(notionalHighCap)
      .minus(netNotional.max(notionalHighCap))
    const deltaNotionalUncapped = deltaNotional
      .minus(deltaNotionalAtLowCap)
      .minus(deltaNotionalAtHighCap)

    const deltaNotionalFeeLow = deltaNotionalAtLowCap.times(
      new Notional(cap.negate()),
    )
    const deltaNotionalFeeHigh = deltaNotionalAtHighCap.times(new Notional(cap))
    const deltaNotionalFeeUncapped = deltaNotionalUncapped
      .times(deltaNotionalUncapped)
      .plus(
        new Notional(2)
          .times(deltaNotionalUncapped)
          .times(netNotional.min(notionalHighCap).max(notionalLowCap)),
      )
      .divide(new Notional(sensitivity).times(2))

    return new Notional(
      deltaNotionalFeeLow
        .plus(deltaNotionalFeeHigh)
        .plus(deltaNotionalFeeUncapped),
    )
  }

  const { oldNotional, newNotional } = props
  let deltaNeutralityFeeFund = props.deltaNeutralityFeeFund
  let netNotional = props.netNotional
  const cap = new Amount(props.deltaNeutralityFeeCap)
  const sensitivity = new Amount(props.deltaNeutralityFeeSensitivity)

  let fees = new Collateral(0)
  const calcInner = (deltaNotionalProp: ConstructableAmount) => {
    const feeFund = deltaNeutralityFeeFund.plus(fees)
    const deltaNotional = new Notional(deltaNotionalProp)

    const feeInNotional = calculateDNF(
      cap,
      sensitivity,
      netNotional,
      deltaNotional,
    )
    const feeInCollateral =
      props.marketPrice.notionalToCollateral(feeInNotional)

    let fee = feeInCollateral
    if (feeInCollateral.isLessThan(0)) {
      const feeToBalanceInNotional = calculateDNF(
        cap,
        sensitivity,
        netNotional,
        netNotional.negate(),
      ).abs()
      const feeToBalanceInCollateral = props.marketPrice.notionalToCollateral(
        feeToBalanceInNotional,
      )

      const fundednessRatio = feeToBalanceInCollateral.abs().isLessThan(1e-6)
        ? new Collateral(1)
        : feeFund.divide(feeToBalanceInCollateral)

      fee = new Collateral(feeInCollateral.times(fundednessRatio.min(1)))
    }

    netNotional = netNotional.plus(deltaNotional)
    fees = fees.minus(fee)
    return fee
  }

  const deltaNotional = newNotional.minus(oldNotional)
  const netNotionalAfter = netNotional.plus(deltaNotional)

  const amount = ((): Collateral => {
    if (netNotional.times(netNotionalAfter).isLessThan(0)) {
      const deltaNotionalSecondCalc = deltaNotional.plus(netNotional)
      const part1 = calcInner(netNotional.negate())
      const part2 = calcInner(deltaNotionalSecondCalc)
      return part1.plus(part2)
    } else {
      return calcInner(deltaNotional)
    }
  })()

  if (amount.isGreaterThan(0)) {
    deltaNeutralityFeeFund = deltaNeutralityFeeFund.plus(
      new Collateral(
        amount
          .toAmount()
          .times(new Amount(1).minus(props.deltaNeutralityFeeTax)),
      ),
    )
  } else {
    deltaNeutralityFeeFund = deltaNeutralityFeeFund.plus(amount)
  }

  return {
    amount: props.marketPrice.collateralToUsd(amount),
    newDnfFund: deltaNeutralityFeeFund,
    newNetNotional: netNotional,
  }
}

export const calculateDeltaNeutralityFee = (
  props: CalculateDeltaNeutralityFeeProps,
): Usd => {
  const details = calculateDnfDetails(props)
  return details.amount
}

export const calculateDeltaNeutralityTax = (
  props: CalculateDeltaNeutralityFeeProps,
) => {
  const detailsOnOpen = calculateDnfDetails(props)
  const propsOnClose: CalculateDeltaNeutralityFeeProps = {
    ...props,
    oldNotional: props.newNotional,
    newNotional: props.oldNotional,
    netNotional: detailsOnOpen.newNetNotional,
    deltaNeutralityFeeFund: detailsOnOpen.newDnfFund,
  }
  const detailsOnClose = calculateDnfDetails(propsOnClose)

  return {
    dnfOnOpen: detailsOnOpen.amount,
    tax: detailsOnOpen.amount.plus(detailsOnClose.amount),
  }
}

export const calculatePriceBaseDNFImpacted = (
  props: CalculateDeltaNeutralityFeeProps,
  dnf: Usd,
): MarketPrice => {
  const priceNotional = props.marketPrice.priceNotionalInCollateral()
  const feeRate = props.marketPrice
    .usdToCollateral(dnf)
    .toAmount()
    .divide(props.newNotional.minus(props.oldNotional))
  const impactedPriceNotional = priceNotional.times(new Amount(1).plus(feeRate))
  const impactedPriceBase = (() => {
    switch (props.marketPrice.marketType) {
      case "collateral_is_base":
        return new Amount(1).divide(impactedPriceNotional)
      case "collateral_is_quote":
        return impactedPriceNotional
    }
  })()

  return MarketPrice.modifiedPriceBase(props.marketPrice, impactedPriceBase)
}

export const calculatePriceBaseDNFImpactedFromDeps = (
  marketPrice: MarketPrice,
  marketConfig: MarketConfig,
  marketStatus: MarketStatus,
  oldNotional: Notional,
  newNotional: Notional,
) => {
  const dnfProps = {
    marketPrice,
    deltaNeutralityFeeCap: marketConfig.deltaNeutralityFeeCap,
    deltaNeutralityFeeFund: marketStatus.deltaNeutralityFeeFund,
    deltaNeutralityFeeSensitivity: marketConfig.deltaNeutralityFeeSensitivity,
    deltaNeutralityFeeTax: marketConfig.deltaNeutralityFeeTax,
    netNotional: marketStatus.netNotional,
    newNotional,
    oldNotional,
  }
  const deltaNeutralityFeeAsset = calculateDeltaNeutralityFee(dnfProps)
  const priceBaseImpacted = calculatePriceBaseDNFImpacted(
    dnfProps,
    deltaNeutralityFeeAsset,
  )

  return priceBaseImpacted.priceBase
}

export interface CalculateFeesProps {
  oldNotional: Notional
  newNotional: Notional
  oldCounterCollateral: Collateral
  newCounterCollateral: Collateral
  newMinCounterCollateral: Collateral
  marketPrice: MarketPrice
  tradingFeeNotionalRate: ConstructableAmount
  counterSideCollateralFeeRate: ConstructableAmount
  borrowFee: ConstructableAmount
}

export const calculateFees = (props: CalculateFeesProps) => {
  const {
    oldNotional,
    newNotional,
    marketPrice,
    tradingFeeNotionalRate,
    counterSideCollateralFeeRate,
  } = props

  const oldNotionalInCollateral = marketPrice.notionalToCollateral(
    new Notional(oldNotional.abs()),
  )
  const newNotionalInCollateral = marketPrice.notionalToCollateral(
    new Notional(newNotional).abs(),
  )

  const oldCounterCollateral = new Collateral(props.oldCounterCollateral)
  const newCounterCollateral = new Collateral(props.newCounterCollateral)

  let tradingFeeNotional: Collateral
  if (newNotionalInCollateral.isGreaterThan(oldNotionalInCollateral)) {
    tradingFeeNotional = new Collateral(
      newNotionalInCollateral
        .toAmount()
        .minus(oldNotionalInCollateral)
        .times(tradingFeeNotionalRate),
    )
  } else {
    tradingFeeNotional = new Collateral(0)
  }

  let tradingFeeCounterCollateral: Collateral
  if (newCounterCollateral.isGreaterThan(oldCounterCollateral)) {
    tradingFeeCounterCollateral = new Collateral(
      newCounterCollateral
        .minus(oldCounterCollateral)
        .times(new Collateral(counterSideCollateralFeeRate)),
    )
  } else {
    tradingFeeCounterCollateral = new Collateral(0)
  }

  const tradingFee = marketPrice.collateralToUsd(
    tradingFeeNotional.plus(tradingFeeCounterCollateral),
  )

  // Borrow fee is annualized, and locked profit is in collateral. Convert to
  // USD and express hourly.
  const borrowFee = marketPrice.collateralToUsd(
    new Collateral(
      newCounterCollateral
        .max(props.newMinCounterCollateral)
        .toAmount()
        .times(props.borrowFee)
        .divide(365 * 24),
    ),
  )

  return {
    tradingFee,
    borrowFee,
  }
}

export interface CalculateDeferredExecutionCrankFeeProps {
  marketConfig: MarketConfig
  marketStatus: MarketStatus
}

export const calculateDeferredExecutionCrankFee = (
  props: CalculateDeferredExecutionCrankFeeProps,
) => {
  return new Usd((props.marketStatus.deferredExecutionItems + 5) / 10)
    .round(round.down)
    .times(props.marketConfig.crankFeeSurcharge)
    .plus(props.marketConfig.crankFeeCharged)
}

export interface CalculateNotionalSizeProps {
  direction: DirectionToBase
  collateral: Collateral
  leverage: ConstructableAmount
  marketPrice: MarketPrice
}

export const calculateNotionalSize = (props: CalculateNotionalSizeProps) => {
  const direction = new Amount(directionToNumber(props.direction))
  const collateral = props.collateral
  const leverage = new Amount(props.leverage)

  if (props.marketPrice.marketType === "collateral_is_quote") {
    return props.marketPrice.collateralToNotional(
      new Collateral(collateral.toAmount().times(leverage).times(direction)),
    )
  } else {
    const notionalSizeCollateral = new Collateral(
      collateral.toAmount().times(direction.negate().times(leverage).plus(1)),
    )

    return props.marketPrice.collateralToNotional(notionalSizeCollateral)
  }
}

export interface CalculatePositionStatsProps {
  direction: DirectionToBase
  collateral: Collateral
  leverage: ConstructableAmount
  takeProfitPrice: ConstructableAmount
  marketPrice: MarketPrice
  tradingFeeNotionalRate: ConstructableAmount
  counterSideCollateralFeeRate: ConstructableAmount
  borrowFee: ConstructableAmount
  borrowFeeRateCap: ConstructableAmount
  fundingFeeRateCap: ConstructableAmount
  deltaNeutralityFeeCap: ConstructableAmount
  deltaNeutralityFeeFund: Collateral
  deltaNeutralityFeeSensitivity: ConstructableAmount
  deltaNeutralityFeeTax: ConstructableAmount
  netNotional: Notional
  crankFee: Usd
  liquifundingDelaySeconds: ConstructableAmount
  unlockedLiquidity: Collateral
  carryLeverage: ConstructableAmount
  maxLeverage: ConstructableAmount
  exposureMarginRatio: ConstructableAmount
}

// return a plain object that's friendly for serialization
// helpful for creating debuggable tests
export const calculatePositionStatsPropsToPlainObject = (
  props: CalculatePositionStatsProps,
) => {
  return {
    direction: props.direction,
    collateral: props.collateral.toString(),
    leverage: props.leverage.toString(),
    takeProfitPrice: props.takeProfitPrice.toString(),
    marketPrice: props.marketPrice.toPlainObject(),
    tradingFeeNotionalRate: props.tradingFeeNotionalRate.toString(),
    counterSideCollateralFeeRate: props.counterSideCollateralFeeRate.toString(),
    borrowFee: props.borrowFee.toString(),
    borrowFeeRateCap: props.borrowFeeRateCap.toString(),
    fundingFeeRateCap: props.fundingFeeRateCap.toString(),
    deltaNeutralityFeeCap: props.deltaNeutralityFeeCap.toString(),
    deltaNeutralityFeeFund: props.deltaNeutralityFeeFund.toString(),
    deltaNeutralityFeeSensitivity:
      props.deltaNeutralityFeeSensitivity.toString(),
    deltaNeutralityFeeTax: props.deltaNeutralityFeeTax.toString(),
    netNotional: props.netNotional.toString(),
    crankFee: props.crankFee.toString(),
    liquifundingDelaySeconds: props.liquifundingDelaySeconds.toString(),
    unlockedLiquidity: props.unlockedLiquidity.toString(),
    carryLeverage: props.carryLeverage.toString(),
    maxLeverage: props.maxLeverage.toString(),
    exposureMarginRatio: props.exposureMarginRatio.toString(),
  }
}

// convert from an object created via calculatePositionStatsPropsToPlainObject
// helpful for creating debuggable tests
export const calculatePositionStatsPropsFromPlainObject = (
  props: ReturnType<typeof calculatePositionStatsPropsToPlainObject>,
): CalculatePositionStatsProps => {
  return {
    direction: props.direction,
    collateral: new Collateral(props.collateral),
    leverage: new Amount(props.leverage),
    takeProfitPrice: new TakeProfit(props.takeProfitPrice),
    marketPrice: MarketPrice.fromPlainObject(props.marketPrice),
    tradingFeeNotionalRate: new Amount(props.tradingFeeNotionalRate),
    counterSideCollateralFeeRate: new Amount(
      props.counterSideCollateralFeeRate,
    ),
    borrowFee: new Amount(props.borrowFee),
    borrowFeeRateCap: new Amount(props.borrowFeeRateCap),
    fundingFeeRateCap: new Amount(props.fundingFeeRateCap),
    deltaNeutralityFeeCap: new Amount(props.deltaNeutralityFeeCap),
    deltaNeutralityFeeFund: new Collateral(props.deltaNeutralityFeeFund),
    deltaNeutralityFeeSensitivity: new Amount(
      props.deltaNeutralityFeeSensitivity,
    ),
    deltaNeutralityFeeTax: new Amount(props.deltaNeutralityFeeTax),
    netNotional: new Notional(props.netNotional),
    crankFee: new Usd(props.crankFee),
    liquifundingDelaySeconds: new Amount(props.liquifundingDelaySeconds),
    unlockedLiquidity: new Collateral(props.unlockedLiquidity),
    carryLeverage: new Amount(props.carryLeverage),
    maxLeverage: new Amount(props.maxLeverage),
    exposureMarginRatio: new Amount(props.exposureMarginRatio),
  }
}

export const calculatePositionStats = (props: CalculatePositionStatsProps) => {
  const {
    leverage,
    tradingFeeNotionalRate,
    counterSideCollateralFeeRate,
    marketPrice,
    deltaNeutralityFeeCap,
    deltaNeutralityFeeFund,
    deltaNeutralityFeeSensitivity,
    deltaNeutralityFeeTax,
    netNotional,
  } = props
  const takeProfitPrice = new TakeProfit(props.takeProfitPrice)

  const positionSize = calculatePositionSize({
    collateral: props.collateral,
    leverage: new Amount(leverage),
    marketPrice,
  })

  const notionalSize = calculateNotionalSize(props)
  const { counterCollateral, minCounterCollateral } =
    calculateCounterCollateral({
      ...props,
      allowNegative: false,
    })

  const { tradingFee, borrowFee } = calculateFees({
    counterSideCollateralFeeRate,
    tradingFeeNotionalRate,
    marketPrice,
    newCounterCollateral: counterCollateral,
    newMinCounterCollateral: minCounterCollateral,
    newNotional: notionalSize,
    oldCounterCollateral: new Collateral(0),
    oldNotional: new Notional(0),
    borrowFee: props.borrowFee,
  })

  const dnfProps = {
    marketPrice,
    deltaNeutralityFeeCap,
    deltaNeutralityFeeFund,
    deltaNeutralityFeeSensitivity,
    deltaNeutralityFeeTax,
    netNotional,
    newNotional: notionalSize,
    oldNotional: new Notional(0),
  }
  const deltaNeutralityFeeAsset = calculateDeltaNeutralityFee(dnfProps)

  const liquidation = calculateLiquidationPrice({
    ...props,
    collateral: props.collateral,
    tradingFee,
    borrowFee: new Usd(props.borrowFee),
    deltaNeutralityFeeAsset,
  })

  const deltaNeutralityTax = calculateDeltaNeutralityTax(dnfProps).tax

  return {
    collateral: props.collateral,
    positionSize,
    takeProfitPrice,
    lockedProfit: counterCollateral,
    liquidation: new Quote(liquidation),
    tradingFee,
    borrowFee,
    deltaNeutralityTax,
    notionalSize,
  }
}

export interface CalculateLiquidationPriceProps {
  direction: DirectionToBase
  collateral: Collateral
  leverage: ConstructableAmount
  takeProfitPrice: ConstructableAmount
  marketPrice: MarketPrice
  borrowFeeRateCap: ConstructableAmount
  fundingFeeRateCap: ConstructableAmount
  deltaNeutralityFeeCap: ConstructableAmount
  crankFee: Usd
  liquifundingDelaySeconds: ConstructableAmount
  unlockedLiquidity: Collateral
  tradingFee: Usd
  borrowFee: Usd
  deltaNeutralityFeeAsset: Usd
  maxLeverage: ConstructableAmount
  exposureMarginRatio: ConstructableAmount
}

export const calculateLiquidationPrice = (
  props: CalculateLiquidationPriceProps,
): Amount => {
  const {
    liquifundingDelaySeconds,
    borrowFeeRateCap,
    marketPrice,
    fundingFeeRateCap,
    deltaNeutralityFeeCap,
    crankFee,
    tradingFee,
    deltaNeutralityFeeAsset,
    exposureMarginRatio,
  } = props

  const collateral = props.collateral

  const notionalSize = calculateNotionalSize(props)
  const { counterCollateral, minCounterCollateral } =
    calculateCounterCollateral({
      ...props,
      allowNegative: false,
    })

  const secondsInAYear = new Amount(365 * 24 * 60 * 60)
  const liquifundingDelayYears = new Amount(liquifundingDelaySeconds).divide(
    secondsInAYear,
  )
  const borrowFeeMargin = new Collateral(
    collateral
      .toAmount()
      .plus(counterCollateral.max(minCounterCollateral))
      .times(borrowFeeRateCap)
      .times(liquifundingDelayYears),
  )

  const maxPrice =
    props.direction === "long"
      ? marketPrice
          .priceNotionalInCollateral()
          .plus(collateral.toAmount().divide(notionalSize.abs()))
      : marketPrice
          .priceNotionalInCollateral()
          .plus(counterCollateral.toAmount().divide(notionalSize.abs()))

  const fundingFeeMargin = new Collateral(
    notionalSize
      .toAmount()
      .abs()
      .times(maxPrice)
      .times(fundingFeeRateCap)
      .times(liquifundingDelayYears),
  )
  const deltaNeutralityFeeMargin = new Collateral(
    notionalSize.toAmount().abs().times(maxPrice).times(deltaNeutralityFeeCap),
  )

  const crankFeeMargin = marketPrice.usdToCollateral(crankFee)

  const exposureMargin = marketPrice.notionalToCollateral(
    new Notional(
      notionalSize.toAmount().abs().times(new Amount(exposureMarginRatio)),
    ),
  )

  const margin = borrowFeeMargin
    .plus(fundingFeeMargin)
    .plus(deltaNeutralityFeeMargin)
    .plus(crankFeeMargin)
    .plus(exposureMargin)

  const feesInCollateral = marketPrice.usdToCollateral(
    tradingFee.plus(deltaNeutralityFeeAsset),
  )

  const liquidationPriceNotional = marketPrice
    .priceNotionalInCollateral()
    .minus(
      collateral
        .toAmount()
        .minus(feesInCollateral)
        .minus(margin)
        .divide(notionalSize),
    )

  if (marketPrice.marketType === "collateral_is_base") {
    return new Amount(1).divide(liquidationPriceNotional)
  } else {
    return new Amount(liquidationPriceNotional)
  }
}

export const useCalculatePositionStatsProps = (
  contextStore: ContextStore<"standard">,
  position:
    | OpenPosition
    | Pick<
        CalculatePositionStatsProps,
        "collateral" | "leverage" | "takeProfitPrice" | "direction"
      >,
  marketConfig: MarketConfig,
  marketPrice: MarketPrice,
) => {
  const marketId = marketConfig.id

  const fullMarketInfo = useStore(contextStore, (state) =>
    state.markets.get(marketId),
  )
  const marketStatus = fullMarketInfo?.status

  if (marketStatus === undefined) {
    throw new Error(`Could not find market status for ${marketId}`)
  }

  const { collateral, leverage, takeProfitPrice, direction } =
    "collateral" in position
      ? position
      : {
          collateral: position.activeCollateral,
          leverage: position.leverage,
          takeProfitPrice: position.takeProfit,
          direction: position.directionToBase,
        }

  const positionStatsProps: CalculatePositionStatsProps = {
    collateral,
    leverage,
    takeProfitPrice,
    direction,
    marketPrice,
    tradingFeeNotionalRate: marketConfig.tradingFeeNotionalSize,
    counterSideCollateralFeeRate: marketConfig.tradingFeeCounterCollateral,
    borrowFee: marketStatus.borrowFee,
    borrowFeeRateCap: marketConfig.borrowFeeRateMaxAnnualized,
    fundingFeeRateCap: marketConfig.fundingRateMaxAnnualized,
    deltaNeutralityFeeCap: marketConfig.deltaNeutralityFeeCap,
    deltaNeutralityFeeFund: marketStatus.deltaNeutralityFeeFund,
    deltaNeutralityFeeSensitivity: marketConfig.deltaNeutralityFeeSensitivity,
    deltaNeutralityFeeTax: marketConfig.deltaNeutralityFeeTax,
    netNotional: marketStatus.netNotional,
    crankFee: new Usd(marketConfig.crankFeeCharged),
    liquifundingDelaySeconds: marketConfig.liquifundingDelaySeconds,
    unlockedLiquidity: marketStatus.liquidity.unlocked,
    carryLeverage: marketConfig.carryLeverage,
    maxLeverage: marketConfig.maxLeverage,
    exposureMarginRatio: marketConfig.exposureMarginRatio,
  }

  return positionStatsProps
}

export const useCalculatePositionStats = (
  positionStatsProps: CalculatePositionStatsProps,
  overrideProps?: Partial<CalculatePositionStatsProps>,
) => {
  return calculatePositionStats(
    Object.assign(positionStatsProps, overrideProps),
  )
}

export interface CalculateCounterCollateralProps {
  direction: DirectionToBase
  collateral: Collateral
  leverage: ConstructableAmount
  takeProfitPrice: ConstructableAmount
  marketPrice: MarketPrice
  maxLeverage: ConstructableAmount
  allowNegative: boolean
}

export interface CalculateMinimumCounterCollateralProps {
  direction: DirectionToBase
  collateral: Collateral
  leverage: ConstructableAmount
  marketPrice: MarketPrice
  maxLeverage: ConstructableAmount
  allowNegative: boolean
}
// Calculate the minimum counter-collateral which can actually be locked up
// if the actual counter-collateral that will be "take-profitted" is less than this,
// then borrow fees are calculated based on this value
export const calculateMinimumCounterCollateral = (
  props: CalculateMinimumCounterCollateralProps,
) => {
  const notionalSize = calculateNotionalSize(props)
  const maxLeverage = new Amount(props.maxLeverage)

  return props.allowNegative
    ? Amount.infiniteLike.negate()
    : props.marketPrice
        .notionalToCollateral(notionalSize.abs())
        .toAmount()
        .divide(maxLeverage)
}

export const calculateCounterCollateral = (
  props: CalculateCounterCollateralProps,
) => {
  const takeProfitPrice = new TakeProfit(props.takeProfitPrice)
  const epsilon = 1e-7
  const notionalSize = calculateNotionalSize(props)

  const minCounterCollateral = calculateMinimumCounterCollateral(props)

  const counterCollateral = (() => {
    if (props.marketPrice.marketType === "collateral_is_quote") {
      return takeProfitPrice
        .toAmount()
        .minus(props.marketPrice.priceNotionalInCollateral())
        .times(notionalSize)
    } else {
      const takeProfitPriceNotional = takeProfitPrice.isLessThan(epsilon)
        ? new Amount(Number.POSITIVE_INFINITY)
        : takeProfitPrice.isInfinite()
          ? new Amount(0)
          : new Amount(1).divide(takeProfitPrice)

      return takeProfitPriceNotional
        .minus(props.marketPrice.priceNotionalInCollateral())
        .times(notionalSize)
    }
  })()

  return {
    counterCollateral: new Collateral(counterCollateral),
    // see calculateMinimumCounterCollateral() for details on this value
    minCounterCollateral: new Collateral(minCounterCollateral),
  }
}

export interface CalculateTakeProfitPriceProps {
  direction: DirectionToBase
  leverage: ConstructableAmount
  maxGains: MaxGainsPercentage
  marketPrice: MarketPrice
}

export const calculateTakeProfitPrice = (
  props: CalculateTakeProfitPriceProps,
) => {
  const direction = new Amount(directionToNumber(props.direction))
  const maxGains = props.maxGains.fromPercentage()
  const takeProfitPriceChange = direction.times(maxGains).divide(props.leverage)

  const takeProfitPrice = (() => {
    if (props.marketPrice.marketType === "collateral_is_quote") {
      return new TakeProfit(
        takeProfitPriceChange
          .plus(1)
          .times(props.marketPrice.priceNotionalInCollateral()),
      )
    } else {
      return new TakeProfit(
        takeProfitPriceChange
          .plus(1)
          .divide(props.marketPrice.priceNotionalInCollateral()),
      )
    }
  })()

  return {
    takeProfitPrice,
    takeProfitPriceChange,
  }
}

export interface CalculateTakeProfitPriceRangeProps {
  direction: DirectionToBase
  leverage: ConstructableAmount
  marketType: MarketType
  marketPrice: MarketPrice
  maxLeverage: ConstructableAmount
  addPadding: boolean
}

export const calculateTakeProfitPriceRange = (
  props: CalculateTakeProfitPriceRangeProps,
) => {
  const maxGainsRange = calculateMaxGainsRange({
    direction: props.direction,
    maxLeverage: props.maxLeverage,
    leverage: props.leverage,
    marketType: props.marketType,
  })

  const takeProfitForMaxMaxGains = calculateTakeProfitPrice({
    direction: props.direction,
    leverage: props.leverage,
    maxGains: maxGainsRange.max,
    marketPrice: props.marketPrice,
  }).takeProfitPrice

  const min = new TakeProfit(props.marketPrice.priceBase)

  return {
    min: props.addPadding
      ? min.times(props.direction === "long" ? 1.001 : 0.999)
      : min,
    max: takeProfitForMaxMaxGains,
  }
}

export interface CalculatePositionSizeProps {
  collateral: Collateral
  leverage: Amount
  marketPrice: MarketPrice
}

export const calculatePositionSize = ({
  collateral,
  leverage,
  marketPrice,
}: CalculatePositionSizeProps): Base => {
  return marketPrice.collateralToBase(
    new Collateral(collateral.toAmount().times(leverage).abs()),
  )
}

export interface CalculateMaxGainsProps {
  activeCollateral: Collateral
  counterCollateral: Collateral
  notionalSize: Notional
  marketPrice: MarketPrice
  marketType: MarketType
}

export const calculateMaxGains = (props: CalculateMaxGainsProps) => {
  const { notionalSize, marketPrice, marketType } = props
  const activeCollateral = props.activeCollateral
  const counterCollateral = props.counterCollateral
  let maxGains: MaxGains

  if (marketType === "collateral_is_quote") {
    maxGains = new MaxGains(
      counterCollateral.toAmount().divide(activeCollateral),
    )
  } else {
    const takeProfitCollateral = activeCollateral.plus(counterCollateral)
    const takeProfitPrice = marketPrice
      .priceNotionalInCollateral()
      .plus(counterCollateral.toAmount().divide(notionalSize))
    const epsilon = 1e-7

    if (takeProfitPrice.isLessThan(epsilon)) {
      return {
        maxGains: new MaxGainsPercentage(Number.POSITIVE_INFINITY),
      }
    }

    const takeProfitInNotional = new Notional(
      takeProfitCollateral.toAmount().divide(takeProfitPrice),
    )
    const activeCollateralInNotional = marketPrice.collateralToNotional(
      new Collateral(activeCollateral),
    )
    maxGains = new MaxGains(
      takeProfitInNotional
        .toAmount()
        .minus(activeCollateralInNotional)
        .divide(activeCollateralInNotional),
    )
  }

  return {
    maxGains: maxGains.toPercentage(),
  }
}

export const calculateMaxGainsFromDependencies = (
  props: CalculateCounterCollateralProps,
) => {
  const notionalSize = calculateNotionalSize(props)
  const { counterCollateral } = calculateCounterCollateral(props)
  return calculateMaxGains({
    activeCollateral: props.collateral,
    counterCollateral,
    marketPrice: props.marketPrice,
    marketType: props.marketPrice.marketType,
    notionalSize,
  })
}

export interface CalculateMaxGainsRangeProps {
  direction: DirectionToBase
  maxLeverage: ConstructableAmount
  leverage: ConstructableAmount
  marketType: MarketType
}

export const calculateMaxGainsRange = (props: CalculateMaxGainsRangeProps) => {
  const { maxLeverage, leverage } = props
  const direction = directionToNumber(props.direction)

  const counterSideRatio = 9 / 10

  // PERP-2971 For some of the fields below, we add a small buffer of 1%
  // to prevent small price deltas from resulting in a too-low countercollateral
  // leverage.
  const buffer = 0.99

  if (props.marketType === "collateral_is_quote") {
    const maxMaxGains =
      props.direction === "short"
        ? new MaxGainsPercentage(
            new Amount(leverage).times(100).floor().times(counterSideRatio),
          )
        : new MaxGainsPercentage(
            new Amount(leverage).times(100).floor().times(buffer),
          )

    return {
      min: new MaxGainsPercentage(
        new Amount(leverage).divide(maxLeverage).times(100).ceil(),
      ),
      max: maxMaxGains,
      end: undefined,
    }
  } else {
    const maxGainsSliderMin = new MaxGainsPercentage(
      new Amount(-1)
        .divide(new Amount(1).minus(new Amount(direction).times(maxLeverage)))
        .times(leverage)
        .times(direction)
        .times(100)
        .ceil(),
    )

    if (props.direction === "long") {
      const maxGainsSliderOneBeforeMax = new MaxGainsPercentage(
        new Amount(-1)
          .divide(
            new Amount(1).minus(new Amount(direction).divide(counterSideRatio)),
          )
          .times(leverage)
          .times(direction)
          .times(100)
          .floor(),
      )

      return {
        min: maxGainsSliderMin,
        max: maxGainsSliderOneBeforeMax,
        end: new Amount(Number.POSITIVE_INFINITY),
      }
    } else {
      const takeProfitPriceChangeMax = -0.5
      const maxGainsSliderMax = new MaxGainsPercentage(
        new Amount(takeProfitPriceChangeMax)
          .times(leverage)
          .times(direction)
          .times(100)
          .floor(),
      )

      return {
        min: maxGainsSliderMin,
        max: maxGainsSliderMax,
        end: undefined,
      }
    }
  }
}

export interface CalculateUpdateLeverageProps {
  direction: DirectionToBase
  counterCollateral: Collateral
  notionalSize: Notional
  leverage: ConstructableAmount
  newLeverage: ConstructableAmount
  marketType: MarketType
}

export const calculateUpdateLeverage = (
  props: CalculateUpdateLeverageProps,
) => {
  const { counterCollateral, leverage, newLeverage, marketType } = props
  const notionalSize = props.notionalSize
  let newNotionalSize: Notional

  if (marketType === "collateral_is_quote") {
    newNotionalSize = new Notional(
      notionalSize.toAmount().times(newLeverage).divide(leverage),
    )
  } else {
    const direction = directionToNumber(props.direction)
    newNotionalSize = new Notional(
      notionalSize
        .toAmount()
        .times(new Amount(newLeverage).times(-1).times(direction).plus(1))
        .divide(new Amount(leverage).times(-1).times(direction).plus(1)),
    )
  }

  const newCounterCollateral = new Collateral(
    counterCollateral.toAmount().times(newNotionalSize).divide(notionalSize),
  )

  return {
    counterCollateral: newCounterCollateral,
    notionalSize: newNotionalSize,
  }
}

export interface CalculateCollateralImpactLeverageProps {
  newCollateral: Collateral
  notionalSize: Notional
  marketPrice: MarketPrice
  direction: DirectionToBase
  marketType: MarketType
}

export const calculateCollateralImpactLeverage = (
  props: CalculateCollateralImpactLeverageProps,
) => {
  const { newCollateral, notionalSize, marketType, direction } = props

  const newLeverageToNotional = props.marketPrice
    .notionalToCollateral(notionalSize)
    .divide(newCollateral)

  const newLeverage =
    marketType === "collateral_is_quote"
      ? newLeverageToNotional.times(directionToNumber(direction))
      : newLeverageToNotional
          .times(-1)
          .plus(1)
          .times(directionToNumber(direction))

  return {
    leverage: newLeverage.abs(),
    leverageSigned: newLeverage,
  }
}

export const calculateDnfCapOutOfBalance = (
  positionStatsProps: CalculatePositionStatsProps,
  oldNotional?: Notional,
) => {
  const netNotional = positionStatsProps.netNotional
  const cap = new Amount(positionStatsProps.deltaNeutralityFeeCap)
  const sensitivity = new Amount(
    positionStatsProps.deltaNeutralityFeeSensitivity,
  )

  const newNotional = calculateNotionalSize(positionStatsProps)
  const deltaNotional = newNotional.minus(oldNotional ?? new Notional(0))

  const notionalLowCap = new Notional(cap.negate().times(sensitivity))
  const notionalHighCap = new Notional(cap.times(sensitivity))
  if (netNotional.isLessThan(notionalLowCap) && deltaNotional.isLessThan(0)) {
    return {
      collateral: new Collateral(0),
    }
  }

  if (
    netNotional.isGreaterThan(notionalHighCap) &&
    deltaNotional.isGreaterThan(0)
  ) {
    return {
      collateral: new Collateral(0),
    }
  }

  return {
    collateral: new Collateral(Amount.infiniteLike),
  }
}

export const calculateMaxNotionalSize = (
  positionStatsProps: CalculatePositionStatsProps,
  oldNotional?: Notional,
) => {
  const netNotional = positionStatsProps.netNotional
  const cap = new Amount(positionStatsProps.deltaNeutralityFeeCap)
  const sensitivity = new Amount(
    positionStatsProps.deltaNeutralityFeeSensitivity,
  )
  const newNotional = calculateNotionalSize(positionStatsProps)
  const deltaNotional = newNotional.minus(oldNotional ?? 0)

  const notionalLowCap = new Notional(cap.negate().times(sensitivity))
  const notionalHighCap = new Notional(cap.times(sensitivity))

  let maxDeltaNotional: Notional
  if (deltaNotional.isLessThan(0)) {
    maxDeltaNotional = notionalLowCap.minus(netNotional)
  } else {
    maxDeltaNotional = notionalHighCap.minus(netNotional)
  }

  return maxDeltaNotional
}

export const calculateDnfCapWithinBalance = (
  positionStatsProps: CalculatePositionStatsProps,
  oldNotional?: Notional,
) => {
  const maxDeltaNotional = calculateMaxNotionalSize(
    positionStatsProps,
    oldNotional,
  )
  const collateral = positionStatsProps.collateral
  const direction = new Amount(directionToNumber(positionStatsProps.direction))

  const newNotional = calculateNotionalSize(positionStatsProps)
  const deltaNotional = newNotional.minus(oldNotional ?? 0)

  if (deltaNotional.isLessThan(0)) {
    if (maxDeltaNotional.isEqualTo(0)) {
      return {
        collateral: new Collateral(0),
        leverage: new Amount(0),
      }
    }
  } else {
    if (maxDeltaNotional.isEqualTo(0)) {
      return {
        collateral: new Collateral(0),
        leverage: new Amount(0),
      }
    }
  }

  const deltasRatio = deltaNotional.divide(maxDeltaNotional)
  const leverageToNotional = positionStatsProps.marketPrice
    .notionalToCollateral(newNotional)
    .divide(collateral)
  const maxLeverageToNotional = leverageToNotional
    .toAmount()
    .divide(deltasRatio)

  const maxLeverage =
    positionStatsProps.marketPrice.marketType === "collateral_is_quote"
      ? maxLeverageToNotional.abs()
      : direction.negate().times(maxLeverageToNotional).plus(direction).abs()

  return {
    collateral: new Collateral(collateral.toAmount().divide(deltasRatio)),
    leverage: maxLeverage,
  }
}

export const noLiquidityInDirection = (
  positionStatsProps: CalculatePositionStatsProps,
) => {
  const carryLeverage = new Amount(positionStatsProps.carryLeverage)
  const netNotional = positionStatsProps.netNotional
  const netNotionalInCollateralAbs =
    positionStatsProps.marketPrice.notionalToCollateral(netNotional.abs())
  const minUnlockedLiquidity = new Collateral(
    netNotionalInCollateralAbs.toAmount().divide(carryLeverage),
  )
  const unlockedLiquidityUntilMin = positionStatsProps.unlockedLiquidity
    .minus(minUnlockedLiquidity)
    .max(0)

  return unlockedLiquidityUntilMin.isLessThanOrEqualTo(0)
}

// calculate the amount of counter-collateral needed to bring net notional to zero
// using carry leverage (not maximum possible counter leverage)
export const calculateUnlockedLiquidity = (
  positionStatsProps: CalculatePositionStatsProps,
  marketType: MarketType,
  maxLeverage: Amount,
  oldNotional?: Notional,
  oldCounterCollateralProp?: Collateral,
) => {
  const collateral = positionStatsProps.collateral
  const direction = new Amount(directionToNumber(positionStatsProps.direction))

  const notionalSize = calculateNotionalSize(positionStatsProps)
  const carryLeverage = new Amount(positionStatsProps.carryLeverage)
  const oldNotionalAmount = oldNotional ?? new Notional(0)
  const deltaNotional = notionalSize.minus(oldNotionalAmount)

  // net notional after position is opened
  const netNotional = positionStatsProps.netNotional.plus(deltaNotional)

  // absolute value of the net notional after position is opened, in collateral
  // in other words - the distance from current net notional to zero (in collateral)
  const netNotionalInCollateralAbs =
    positionStatsProps.marketPrice.notionalToCollateral(netNotional.abs())

  // taking the above value, but converting it to the actual counter-collateral amount needed
  // using carryLeverage (not maximum possible counter leverage)
  // in other words, this is the actual counter-collateral amount needed to balance net-notional to zero
  const minUnlockedLiquidity = new Collateral(
    netNotionalInCollateralAbs.toAmount().divide(carryLeverage),
  )

  // calculate how much liquidity is available to be used for this position
  // i.e. given the actual amount of available liquidity
  // make sure there's enough left over after we deduct the amount needed to balance net-notional to zero
  const unlockedLiquidityUntilMin = positionStatsProps.unlockedLiquidity
    .minus(minUnlockedLiquidity)
    .max(0)

  const oldCounterCollateral = oldCounterCollateralProp ?? new Collateral(0)

  // calculateCounterCollateral assumes that the take profit price is the max gains price
  const newMaxGainsAmount = calculateMaxGainsFromDependencies({
    ...positionStatsProps,
    maxLeverage,
    allowNegative: false,
  }).maxGains.fromPercentage()

  const maxGainsPrice = (() => {
    const minMaxGains = calculateMaxGainsRange({
      ...positionStatsProps,
      maxLeverage,
      marketType,
    }).min.fromPercentage()

    if (newMaxGainsAmount.isGreaterThan(minMaxGains)) {
      return positionStatsProps.takeProfitPrice
    } else {
      return calculateTakeProfitPrice({
        ...positionStatsProps,
        maxGains: minMaxGains.toPercentage(),
      }).takeProfitPrice
    }
  })()

  const { counterCollateral: newCounterCollateral } =
    calculateCounterCollateral({
      ...positionStatsProps,
      takeProfitPrice: maxGainsPrice,
      allowNegative: false,
    })

  // now calculate the min counter-collateral the trader can lock up
  const minCounterCollateral = positionStatsProps.marketPrice
    .notionalToCollateral(notionalSize.abs())
    .divide(new Collateral(maxLeverage))

  // how much new counter collateral your position will be locking
  // (i.e. new target counter collateral minus what was already in the position)
  const counterCollateralDelta =
    newCounterCollateral.minus(oldCounterCollateral)

  if (counterCollateralDelta.isLessThanOrEqualTo(0)) {
    return {
      newCounterCollateral,
      minUnlockedLiquidity,
      collateral: new Collateral(Amount.infiniteLike),
      collateralAtMinCounterCollateral: new Collateral(Amount.infiniteLike),
      leverage: Amount.infiniteLike,
      maxGains: Amount.infiniteLike,
    }
  }

  // just the delta between the old counter collateral and minimum counter collateral the trader can lock up
  const minCounterCollateralDelta =
    minCounterCollateral.minus(oldCounterCollateral)

  // ratios between the delta and the maximum counter collateral available
  const deltasRatio = counterCollateralDelta.divide(unlockedLiquidityUntilMin)

  // TODO - the following may not ultimately be needed
  // we already know by here that the position is valid if deltasRatio is <= 1
  const minDeltasRatio = minCounterCollateralDelta.divide(
    unlockedLiquidityUntilMin,
  )

  const leverageToNotional = positionStatsProps.marketPrice
    .notionalToCollateral(notionalSize)
    .divide(collateral)

  const maxLeverageToNotional = leverageToNotional.divide(deltasRatio)

  const maxLeveragePosition =
    positionStatsProps.marketPrice.marketType === "collateral_is_quote"
      ? maxLeverageToNotional.abs()
      : direction.negate().times(maxLeverageToNotional).plus(direction).abs()

  const maxGainsProps: CalculateMaxGainsProps = {
    activeCollateral: collateral,
    counterCollateral: unlockedLiquidityUntilMin.plus(oldCounterCollateral),
    notionalSize: notionalSize,
    marketPrice: positionStatsProps.marketPrice,
    marketType: marketType,
  }
  const maxMaxGains = calculateMaxGains(maxGainsProps).maxGains

  // if newCounterCollateral (i.e. the amount we want to lock up) plus unlockedLiquidityUntilMin (i.e. the amount we can lock up)
  // is less than positionStatsProps.unlockedLiquidity (the actual available liquidity in the pool), then we can open the position
  // but a more direct comparison is just to compare the returned collateral value to the trader's desired collateral
  return {
    newCounterCollateral,
    minUnlockedLiquidity,
    collateral: new Collateral(collateral.divide(deltasRatio)),
    collateralAtMinCounterCollateral:
      minCounterCollateralDelta.isLessThanOrEqualTo(0)
        ? new Collateral(Amount.infiniteLike)
        : new Collateral(collateral.divide(minDeltasRatio)),
    // maximum valid leverage for your position
    leverage: maxLeveragePosition,
    maxGains: maxMaxGains.isInfinite() ? Amount.infiniteLike : maxMaxGains,
  }
}

interface TakeProfitFromCounterCollateralProps {
  direction: DirectionToBase
  leverage: ConstructableAmount
  marketPrice: MarketPrice
  counterCollateral: Collateral
  collateral: Collateral
}

export const calculateTakeProfitFromCounterCollateral = (
  props: TakeProfitFromCounterCollateralProps,
) => {
  const { direction, leverage, marketPrice, counterCollateral, collateral } =
    props

  const notionalSize = calculateNotionalSize({
    direction,
    collateral,
    leverage,
    marketPrice,
  })

  const takeProfitPrice = new TakeProfit(
    marketPrice
      .priceNotionalInCollateral()
      .plus(counterCollateral.toAmount().divide(notionalSize)),
  )

  const epsilon = 1e-7

  if (takeProfitPrice.isLessThan(epsilon)) {
    if (marketPrice.marketType === "collateral_is_quote") {
      throw new Error("infinite max gains not allowed here")
    } else {
      return new TakeProfit(Number.POSITIVE_INFINITY)
    }
  } else {
    if (marketPrice.marketType === "collateral_is_quote") {
      return takeProfitPrice
    } else {
      return new TakeProfit(new Amount(1).divide(takeProfitPrice))
    }
  }
}
