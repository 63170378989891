import { queryOptions, useQuery } from "@tanstack/react-query"
import { useStore } from "zustand"
import { useAccount } from "@levana/chain/account"

import type CosmosClient from "@perps/sdk/client/CosmosClient"
import { OPEN_POSITIONS_INTERVAL } from "@common/utils/date"
import type { ContextStoreProp } from "@future/context/store"
import type { TargetId } from "@common/target/types"

import type { QueryOptions } from "../types"

export const marketsOpenPositionsQueryOptions = (
  chainClient: CosmosClient,
  walletAddress: string | undefined,
  targetId: TargetId,
) => {
  return queryOptions({
    // TODO: instead of `targetId` use `chainClient` in the queryKey once it doesnt change unnecessarily
    queryKey: ["marketsOpenPositions", walletAddress, targetId],
    refetchInterval: OPEN_POSITIONS_INTERVAL,
    queryFn: async (context) => {
      if (!walletAddress) {
        return []
      }

      return chainClient.queryOpenPositions({
        walletAddress,
        abortSignal: context.signal,
      })
    },
  })
}

export const useMarketsOpenPositionsQuery = (
  props: ContextStoreProp<"standard">,
  options?: QueryOptions,
) => {
  const client = useStore(props.contextStore, (state) => state.chain.client)
  const { data: account } = useAccount()
  const targetId = useStore(props.contextStore, (state) => state.targetInfo.id)
  return useQuery({
    ...marketsOpenPositionsQueryOptions(client, account?.address, targetId),
    ...options,
  })
}
