import Box from "@mui/joy/Box"
import Button from "@mui/joy/Button"
import Stack from "@mui/joy/Stack"
import Tooltip from "@mui/joy/Tooltip"
import Typography from "@mui/joy/Typography"
import IconButton from "@mui/joy/IconButton"
import { ClickAwayListener } from "@mui/base/ClickAwayListener"
import { useTranslation } from "react-i18next"
import NavigationModal, {
  NavigationModalDynamicItem,
} from "@levana-protocol/ui/NavigationModal"
import { ModalPresenter, useModal } from "@levana-protocol/utils/modal"
import { useEffect } from "react"

import NotificationIcon from "@future/header/icons/NotificationIcon"
import { useLazyLoad } from "@future/utils/lazyLoad"
import { useAccountDrawerStore } from "@future/header/AccountDrawer"

import NotifiCard from "./NotifiCard"
import { useNotifiNavItemOnboard } from "./onboard"

const NotifiButton = () => {
  const { present } = useModal()
  const { t } = useTranslation("perpsCommon")
  const onboard = useNotifiNavItemOnboard()
  const NotifiBadge = useLazyLoad(() => import("@future/notifi/NotifiBadge"))

  useEffect(() => {
    if (!onboard.enabled) {
      return
    }

    useAccountDrawerStore.setState({ open: true })
  }, [onboard.enabled])

  return (
    <>
      <ClickAwayListener onClickAway={onboard.next}>
        <Tooltip
          arrow
          open={onboard.enabled}
          placement="bottom-end"
          size="lg"
          variant="soft"
          title={
            <Box
              sx={({ vars }) => ({
                p: 1,
                maxWidth: "356px",
                color: vars.palette.text.primary,
              })}
            >
              <Typography level="body-xs">
                {t("notifi.discovery.header")}
              </Typography>

              <Typography level="body-sm" sx={{ mt: 1 }}>
                {t("notifi.discovery.first")}
              </Typography>
              <Typography level="body-sm">
                <ul>
                  {t("notifi.discovery.second", { returnObjects: true }).map(
                    (text) => (
                      <li key={text}>{text}</li>
                    ),
                  )}
                </ul>
              </Typography>
              <Typography level="body-sm">
                {t("notifi.discovery.third")}
              </Typography>
              <Box
                sx={{
                  gridArea: "buttons",
                  alignSelf: "center",
                  marginTop: "20px",
                }}
              >
                <Stack
                  gap={1}
                  sx={{
                    flexDirection: { md: "row", sm: "column" },
                    justifyContent: { lg: "flex-start", xl: "flex-end" },
                  }}
                >
                  <Button
                    variant="solid"
                    sx={{
                      width: { xs: "100%", sm: "auto" },
                      pl: 5,
                      pr: 5,
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => {
                      onboard.next()
                      present(NotifiCard.name)
                    }}
                    aria-label="open notifi"
                  >
                    {t("notifi.discovery.setUp")}
                  </Button>
                  <Button
                    variant="outlined"
                    color="neutral"
                    sx={{
                      width: { xs: "100%", sm: "auto" },
                      pl: 5,
                      pr: 5,
                      whiteSpace: "nowrap",
                    }}
                    onClick={onboard.next}
                    aria-label="dismiss notifi"
                  >
                    {t("notifi.discovery.dismiss")}
                  </Button>
                </Stack>
              </Box>
            </Box>
          }
        >
          <Box ref={onboard.ref}>
            <IconButton
              variant="outlined"
              size="md"
              onClick={() => present(NotifiCard.name)}
            >
              {NotifiBadge && (
                <NotifiBadge>
                  <NotificationIcon size="sm" />
                </NotifiBadge>
              )}
            </IconButton>
          </Box>
        </Tooltip>
      </ClickAwayListener>

      <ModalPresenter queueKey={NotifiCard.name}>
        <NavigationModal
          rootId={NotifiCard.name}
          slotProps={{
            dialog: {
              slotProps: {
                root: {
                  sx: {
                    "--NavigationModal-contentTop": "0px",
                    "--ModalDialog-maxWidth": "unset",
                    "--ModalDialog-minWidth": "unset",
                    "--ModalContent-padding": "unset",
                  },
                },
                header: {
                  sx: {
                    display: "none",
                  },
                },
              },
            },
          }}
        >
          <NavigationModalDynamicItem id={NotifiCard.name} canClose>
            <NotifiCard />
          </NavigationModalDynamicItem>
        </NavigationModal>
      </ModalPresenter>
    </>
  )
}

export default NotifiButton
