import { useTranslation } from "react-i18next"
import List from "@mui/joy/List"
import ListDivider from "@mui/joy/ListDivider"
import ListItem from "@mui/joy/ListItem"
import Radio from "@mui/joy/Radio"
import Stack from "@mui/joy/Stack"
import { useModal } from "@levana-protocol/utils/modal"
import NavigationModal, {
  NavigationModalDynamicItem,
} from "@levana-protocol/ui/NavigationModal"
import { useEffect, useRef } from "react"
import { useStore } from "zustand"
import { useSearchParams } from "react-router-dom"

import NetworkIcon from "@common/icons/NetworkIcon"
import type { ContextStoreProp } from "@future/context/store"
import { useChooseChainNavItems } from "@future/chain/chooseChainNavItems"
import NavActionButton from "@future/header/NavActionButton"

interface ChooseChainModalProps extends ContextStoreProp<"initial"> {
  isOpen: boolean
  close: () => void
}

const ChooseChainModal = (props: ChooseChainModalProps) => {
  const { t } = useTranslation("common")

  const navItems = useChooseChainNavItems(props)
  const targetSlug = useStore(
    props.contextStore,
    (state) => state.targetInfo.slug,
  )

  return (
    <NavigationModal rootId={ChooseChainModal.name}>
      <NavigationModalDynamicItem
        id={ChooseChainModal.name}
        title={t("menu.general.network")}
        icon={<NetworkIcon sx={{ color: "text.secondary" }} />}
        canClose
      >
        <Stack component={List} divider={<ListDivider inset="gutter" />}>
          {navItems.map((navItem) => (
            <ListItem
              key={navItem.id}
              slotProps={{
                root: { sx: { backgroundColor: "transparent" } },
                endAction: { sx: { pointerEvents: "none" } },
              }}
              endAction={
                <Radio
                  size="lg"
                  checked={targetSlug === navItem.id}
                  sx={{ pointerEvents: "none" }}
                  slotProps={{
                    radio: {
                      sx: ({ vars }) => ({
                        borderColor: vars.palette.primary[500],
                        color: vars.palette.primary[500],
                        backgroundColor: "transparent",
                      }),
                    },
                  }}
                />
              }
            >
              <NavActionButton navItem={navItem} />
            </ListItem>
          ))}
        </Stack>
      </NavigationModalDynamicItem>
    </NavigationModal>
  )
}

export const AutoShowChooseChainModal = (
  props: ContextStoreProp<"initial">,
) => {
  const propsRef = useRef(props)
  const { present, dismiss } = useModal()
  const [searchParams] = useSearchParams()
  const autoShowNetworkModal = useRef(
    searchParams.get("networkSelect") !== null,
  )

  useEffect(() => {
    if (autoShowNetworkModal.current) {
      present(
        <ChooseChainModal
          isOpen
          close={dismiss}
          contextStore={propsRef.current.contextStore}
        />,
        { key: "showNetwork" },
      )
    }
  }, [present, dismiss])

  return null
}

export default ChooseChainModal
