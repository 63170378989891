import type { SvgIconProps } from "@mui/joy/SvgIcon"
import type { ListItemButtonProps } from "@mui/joy/ListItemButton"
import { useModal } from "@levana-protocol/utils/modal"
import { lazyLoad } from "@levana-protocol/utils/lazyLoad"
import type { PropsWithChildren } from "react"
import { useStore } from "zustand"
import type { ConnectedAgent } from "@levana/chain/account"
import { useConnectedWallet } from "@levana/chain/wallet"
import { useTranslation } from "react-i18next"

import {
  type ContextStore,
  isStandardContextState,
  isStandardContextStore,
  type ContextStoreProp,
} from "@future/context/store"
import type { PerpsRoute } from "@future/router/Router"
import ReferralModal from "@future/referral/ReferralModal"
import GetLvnModal from "@future/lvn/GetLvnModal"
import {
  EarnNavItemOnboard,
  DiscordNavItemOnboard,
} from "@future/pages/tradeMarket/onboard"
import { govUrl } from "@future/target/domain"
import { Report } from "@future/libs/error/report"
import { track } from "@perps/analytics/track"
import { Event } from "@perps/analytics/events"
import MarketsIcon from "@future/header/icons/MarketsIcon"
import TradeIcon from "@future/header/icons/TradeIcon"
import EarnIcon from "@future/header/icons/EarnIcon"
import ReferralIcon from "@future/header/icons/ReferralIcon"
import HistoryIcon from "@future/header/icons/HistoryIcon"
import StatsIcon from "@future/header/icons/StatsIcon"
import MoreIcon from "@future/header/icons/MenuIcon" // TODO:
import GetLvnIcon from "@future/header/icons/GetLvnIcon"
import BridgeIcon from "@future/header/icons/BridgeIcon"
import LearnIcon from "@future/header/icons/LearnIcon"
import ValidateIcon from "@future/header/icons/ValidateIcon"
import DiscordIcon from "@future/header/icons/DiscordIcon"
import CrankIcon from "@future/header/icons/CrankIcon"
import SettingsIcon from "@future/header/icons/SettingsIcon"
import DaoIcon from "@future/header/icons/DaoIcon"
import PredictIcon from "@future/header/icons/PredictIcon"
import { useCrankCallback } from "@future/crank/hooks"
import type { MarketConfig } from "@future/market/config/types"
import { tryCanTapFaucet } from "@future/faucet/utils"
import HeaderFaucetModal from "@perps/header/HeaderFaucetModal"
import { RouteRootId } from "@future/router/types"
import { useBridgeSelector } from "@future/bridge/useBridgeSelector"
import { openedBridgeWindow } from "@future/bridge/BridgeContent"
import BridgeModal from "@future/bridge/BridgeModal"

export interface NavItem {
  title: string
  icon: React.JSXElementConstructor<SvgIconProps>
  onboardWrapper?: (props: PropsWithChildren) => JSX.Element
  color?: ListItemButtonProps["color"]
  id?: string
}

export interface ParentNavItem extends NavItem {
  type: "parent"
  subitems: ActionNavItem[]
}

export interface ActionNavItem extends NavItem {
  type: "action"
  action:
    | { type: "router"; router: PerpsRoute }
    | { type: "href"; href: string }
    | { type: "handler"; handler: () => void }
}

export const moreNavItemId = "more"

export const useMainNavItems = (
  props: ContextStoreProp<"initial">,
): (ParentNavItem | ActionNavItem)[] => {
  const { contextStore } = props
  const factory = useNavItemFactory(props)
  const { data: connectedWallet } = useConnectedWallet()
  const chainNetworkId = useStore(contextStore, (state) => {
    if (isStandardContextState(state)) {
      return state.chain.config.chainNetworkId
    }
  })
  const chainName = useStore(contextStore, (state) => {
    if (isStandardContextState(state)) {
      return state.chain.config.chainName
    }
  })

  const items = [
    factory.markets([factory.trade(), factory.earn(), factory.referral()]),
    factory.history(),
    factory.stats(),
  ]

  switch (process.env.BRAND_ID) {
    case "levana":
      items.push(
        factory.more(
          (() => {
            const items: ActionNavItem[] = []

            if (isStandardContextStore(contextStore)) {
              items.push(factory.lvn(contextStore))
            }

            if (
              isStandardContextStore(contextStore) &&
              (chainNetworkId === "osmosis-mainnet" ||
                chainNetworkId === "injective-mainnet")
            ) {
              items.push(factory.bridge())
            }

            items.push(factory.learn())

            if (chainName === "osmosis") {
              items.push(factory.validate())
            }

            items.push(factory.discord())

            return items
          })(),
        ),
      )
      break

    case "rujira":
      if (isStandardContextStore(contextStore)) {
        items.push(factory.settings(contextStore))
      }
      if (
        isStandardContextStore(contextStore) &&
        connectedWallet &&
        contextStore.getState().chain.config.network === "testnet"
      ) {
        items.push(factory.faucet(contextStore, connectedWallet))
      }
      break
  }

  return items
}

export const useLevanaAppsNavItems = (
  props: ContextStoreProp<"initial">,
): ActionNavItem[] => {
  const factory = useNavItemFactory(props)

  return [factory.dao(), factory.predict()]
}

export const useNavItemFactory = (props: ContextStoreProp<"initial">) => {
  const { t } = useTranslation("perpsCommon")
  const { present } = useModal()
  const doCrank = useCrankCallback()

  const targetSlug = useStore(
    props.contextStore,
    (state) => state.route.targetSlug,
  )
  const marketConfig = useStore(props.contextStore, (state) => {
    if (isStandardContextState(state) && state.isMarketRoute()) {
      return state.fullMarketInfo.config
    }
  })
  const bridgeSelector = useBridgeSelector({ ...props, marketConfig })

  return {
    markets: createParentNavItem({
      title: t("navItem.markets"),
      icon: MarketsIcon,
      id: RouteRootId.markets,
    }),
    trade: createActionNavItem({
      title: t("navItem.trade"),
      icon: TradeIcon,
      id: RouteRootId.trade,
      action: {
        type: "router",
        router: { route: RouteRootId.trade, targetSlug },
      },
    }),
    earn: createActionNavItem({
      title: t("navItem.earn"),
      icon: EarnIcon,
      id: RouteRootId.earn,
      onboardWrapper: EarnNavItemOnboard,
      action: {
        type: "router",
        router: { route: RouteRootId.earn, targetSlug },
      },
    }),
    referral: createActionNavItem({
      title: t("navItem.referral"),
      icon: ReferralIcon,
      action: {
        type: "handler",
        handler: () => present(ReferralModal.name),
      },
    }),
    history: createActionNavItem({
      title: t("navItem.history"),
      icon: HistoryIcon,
      id: RouteRootId.history,
      action: {
        type: "router",
        router: { route: RouteRootId.history, targetSlug },
      },
    }),
    stats: createActionNavItem({
      title: t("navItem.stats"),
      icon: StatsIcon,
      id: RouteRootId.stats,
      action: {
        type: "router",
        router: { route: RouteRootId.stats },
      },
    }),
    more: createParentNavItem({
      title: t("navItem.more"),
      icon: MoreIcon,
      id: moreNavItemId,
    }),
    lvn: (contextStore: ContextStore<"standard">): ActionNavItem => ({
      title: t("navItem.lvn"),
      icon: GetLvnIcon,
      type: "action",
      action: {
        type: "handler",
        handler: () => {
          present(<GetLvnModal contextStore={contextStore} />)
        },
      },
    }),
    bridge: createActionNavItem({
      title: t("navItem.bridge"),
      icon: BridgeIcon,
      action: {
        type: "handler",
        handler: () => {
          if (!bridgeSelector) {
            if (isStandardContextStore(props.contextStore)) {
              const { chainNetworkId } =
                props.contextStore.getState().chain.config
              Report.message(`Missing bridge for '${chainNetworkId}'`)
            }
            return
          }

          if (!openedBridgeWindow(bridgeSelector)) {
            present(
              <BridgeModal
                bridge={bridgeSelector}
                marketId={marketConfig?.id}
              />,
            )
          }
        },
      },
    }),
    learn: createActionNavItem({
      title: t("navItem.learn"),
      icon: LearnIcon,
      action: {
        type: "href",
        href: "https://levanaexchange.zendesk.com/",
      },
    }),
    validate: createActionNavItem({
      title: t("navItem.validate"),
      icon: ValidateIcon,
      action: {
        type: "href",
        href: "https://wallet.keplr.app/chains/osmosis?modal=validator&chain=osmosis-1&validator_address=osmovaloper1k5mws9tqgflx6rvhq6x0tnsdw0ddfkptwf3ch9&referral=true",
      },
    }),
    discord: createActionNavItem({
      title: t("navItem.discord"),
      icon: DiscordIcon,
      onboardWrapper: DiscordNavItemOnboard,
      color: "success",
      action: {
        type: "href",
        href: "https://discord.com/invite/Xa7yzUtApz",
      },
    }),
    crank: (
      contextStore: ContextStore<"standard">,
      marketConfig: MarketConfig,
      connectedWallet: ConnectedAgent,
    ): ActionNavItem => ({
      title: t("navItem.crank"),
      icon: CrankIcon,
      type: "action",
      action: {
        type: "handler",
        handler: async () => {
          await doCrank({
            contextStore,
            marketConfig,
            walletAddress: connectedWallet.account.address,
            signAndBroadcast: connectedWallet.signAndBroadcast,
          })
        },
      },
    }),
    faucet: (
      contextStore: ContextStore<"standard">,
      connectedWallet: ConnectedAgent,
    ): ActionNavItem => ({
      title: t("navItem.faucet"),
      icon: CrankIcon,
      type: "action",
      action: {
        type: "handler",
        handler: async () => {
          const storeState = contextStore.getState()
          track(Event.initiateTapFaucet())

          // If we encounter any errors when checking, fall back to just
          // opening up the faucet captcha. The user will end up getting an
          // error message later if they aren't eligible.
          const errorMessage = await (async () => {
            try {
              const ineligibleReason = await tryCanTapFaucet({
                contractClient: storeState.chain.client,
                faucetAddress: storeState.chain.faucet,
                walletAddress: connectedWallet.account.address,
                cw20s: storeState.chain.cw20s,
              })

              return ineligibleReason
            } catch (error) {
              console.error(error)
              return undefined
            }
          })()

          present(
            <HeaderFaucetModal
              errorMessage={errorMessage}
              contextStore={contextStore}
            />,
          )
        },
      },
    }),
    settings: (contextStore: ContextStore<"standard">): ActionNavItem => ({
      title: t("navItem.settings"),
      icon: SettingsIcon,
      type: "action",
      action: {
        type: "handler",
        handler: async () => {
          track(Event.viewSettings())

          const SettingsModal = await lazyLoad(
            () => import("@perps/settings/SettingsModal"),
          )

          present(<SettingsModal contextStore={contextStore} />)
        },
      },
    }),
    dao: createActionNavItem({
      title: t("navItem.dao"),
      icon: DaoIcon,
      action: {
        type: "href",
        href: govUrl,
      },
    }),
    predict: createActionNavItem({
      title: t("navItem.predict"),
      icon: PredictIcon,
      action: {
        type: "href",
        href: "https://predict.levana.finance",
      },
    }),
  }
}

const createParentNavItem =
  (
    props: Omit<NavItem, "purpose" | "id"> & {
      id?: RouteRootId | string
    },
  ) =>
  (subitems: ActionNavItem[]): ParentNavItem => {
    return {
      ...props,
      type: "parent",
      subitems,
    }
  }

const createActionNavItem =
  (
    props: Omit<NavItem, "purpose" | "id"> & {
      action: ActionNavItem["action"]
      id?: RouteRootId | string
    },
  ) =>
  (): ActionNavItem => {
    const { action, ...otherProps } = props
    return {
      ...otherProps,
      type: "action",
      action,
    }
  }
