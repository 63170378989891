import { useStore } from "zustand"

import type { ContextStoreProp } from "@future/context/store"
import { useClosedPositionsStore } from "@future/pages/tradeMarket/openPositions/useClosedPositionsStore"
import { usePositionActionHistoryQueries } from "@future/network/positionActionHistory"
import { formatOpenPosition } from "@future/network/marketsOpenPositions/format"
import type { OpenPosition } from "@future/network/marketsOpenPositions/types"
import { useMarketsOpenPositionsQuery } from "@future/network/marketsOpenPositions/query"

export const useOpenPositions = (props: ContextStoreProp<"standard">) => {
  const marketsOpenPositionsQuery = useMarketsOpenPositionsQuery(props)
  const marketPositions = marketsOpenPositionsQuery.data ?? []

  const positionActionHistoryResults = usePositionActionHistoryQueries({
    contextStore: props.contextStore,
    positions: marketPositions.map((marketPosition) => ({
      id: marketPosition.position.id,
      marketId: marketPosition.marketId,
    })),
  })

  const markets = useStore(props.contextStore, (state) => state.markets)
  const isPositionClosed = useClosedPositionsStore((state) => state.has)

  if (marketsOpenPositionsQuery.isPending) {
    return
  }

  const openPositions: OpenPosition[] = []

  for (let i = 0; i < marketPositions.length; i += 1) {
    const marketPosition = marketPositions[i]
    const market = markets.get(marketPosition.marketId)
    const initialLeverage = positionActionHistoryResults
      .at(i)
      ?.data?.at(0)?.leverage

    if (!market) {
      console.warn(
        `Market not found when getting open positions: ${marketPosition.marketId}`,
      )
      continue
    }

    if (isPositionClosed(market.config.id, marketPosition.position.id)) {
      continue
    }

    openPositions.push(
      formatOpenPosition(marketPosition, market, initialLeverage),
    )
  }

  return openPositions
}
