var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from "@tanstack/react-query";
import { fromBech32 } from "@cosmjs/encoding";
import { useMemo } from "react";
import { cosmosConnect, cosmosDisconnect } from "../cosmos/account";
import { WalletType } from "../shared/wallet";
import { useChainStore, useAccountStore } from "../shared/store";
import { injectiveConnect, injectiveDisconnect } from "../injective/account";
import { ConnectedAgentType, } from "../shared/account";
import { useDecideChainAction } from "./chain";
import { ErrorMessage } from "./error";
export { ConnectedAgentType, isCosmosAccount, isEthereumAccount, } from "../shared/account";
export const getAccount = () => {
    const { chainId } = useChainStore.getState();
    return useAccountStore.getState().activeAccount(chainId);
};
export const useAccount = () => {
    const chainId = useChainStore((state) => state.chainId);
    const account = useAccountStore((state) => state.activeAccount(chainId));
    return useMemo(() => {
        if ((account === null || account === void 0 ? void 0 : account.chainId) !== chainId) {
            return {
                data: undefined,
            };
        }
        return {
            data: account,
        };
    }, [account, chainId]);
};
export const useMainAccount = () => {
    const chainId = useChainStore((state) => state.chainId);
    return useAccountStore((state) => state.mainAccount(chainId));
};
export const useViewingAccounts = () => {
    const chainId = useChainStore((state) => state.chainId);
    const accounts = useAccountStore((state) => {
        return state.accounts.filter((account) => {
            return account.walletType === "viewing" && account.chainId === chainId;
        });
    });
    return accounts;
};
export const viewingConnectedAgent = (account) => {
    return {
        type: ConnectedAgentType.generic,
        account,
        signAndBroadcast: () => {
            throw new Error(ErrorMessage.viewingExecute);
        },
    };
};
export const useConnect = (args = {}) => {
    const { onError, onLoading, onSuccess } = args;
    const connect = useDecideChainAction({
        cosmos: cosmosConnect,
        injective: injectiveConnect,
    });
    const mutation = useMutation({
        mutationKey: ["useConnect", onError, onLoading, onSuccess],
        mutationFn: (strategy) => __awaiter(void 0, void 0, void 0, function* () {
            useAccountStore.setState({ status: "connecting" });
            if (strategy.walletType === WalletType.viewing) {
                return viewingConnectedAgent({
                    walletType: strategy.walletType,
                    address: strategy.address,
                    chainId: useChainStore.getState().chainId,
                });
            }
            return connect(strategy);
        }),
        onMutate: onLoading,
        onError: (error, account) => {
            onError === null || onError === void 0 ? void 0 : onError(error, account);
            useAccountStore.setState({
                status: "disconnected",
                connectIssue: {
                    error,
                    walletType: account.walletType,
                },
            });
        },
        onSuccess: (connectedAgent) => {
            useAccountStore.getState().addAccount(connectedAgent.account);
            useAccountStore.setState({ status: "connected" });
            return onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(connectedAgent);
        },
    });
    return useMemo(() => ({
        connect: (strategy) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                return yield mutation.mutateAsync(strategy);
            }
            catch (_a) {
                return;
            }
        }),
        error: mutation.error,
        isLoading: mutation.isPending,
        isSuccess: mutation.isSuccess,
        status: mutation.status,
    }), [
        mutation.mutateAsync,
        mutation.error,
        mutation.isPending,
        mutation.isSuccess,
        mutation.status,
    ]);
};
export const useDisconnect = (args = {}) => {
    const { onError, onLoading, onSuccess } = args;
    const mutation = useMutation({
        mutationKey: ["useDisconnect", onError, onLoading, onSuccess],
        mutationFn: (address) => __awaiter(void 0, void 0, void 0, function* () {
            if (address) {
                useAccountStore.getState().removeAccount(address);
            }
            else {
                useAccountStore.setState({
                    status: "disconnected",
                    accounts: [],
                    connectIssue: undefined,
                });
                yield Promise.all([injectiveDisconnect(), cosmosDisconnect()]);
            }
            return;
        }),
        onMutate: onLoading,
        onError: (error) => onError === null || onError === void 0 ? void 0 : onError(error, undefined),
        onSuccess: () => onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(undefined),
    });
    return useMemo(() => ({
        disconnect: (address) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                return yield mutation.mutateAsync(address);
            }
            catch (_a) {
                return;
            }
        }),
        error: mutation.error,
        isLoading: mutation.isPending,
        isSuccess: mutation.isSuccess,
        status: mutation.status,
    }), [
        mutation.mutateAsync,
        mutation.error,
        mutation.isPending,
        mutation.isSuccess,
        mutation.status,
    ]);
};
const getAddressPrefix = (address) => {
    try {
        const decoded = fromBech32(address);
        return { type: "cosmos", prefix: decoded.prefix };
    }
    catch (_a) {
        if (address.startsWith("0x")) {
            return { type: "ethereum", prefix: "0x" };
        }
    }
};
export const truncateAddress = (address, startIndex, endIndex) => {
    var _a, _b;
    const lowercaseAddress = address.toLowerCase();
    const addressPrefix = (_b = (_a = getAddressPrefix(lowercaseAddress)) === null || _a === void 0 ? void 0 : _a.prefix) !== null && _b !== void 0 ? _b : "";
    const defaultIndexes = (() => {
        var _a;
        switch ((_a = getAddressPrefix(lowercaseAddress)) === null || _a === void 0 ? void 0 : _a.type) {
            case "cosmos":
                return { start: 2, end: 4 };
            case "ethereum":
                return { start: 4, end: 5 };
            case undefined:
                return { start: 4, end: 4 };
        }
    })();
    const indexes = {
        start: startIndex !== null && startIndex !== void 0 ? startIndex : defaultIndexes.start,
        end: endIndex !== null && endIndex !== void 0 ? endIndex : defaultIndexes.end,
    };
    if (addressPrefix.length + indexes.start + indexes.end >= address.length) {
        return address;
    }
    const start = address.slice(0, addressPrefix.length + indexes.start);
    const end = address.slice(-indexes.end, address.length);
    return `${start}...${end}`;
};
