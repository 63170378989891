import { QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import ThemeProvider from "@levana-protocol/ui/ThemeProvider"

import RootSetup from "@perps/root/RootSetup"
import ConsoleDrawer from "@future/libs/debug/ConsoleDrawer"
import { queryClient } from "@future/network/client"
import NotificationLayout from "@future/notification/NotificationLayout"
import ChainContainer from "@future/chain/ChainContainer"
import SetupContext from "@future/context/SetupContext"

import { BrowserConsole } from "../libs/debug/BrowserConsole"

const RootLayout = (props: Parameters<typeof SetupContext>[0]) => {
  return (
    <ThemeProvider brandId="levana">
      {/* TODO: handle variant condition */}
      <RootSetup />
      <NotificationLayout />
      <QueryClientProvider client={queryClient}>
        <BrowserConsole />
        <ConsoleDrawer />
        <ChainContainer>
          <SetupContext {...props} />
        </ChainContainer>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeProvider>
  )
}

export default RootLayout
