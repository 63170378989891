import Typography from "@mui/joy/Typography"
import Box from "@mui/joy/Box"
import Stack from "@mui/joy/Stack"
import { useTranslation } from "react-i18next"
import { useConnectedWallet } from "@levana/chain/wallet"

import { AppError, type AppErrorOptions } from "./AppError"

export interface ErrorContentProps {
  error: unknown
  showStack?: boolean
}

const ErrorContent = (props: ErrorContentProps) => {
  const { error } = props
  const { t } = useTranslation("error")
  const errorAdjuster = useErrorAdjuster()

  if (error instanceof AppError) {
    const rootCause =
      typeof error.rootError.cause === "string"
        ? error.rootError.cause
        : undefined

    return (
      <Stack spacing={1}>
        <Box>
          {error.toContexts(t).map((message, index) => (
            <Typography
              key={`${message}-${index}`}
              level="body-md"
              textColor={
                isCauseMessage(error, message)
                  ? "text.primary"
                  : "text.secondary"
              }
              sx={{ mt: index > 0 ? 0.5 : 0, overflowWrap: "anywhere" }}
            >
              {message}
            </Typography>
          ))}
        </Box>

        {rootCause && (
          <Typography
            level="body-xs"
            textColor="text.tertiary"
            sx={{ overflowWrap: "anywhere" }}
          >
            {rootCause}
          </Typography>
        )}

        <Stack
          direction="row"
          sx={{ flexWrap: "wrap", columnGap: 2, rowGap: 1 }}
        >
          {errorAdjuster.additionalData(error.extra).map(([key, value]) => (
            <Box key={key}>
              <Typography level="body-sm" textColor="text.secondary">
                {key}
              </Typography>
              <Typography
                level="body-sm"
                textColor="text.primary"
                sx={{ overflowWrap: "anywhere" }}
              >
                {value}
              </Typography>
            </Box>
          ))}
        </Stack>
        {props.showStack && (
          <Typography
            level="body-xs"
            sx={{
              whiteSpace: "pre-wrap",
              maxWidth: "100vw",
              overflowX: "auto",
            }}
          >
            {error.stack}
          </Typography>
        )}
      </Stack>
    )
  } else if (error instanceof Error) {
    return (
      <Stack spacing={1}>
        <Typography
          level="body-md"
          textColor="text.primary"
          sx={{ mt: 0.5, overflowWrap: "anywhere" }}
        >
          {error.message}
        </Typography>
        <Typography level="body-sm" sx={{ whiteSpace: "pre-wrap" }}>
          {JSON.stringify(error, null, 2)}
        </Typography>
        {props.showStack && (
          <Typography
            level="body-xs"
            sx={{
              whiteSpace: "pre-wrap",
              maxWidth: "100vw",
              overflowX: "auto",
            }}
          >
            {error.stack}
          </Typography>
        )}
      </Stack>
    )
  } else {
    return null
  }
}

const isCauseMessage = (error: AppError, message: string) => {
  if (error.rootError.cause instanceof Error) {
    return error.rootError.cause.message === message
  }
  return false
}

const useErrorAdjuster = () => {
  const { data: connectedWallet } = useConnectedWallet()

  const additionalData = (extra: NonNullable<AppErrorOptions["extra"]>) => {
    const wallet = connectedWallet
      ? {
          address: connectedWallet.account.address,
          type: connectedWallet.account.walletType,
        }
      : undefined

    return Object.entries(
      flattenObject({
        ...(wallet ? { wallet } : {}),
        timestamp: Date.now(),
        ...extra,
      }),
    )
  }

  return { additionalData }
}

const flattenObject = (obj: object, prefix = "") => {
  const flattened: Record<string, string> = {}

  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === "object" && value !== null) {
      Object.assign(flattened, flattenObject(value, `${prefix}${key}.`))
    } else {
      flattened[`${prefix}${key}`] = value
    }
  }

  return flattened
}

export default ErrorContent
