import List from "@mui/joy/List"
import ListItem from "@mui/joy/ListItem"
import ListItemContent from "@mui/joy/ListItemContent"
import Stack from "@mui/joy/Stack"
import Typography, { type TypographyProps } from "@mui/joy/Typography"
import type { ThemeVars } from "@mui/joy/styles"
import { useEffect, useState } from "react"
import { useThemeMode, type ThemeMode } from "@levana-protocol/ui/useThemeMode"

import type { JSONValue } from "@common/json/types"

import { useDebugStore } from "./store"
import type { ConsoleLog } from "./console"

const ConsoleView = () => {
  const themeMode = useThemeMode()
  const consoleLogs = useDebugStore((state) => state.consoleLogs)

  return (
    <List>
      {consoleLogs.map(({ level, items }, index) => (
        <ListItem
          key={index}
          variant="outlined"
          sx={({ vars }) => ({
            borderColor: levelBorderColor(themeMode, vars, level),
            backgroundColor: levelBackgroundColor(themeMode, vars, level),
          })}
        >
          <ListItemContent>
            <Stack spacing={1.5} direction="row" sx={{ flexWrap: "wrap" }}>
              {items.reduce((accumulator, item, index) => {
                const consoleItem = createConsoleItem(items, item, index)

                switch (consoleItem.type) {
                  case "styledString":
                    accumulator.push(
                      <ConsoleTypography
                        key={index}
                        slotProps={{
                          root: {
                            ref: (element) => {
                              element?.setAttribute("style", consoleItem.style)
                            },
                          },
                        }}
                      >
                        {consoleItem.item}
                      </ConsoleTypography>,
                    )
                    break
                  case "string":
                    accumulator.push(
                      <ConsoleTypography key={index} textColor="text.primary">
                        {consoleItem.item}
                      </ConsoleTypography>,
                    )
                    break
                  case "number":
                    accumulator.push(
                      <ConsoleTypography key={index} sx={{ color: "#9980FF" }}>
                        {consoleItem.item}
                      </ConsoleTypography>,
                    )
                    break
                  case "object":
                    accumulator.push(
                      <ConsoleTypography
                        key={index}
                        component="code"
                        textColor="text.primary"
                      >
                        {consoleItem.item}
                      </ConsoleTypography>,
                    )
                    break
                  case "ignore":
                    break
                }

                return accumulator
              }, [] as JSX.Element[])}
            </Stack>
          </ListItemContent>
        </ListItem>
      ))}
    </List>
  )
}

const ConsoleTypography = ({ sx, ...props }: TypographyProps) => {
  return (
    <Typography
      level="body-sm"
      sx={{ whiteSpace: "pre-wrap", wordBreak: "break-word", ...sx }}
      {...props}
    />
  )
}

type ConsoleItem =
  | {
      type: "styledString"
      style: string
      item: string
    }
  | {
      type: "string"
      item: string
    }
  | {
      type: "number"
      item: number
    }
  | {
      type: "object"
      item: string
    }
  | {
      type: "ignore"
    }

const createConsoleItem = (
  items: JSONValue[],
  item: JSONValue,
  index: number,
): ConsoleItem => {
  if (typeof item === "string") {
    const colorCode = "%c"

    if (item.startsWith(colorCode)) {
      const codelessItem = item.substring(colorCode.length)
      const nextItem = items.at(index + 1)

      if (typeof nextItem === "string") {
        return {
          type: "styledString",
          style: nextItem,
          item: codelessItem,
        }
      } else {
        return {
          type: "string",
          item: codelessItem,
        }
      }
    } else if (item.startsWith("color: ") && item.endsWith(";")) {
      return {
        type: "ignore",
      }
    } else {
      try {
        const parsedItem = JSON.parse(item)

        return {
          type: "object",
          item: JSON.stringify(parsedItem, null, 2),
        }
      } catch {
        return {
          type: "string",
          item,
        }
      }
    }
  } else if (typeof item === "number") {
    return {
      type: "number",
      item,
    }
  } else {
    return {
      type: "object",
      item: JSON.stringify(item, null, 2),
    }
  }
}

const levelBorderColor = (
  themeMode: ThemeMode,
  vars: ThemeVars,
  level: ConsoleLog["level"],
): string => {
  const weight = themeMode === "dark" ? 600 : 300

  switch (level) {
    case "log":
    case "debug":
      return vars.palette.neutral[weight]
    case "warn":
      return vars.palette.warning[weight]
    case "error":
      return vars.palette.danger[weight]
    case "info":
      return vars.palette.primary[weight]
  }
}

const levelBackgroundColor = (
  themeMode: ThemeMode,
  vars: ThemeVars,
  level: ConsoleLog["level"],
): string => {
  const weight = themeMode === "dark" ? 900 : 100

  switch (level) {
    case "log":
    case "debug":
      return vars.palette.neutral[weight]
    case "warn":
      return vars.palette.warning[weight]
    case "error":
      return vars.palette.danger[weight]
    case "info":
      return vars.palette.primary[weight]
  }
}

/**
 * The `consoleLogs` inside of `ConsoleView` is set early on in the application
 * lifecycle from outside of React. This can cause a rendering error. This gate
 * will delay the mounting until the component is needed which avoids the error.
 */
const ConsoleViewMountingGate = () => {
  const consoleVisible = useDebugStore((state) => state.consoleVisible)
  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (consoleVisible) {
      setReady(true)
    }
  }, [consoleVisible])

  if (!ready) {
    return null
  }

  return <ConsoleView />
}

export default ConsoleViewMountingGate
