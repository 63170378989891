import type { MarketSlug } from "@future/market/config/constants"

import type { PerpsMarketRoute } from "./Router"
import type { MaintenanceMessage } from "../target/config"

export enum RouteRootId {
  markets = "markets",
  trade = "trade",
  earn = "earn",
  leaderboard = "leaderboard",
  history = "history",
  stats = "stats",
}

/**
 * A list of route identifiers which don't resolve to a dedicated page
 */
export enum RouteDynamicRootId {
  referral = "referral",
}

export interface Route {
  targetSlug: string
  rootId?: RouteRootId
  fallbackRootId: RouteRootId
  marketSlug?: MarketSlug
  fallbackMarketSlug: MarketSlug
}

type CreateRouteState<
  RootId extends RouteRootId | "unknown",
  Case extends string,
  Obj extends Record<string, unknown> | unknown = unknown,
> = {
  rootId: RootId
  case: Case
} & Obj

type CreateStandardRouteState<RootId extends RouteRootId> = CreateRouteState<
  RootId,
  "found"
>

type CreateMarketRouteState<RootId extends RouteRootId> =
  | CreateStandardRouteState<RootId>
  | CreateRouteState<RootId, "missing", { newRoute: PerpsMarketRoute }>

export type RouteState =
  | InitialRouteState
  | StandardRouteState
  | MarketRouteState

export type InitialRouteState =
  | CreateRouteState<"unknown", "initializing">
  | CreateRouteState<"unknown", "geoblocked">
  | CreateRouteState<"unknown", "maintenance", { message: MaintenanceMessage }>
  | CreateRouteState<"unknown", "unknown">

export type StandardRouteState =
  | CreateStandardRouteState<RouteRootId.markets>
  | CreateStandardRouteState<RouteRootId.history>
  | CreateStandardRouteState<RouteRootId.stats>

export type MarketRouteState =
  | CreateMarketRouteState<RouteRootId.trade>
  | CreateMarketRouteState<RouteRootId.earn>
  | CreateMarketRouteState<RouteRootId.leaderboard>
