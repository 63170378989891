import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const PredictIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M6.9 12.8H3.7C3.3 12.8 3 13.2 3 13.5V21.2C3 21.6 3.4 22 3.7 22H6.8C7.2 22 7.5 21.6 7.5 21.3V13.6C7.6 13.2 7.2 12.8 6.9 12.8Z" />
      <path d="M13.4 8H10.8C10.4 8 10 8.4 10 8.7V21.2C10 21.6 10.4 21.9 10.7 21.9H13.8C14.2 21.9 14.5 21.5 14.5 21.2V9.1C14.4 8.9 14.2 8.8 14.1 8.6C13.9 8.3 13.7 8.2 13.4 8Z" />
      <path d="M17.5998 7.8C16.6998 8.7 16.2998 9.7 16.2998 10.9C16.2998 9.7 15.7998 8.6 14.9998 7.8C14.0998 6.9 13.0998 6.5 11.7998 6.5C12.9998 6.5 14.0998 6.1 14.9998 5.2C15.8998 4.3 16.2998 3.3 16.2998 2C16.2998 3.2 16.6998 4.3 17.5998 5.2C18.4998 6.1 19.4998 6.5 20.7998 6.5C19.5998 6.5 18.4998 6.9 17.5998 7.8Z" />
    </SvgIcon>
  )
}

export default PredictIcon
