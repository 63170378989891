var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from "axios";
import axiosRetry from "axios-retry";
import { getWallet } from "../wallet";
import { queryClient } from "../shared/queryClient";
import { useChainStore, useAccountStore } from "../shared/store";
import { ErrorMessage } from "../shared/error";
import { getChainInfo } from "./chain";
export const cosmWasmSigningClient = (chainId) => {
    var _a;
    const chainInfo = getChainInfo(chainId);
    const walletType = (_a = useAccountStore.getState().mainAccount(chainId)) === null || _a === void 0 ? void 0 : _a.walletType;
    const queryKey = [
        "cosmWasmSigningClient",
        walletType,
        chainId,
        chainInfo === null || chainInfo === void 0 ? void 0 : chainInfo.rpc,
    ];
    const cachedData = queryClient.getQueryData(queryKey);
    if (cachedData) {
        return Promise.resolve(cachedData);
    }
    return queryClient.fetchQuery({
        queryKey,
        queryFn: () => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            if (!chainInfo) {
                throw new Error(`Unavailable chain: ${chainId}`);
            }
            if (!walletType) {
                throw new Error("Missing wallet type, try to reconnect");
            }
            const provider = getWallet(walletType).provider();
            if (!provider.installed) {
                throw new Error(`Unavailable wallet: ${walletType}`);
            }
            const { GasPrice } = yield import("@cosmjs/stargate");
            const chainConfig = (_a = useChainStore.getState().chainsConfig) === null || _a === void 0 ? void 0 : _a[chainInfo.chainId];
            const endpoint = {
                url: chainInfo.rpc,
                headers: Object.assign({}, ((chainConfig === null || chainConfig === void 0 ? void 0 : chainConfig.rpcHeaders) || {})),
            };
            const gasPrice = (chainConfig === null || chainConfig === void 0 ? void 0 : chainConfig.gas)
                ? GasPrice.fromString(`${chainConfig.gas.price}${chainConfig.gas.denom}`)
                : undefined;
            /**
             * Offline Signer Mode
             *
             * Some wallets have issues using the direct signer (`SIGN_MODE_DIRECT`)
             * such as Cosmostation and Ledger Nano through Keplr. Use the amino
             * signer, since all current wallets support it.
             */
            const offlineSigner = (_b = provider.getOfflineSignerOnlyAmino) === null || _b === void 0 ? void 0 : _b.call(provider, chainInfo.chainId);
            const { SigningCosmWasmClient } = yield import("@cosmjs/cosmwasm-stargate");
            const signingClient = yield SigningCosmWasmClient.connectWithSigner(endpoint, offlineSigner, { gasPrice });
            return signingClient;
        }),
    });
};
export const cosmWasmSignAndBroadcast = (account, instructions, options) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d;
    const { chainId } = account;
    const signingClient = yield cosmWasmSigningClient(chainId);
    const chainNetworkId = (_a = useChainStore
        .getState()
        .options.cosmos) === null || _a === void 0 ? void 0 : _a.networkId(chainId);
    if (!chainNetworkId) {
        throw new Error("Missing `networkId`");
    }
    const { MsgExecuteContract } = yield import("cosmjs-types/cosmwasm/wasm/v1/tx");
    const enc = new TextEncoder();
    const msgs = instructions.map((i) => ({
        typeUrl: "/cosmwasm.wasm.v1.MsgExecuteContract",
        value: MsgExecuteContract.fromPartial({
            sender: account.address,
            contract: i.contractAddress,
            msg: enc.encode(JSON.stringify(i.msg)),
            funds: [...(i.funds || [])],
        }),
    }));
    // https://developer.mozilla.org/en-US/docs/Glossary/Base64
    const encode = (bytes) => {
        const binString = Array.from(bytes, (byte) => String.fromCodePoint(byte)).join("");
        return btoa(binString);
    };
    const simulateUrl = prepareQuerierUrl("/v1/chain/simulate", {
        network: chainNetworkId,
    });
    const { gas_used: gasEstimation } = yield putQuerier(simulateUrl, {
        messages: instructions.map((i) => ({
            execute_contract: {
                sender: account.address,
                contract: i.contractAddress,
                msg: encode(enc.encode(JSON.stringify(i.msg))),
                funds: [...(i.funds || [])],
            },
        })),
    }, options === null || options === void 0 ? void 0 : options.signal);
    const { calculateFee } = yield import("@cosmjs/stargate");
    const multiplier = (_d = (_c = (_b = useChainStore.getState().options.cosmos) === null || _b === void 0 ? void 0 : _b.gasMultiplier) === null || _c === void 0 ? void 0 : _c.call(_b, chainId)) !== null && _d !== void 0 ? _d : 1.4;
    const gasAmount = Math.round(gasEstimation * multiplier);
    const fee = calculateFee(gasAmount, signingClient[`${"gasPrice"}`]);
    const accountUrl = prepareQuerierUrl("/v1/chain/account-info", {
        network: chainNetworkId,
        wallet: account.address,
    });
    const accountInfo = yield queryQuerier(accountUrl, options === null || options === void 0 ? void 0 : options.signal);
    if ("not_found" in accountInfo) {
        throw new Error(ErrorMessage.noBalance);
    }
    const signed = yield signingClient.sign(account.address, msgs, fee, "", {
        accountNumber: accountInfo.found.account,
        sequence: accountInfo.found.sequence,
        chainId,
    });
    const tx = {
        body_bytes: encode(signed.bodyBytes),
        auth_info_bytes: encode(signed.authInfoBytes),
        signatures: signed.signatures.map(encode),
    };
    const broadcastUrl = prepareQuerierUrl("/v1/chain/broadcast", {
        network: chainNetworkId,
    });
    const result = yield putQuerier(broadcastUrl, tx, options === null || options === void 0 ? void 0 : options.signal);
    if (!(typeof result === "object") || result === null) {
        throw new Error("Broadcast must return an object");
    }
    if (!("txhash" in result)) {
        throw new Error("Broadcast response does not include txhash");
    }
    if (!(typeof result.txhash === "string")) {
        throw new Error("Broadcast response includes txhash which is not a string");
    }
    const MAX_ATTEMPTS = 30;
    const DELAY_BETWEEN_ATTEMPTS = 200;
    let attempt = 1;
    while (attempt < MAX_ATTEMPTS) {
        attempt += 1;
        const waitForTxUrl = prepareQuerierUrl("/v1/chain/wait-for-tx", {
            network: chainNetworkId,
            txhash: result.txhash,
        });
        const waitForTxResult = yield queryQuerier(waitForTxUrl, options === null || options === void 0 ? void 0 : options.signal).catch((error) => {
            if (typeof error === "object" &&
                "code" in error &&
                error.code === "ECONNABORTED") {
                return { not_found: null };
            }
            throw error;
        });
        if ("found" in waitForTxResult) {
            const code = waitForTxResult.found.code.toString();
            switch (code) {
                case "0":
                    return { transactionHash: result.txhash };
                case "5":
                    throw new Error(ErrorMessage.insufficientGas, {
                        cause: waitForTxResult.found,
                    });
                case "11":
                    throw new Error(ErrorMessage.incorrectGasCalculated, {
                        cause: waitForTxResult.found,
                    });
                case "13":
                    throw new Error(ErrorMessage.incorrectGasSet, {
                        cause: waitForTxResult.found,
                    });
            }
            throw new Error(ErrorMessage.executeContract, {
                cause: waitForTxResult.found,
            });
        }
        const { sleep } = yield import("@cosmjs/utils");
        yield sleep(DELAY_BETWEEN_ATTEMPTS);
    }
    throw new Error("Timed out waiting for the transaction to be included in a block. This may be caused by temporary congestion. Please try again.");
});
const axiosClient = axios.create();
const defaultRetries = 3;
axiosRetry(axiosClient, {
    retries: defaultRetries,
    retryDelay: axiosRetry.exponentialDelay,
});
const prepareQuerierUrl = (path, params = {}) => {
    var _a;
    const queryString = `?${new URLSearchParams(params)}`;
    const querierUrl = (_a = useChainStore.getState().options.cosmos) === null || _a === void 0 ? void 0 : _a.querierUrl;
    if (!querierUrl) {
        throw new Error("Missing `querierUrl`");
    }
    return `${querierUrl}${path}${queryString}`;
};
const putQuerier = (url, body, signal) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield axiosClient.put(url, body, queryConfig({ signal }));
    return handleQuerierResponse(response);
});
const queryQuerier = (url, signal) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield axiosClient.get(url, queryConfig({ signal }));
    return handleQuerierResponse(response);
});
const handleQuerierResponse = (response) => {
    if (response.status !== 200) {
        const cause = "message" in response.data && typeof response.data.message === "string"
            ? response.data.message
            : response;
        throw new Error(cause);
    }
    return response.data;
};
const queryConfig = (config) => {
    return Object.assign(Object.assign({}, config), { timeout: 10000, validateStatus: () => true });
};
