import Link from "@mui/joy/Link"
import Stack from "@mui/joy/Stack"
import Typography from "@mui/joy/Typography"
import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import type { ConnectedAgent } from "@levana/chain/account"
import HelperButton from "@levana-protocol/ui/HelperButton"
import { useStore } from "zustand"

import { dropletsQueryOptions } from "@future/network/droplets"
import DropletsIcon from "@common/icons/DropletsIcon"
import type { ContextStoreProp } from "@future/context/store"

export interface HeaderDropletsProps extends ContextStoreProp<"standard"> {
  connectedAgent: ConnectedAgent
}

const HeaderDroplets = (props: HeaderDropletsProps) => {
  const { connectedAgent, contextStore } = props

  const chainName = useStore(
    contextStore,
    (state) => state.chain.config.chainName,
  )

  if (chainName === "neutron") {
    return <HeaderDropletsInner connectedAgent={connectedAgent} />
  }
}

const HeaderDropletsInner = (
  props: Pick<HeaderDropletsProps, "connectedAgent">,
) => {
  const { t } = useTranslation("perpsCommon")
  const dropletsResult = useQuery(
    dropletsQueryOptions(props.connectedAgent.account.address),
  )

  if (dropletsResult.isLoadingError) {
    return
  }

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <DropletsIcon />
      <Typography>
        {dropletsResult.data
          ? t("droplets.header.balance", {
              count: Number(
                (Number(dropletsResult.data.balance ?? 0) / 10 ** 6).toFixed(0),
              ),
            })
          : t("droplets.header.loading")}
      </Typography>
      <HelperButton
        modal={{
          title: t("droplets.modal.title"),
          message: (
            <Stack alignItems="center">
              {t("droplets.modal.explainer")}
              <Link
                color="primary"
                href="https://droplets.drop.money/#Defi"
                target="_blank"
                rel="noopener"
                aria-label="learn more about droplets"
              >
                {t("droplets.modal.learnMore")}
              </Link>
            </Stack>
          ),
        }}
      />
    </Stack>
  )
}

export default HeaderDroplets
