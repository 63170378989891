import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const OsmosisIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      <path
        d="M31.9362 11.2422C31.6919 10.3015 30.9066 9.36074 29.4931 8.3135C28.3586 7.47925 27.1546 7 26.1948 7C26.0028 7 25.8283 7.01775 25.6538 7.05325C25.2175 7.142 24.8335 7.4615 24.5893 7.94074C24.2927 8.50874 24.2228 9.272 24.4148 9.73348C24.4846 9.87548 24.5718 10.053 24.6766 10.2127C23.7516 10.7807 23.228 10.9405 23.1582 10.9582C25.5666 11.7747 27.5735 13.4787 28.8299 15.6975L28.8474 15.4845C28.8997 14.8987 29.0742 14.2242 29.3186 13.532C29.5628 13.603 29.8072 13.6385 30.0515 13.6385C30.6972 13.6385 31.2556 13.3722 31.6046 12.893C31.9537 12.4137 32.0932 11.7747 31.9362 11.2422Z"
        fill="#5E12A0"
      />
      <path
        d="M29.4238 13.0177C31.0118 13.4614 31.6575 12.2189 31.448 11.3847C31.2212 10.5504 30.4882 9.69844 29.1968 8.73996C27.9055 7.78146 26.5967 7.39096 25.759 7.56845C24.9214 7.74596 24.6771 9.0417 24.8864 9.53869C24.9737 9.73394 25.1656 10.0179 25.4275 10.3374C25.0959 10.5682 24.7817 10.7457 24.52 10.9054C26.1255 11.6332 27.5216 12.7692 28.5686 14.1892C28.6907 13.7099 28.8653 13.2839 29.0224 12.9112C29.1445 12.9289 29.2841 12.9644 29.4238 13.0177Z"
        fill="url(#paint0_radial_84_1702)"
      />
      <path
        d="M20.3308 30.4135C25.7184 30.4135 30.086 25.9711 30.086 20.4913C30.086 15.0114 25.7184 10.5691 20.3308 10.5691C14.9432 10.5691 10.5757 15.0114 10.5757 20.4913C10.5757 25.9711 14.9432 30.4135 20.3308 30.4135Z"
        fill="url(#paint1_radial_84_1702)"
      />
      <path
        opacity="0.6"
        d="M30.6453 10.5677C29.3189 9.14768 28.2021 8.77492 26.8583 8.47318C25.8113 8.22468 26.0905 7.62118 27.3645 7.74543C26.7537 7.53243 26.1778 7.47918 25.7589 7.56793C24.9213 7.74543 24.677 9.04117 24.8864 9.53817C24.9736 9.73341 25.1656 10.0174 25.4275 10.3369C24.9562 10.6564 24.5548 10.8872 24.2231 11.0647C24.3803 11.1357 24.5722 11.2244 24.799 11.3487C25.3924 11.6682 26.0381 12.2006 26.0381 12.2006C25.0609 11.3487 25.2703 10.9582 26.614 9.99967C27.0329 9.69793 27.8007 9.73341 28.5163 10.1062C29.2317 10.4789 30.0693 11.4197 30.0693 11.4197L29.2666 12.9817C29.3189 12.9994 29.3713 13.0171 29.4237 13.0349C29.9297 13.1769 30.331 13.1414 30.6453 13.0172C31.0118 12.7864 31.9715 12.0054 30.6453 10.5677Z"
        fill="#A98698"
      />
      <path
        d="M26.8406 9.32622C27.1897 9.46822 27.6434 9.71671 28.2019 10.0895C28.865 10.5332 29.4409 11.0302 29.8074 11.4207C29.1966 12.2372 28.7952 13.3554 28.551 14.1364C28.6732 14.3139 28.8127 14.4914 28.9349 14.6689C29.057 14.2252 29.2663 13.5684 29.5456 12.9117C29.6154 12.9294 29.7028 12.9294 29.7899 12.9294C29.9993 12.9294 30.2436 12.894 30.4356 12.7342C30.5752 12.6277 30.7324 12.4325 30.7148 12.0775C30.7148 11.7402 30.4531 11.3142 29.912 10.7995C29.5282 10.4267 29.0047 10.0185 28.4812 9.64572C26.9803 8.63398 25.9332 8.34997 25.4621 8.82922C25.1479 9.14873 25.1828 9.53922 25.2875 9.82322C24.7291 10.196 24.2579 10.4622 23.9438 10.6397C24.1532 10.7107 24.3452 10.7995 24.5546 10.8882C25.113 10.5865 25.9158 10.0717 26.8406 9.32622ZM30.139 11.8645C30.1913 11.9532 30.2088 12.042 30.2088 12.0952C30.2088 12.255 30.1565 12.3082 30.1214 12.3437C30.0517 12.397 29.912 12.4325 29.7899 12.4325C29.8946 12.2195 30.0168 12.042 30.139 11.8645ZM25.8286 9.21972C25.8809 9.16646 26.0206 9.13097 26.2474 9.16646C26.0729 9.30847 25.8984 9.43272 25.7239 9.55697C25.7064 9.43272 25.7239 9.30847 25.8286 9.21972Z"
        fill="#5E12A0"
      />
      <path
        d="M20.331 9.98407C14.6245 9.98407 10 14.6878 10 20.4921C10 26.2961 14.6245 31 20.331 31C26.0375 31 30.6621 26.2961 30.6621 20.4921C30.6621 14.6878 26.0201 9.98407 20.331 9.98407ZM20.331 30.4142C14.9387 30.4142 10.5759 25.9768 10.5759 20.4921C10.5759 15.0073 14.9387 10.5698 20.331 10.5698C25.7234 10.5698 30.0863 15.0073 30.0863 20.4921C30.0863 25.9768 25.7061 30.4142 20.331 30.4142Z"
        fill="#5E12A0"
      />
      <path
        opacity="0.6"
        d="M20.3308 30.4135C25.7184 30.4135 30.086 25.9711 30.086 20.4913C30.086 15.0114 25.7184 10.5691 20.3308 10.5691C14.9432 10.5691 10.5757 15.0114 10.5757 20.4913C10.5757 25.9711 14.9432 30.4135 20.3308 30.4135Z"
        fill="url(#paint2_linear_84_1702)"
      />
      <path
        opacity="0.6"
        d="M20.2622 29.2074C14.9396 28.3377 11.3273 23.2434 12.1998 17.8298C12.5838 15.4335 13.7704 13.3923 15.4457 11.9191C13.0026 13.3568 11.1877 15.8595 10.699 18.9125C9.84393 24.3262 13.4563 29.4204 18.7614 30.2902C21.7281 30.7872 24.5901 29.8464 26.7017 28.0005C24.8344 29.101 22.5658 29.5803 20.2622 29.2074Z"
        fill="#A98698"
      />
      <path
        opacity="0.6"
        d="M21.8837 10.7108C19.4231 10.3026 17.0323 10.8706 15.0778 12.1308C15.0429 12.1663 15.008 12.2018 15.008 12.2018C15.7584 11.7403 16.8578 11.3321 16.8578 11.3321C14.0308 13.0006 13.1582 14.8998 13.1582 14.8998C14.2576 12.7343 17.4861 11.2078 20.0165 11.1013C22.5468 10.9948 24.2046 11.7581 26.229 13.4088C28.2533 15.0773 29.4749 18.4852 29.3528 21.1833C29.2481 23.8814 27.852 26.0645 27.852 26.0645C28.8118 24.8042 29.3877 23.8814 29.7542 22.9404C29.824 22.6565 29.8938 22.3726 29.9286 22.0708C30.8013 16.6748 27.2063 11.5806 21.8837 10.7108Z"
        fill="url(#paint3_linear_84_1702)"
      />
      <path
        d="M29.5974 20.4027C29.5974 25.6034 25.4439 29.828 20.3309 29.828C15.2177 29.828 11.0469 25.6034 11.0469 20.4027H29.5974Z"
        fill="url(#paint4_linear_84_1702)"
      />
      <g opacity="0.6">
        <path
          opacity="0.6"
          d="M28.8639 20.4027C28.8639 25.4792 24.92 29.6326 19.9639 29.828C20.086 29.828 20.2082 29.828 20.3303 29.828C25.4435 29.828 29.597 25.6034 29.597 20.4027H28.8639Z"
          fill="url(#paint5_linear_84_1702)"
        />
      </g>
      <g opacity="0.7">
        <path
          opacity="0.7"
          d="M12.3557 20.4027H11.0469C11.0469 25.6034 15.2002 29.828 20.3134 29.828C20.5403 29.828 20.7497 29.828 20.9591 29.8101C16.1601 29.4551 12.3557 25.3727 12.3557 20.4027Z"
          fill="url(#paint6_linear_84_1702)"
        />
      </g>
      <path
        d="M29.58 20.4906C29.58 19.4079 27.7302 18.7865 25.2694 18.5736C23.4895 18.4316 21.6921 18.609 19.6328 19.248C17.8528 19.7806 16.2473 19.6918 15.0781 19.5497C12.4779 19.248 11.0469 19.2125 11.0469 20.4906C11.0469 22.3366 14.7465 24.6441 20.296 23.8452C23.1056 23.4371 24.554 22.6029 26.2119 22.0348C28.0094 21.4313 29.58 21.4491 29.58 20.4906Z"
        fill="url(#paint7_linear_84_1702)"
      />
      <path
        d="M23.3674 16.0183C24.2349 16.0183 24.938 15.3031 24.938 14.4208C24.938 13.5385 24.2349 12.8233 23.3674 12.8233C22.5001 12.8233 21.7969 13.5385 21.7969 14.4208C21.7969 15.3031 22.5001 16.0183 23.3674 16.0183Z"
        fill="white"
      />
      <path
        d="M25.9331 17.2075C26.2994 17.2075 26.5963 16.9055 26.5963 16.533C26.5963 16.1604 26.2994 15.8585 25.9331 15.8585C25.5669 15.8585 25.27 16.1604 25.27 16.533C25.27 16.9055 25.5669 17.2075 25.9331 17.2075Z"
        fill="white"
      />
      <g opacity="0.6">
        <path
          opacity="0.6"
          d="M28.306 13.1444C28.2885 13.1444 28.2711 13.1444 28.2711 13.1444C28.1663 13.1266 28.0966 13.0201 28.114 12.8959C28.2711 12.0794 28.9342 11.2984 28.9691 11.2629C29.0388 11.1741 29.1785 11.1741 29.2483 11.2451C29.3356 11.3161 29.3356 11.4581 29.2657 11.5291C29.2483 11.5469 28.6375 12.2746 28.498 12.9846C28.4805 13.0911 28.3932 13.1444 28.306 13.1444Z"
          fill="url(#paint8_linear_84_1702)"
        />
      </g>
      <path
        opacity="0.2"
        d="M19.6874 27.8918C19.9572 27.8918 20.176 27.6693 20.176 27.3947C20.176 27.1203 19.9572 26.8977 19.6874 26.8977C19.4175 26.8977 19.1987 27.1203 19.1987 27.3947C19.1987 27.6693 19.4175 27.8918 19.6874 27.8918Z"
        fill="white"
      />
      <g opacity="0.4">
        <path
          opacity="0.6"
          d="M19.4057 27.7188C19.2137 27.5236 19.2137 27.2041 19.4057 27.0088C19.4406 26.9733 19.4755 26.9555 19.5104 26.92C19.4406 26.9379 19.3882 26.9733 19.3359 27.0265C19.1439 27.2218 19.1439 27.5413 19.3359 27.7366C19.4929 27.8963 19.7372 27.9318 19.9292 27.8254C19.7547 27.8963 19.5453 27.8609 19.4057 27.7188Z"
          fill="url(#paint9_linear_84_1702)"
        />
      </g>
      <path
        opacity="0.3"
        d="M19.8591 27.2535C19.9169 27.2535 19.9638 27.2058 19.9638 27.147C19.9638 27.0881 19.9169 27.0405 19.8591 27.0405C19.8013 27.0405 19.7544 27.0881 19.7544 27.147C19.7544 27.2058 19.8013 27.2535 19.8591 27.2535Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M22.2362 27.6442C22.5061 27.6442 22.7248 27.4216 22.7248 27.1471C22.7248 26.8725 22.5061 26.65 22.2362 26.65C21.9663 26.65 21.7476 26.8725 21.7476 27.1471C21.7476 27.4216 21.9663 27.6442 22.2362 27.6442Z"
        fill="white"
      />
      <g opacity="0.4">
        <path
          opacity="0.6"
          d="M21.9545 27.4711C21.7625 27.2758 21.7625 26.9562 21.9545 26.761C21.9893 26.7255 22.0243 26.7077 22.0591 26.6723C21.9893 26.6899 21.937 26.7255 21.8846 26.7787C21.6928 26.974 21.6928 27.2935 21.8846 27.4888C22.0418 27.6486 22.286 27.684 22.478 27.5775C22.3036 27.6486 22.0942 27.6131 21.9545 27.4711Z"
          fill="url(#paint10_linear_84_1702)"
        />
      </g>
      <path
        opacity="0.3"
        d="M22.408 27.0057C22.4658 27.0057 22.5126 26.958 22.5126 26.8991C22.5126 26.8404 22.4658 26.7927 22.408 26.7927C22.3501 26.7927 22.3032 26.8404 22.3032 26.8991C22.3032 26.958 22.3501 27.0057 22.408 27.0057Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M20.9758 28.7995C21.1783 28.7995 21.3423 28.6325 21.3423 28.4267C21.3423 28.2208 21.1783 28.0539 20.9758 28.0539C20.7735 28.0539 20.6094 28.2208 20.6094 28.4267C20.6094 28.6325 20.7735 28.7995 20.9758 28.7995Z"
        fill="white"
      />
      <g opacity="0.4">
        <path
          opacity="0.6"
          d="M20.7664 28.6596C20.6268 28.5176 20.6268 28.2692 20.7664 28.1271C20.7839 28.1093 20.8188 28.0917 20.8362 28.0738C20.7839 28.0917 20.749 28.1271 20.7141 28.1626C20.5745 28.3046 20.5745 28.5532 20.7141 28.6952C20.8362 28.8195 21.0108 28.8371 21.1678 28.7484C21.0282 28.8017 20.8711 28.7662 20.7664 28.6596Z"
          fill="url(#paint11_linear_84_1702)"
        />
      </g>
      <path
        opacity="0.3"
        d="M21.0986 28.301C21.1372 28.301 21.1684 28.2692 21.1684 28.23C21.1684 28.1908 21.1372 28.1589 21.0986 28.1589C21.0601 28.1589 21.0288 28.1908 21.0288 28.23C21.0288 28.2692 21.0601 28.301 21.0986 28.301Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M23.2277 28.5315C23.4301 28.5315 23.5942 28.3647 23.5942 28.1587C23.5942 27.9528 23.4301 27.786 23.2277 27.786C23.0254 27.786 22.8613 27.9528 22.8613 28.1587C22.8613 28.3647 23.0254 28.5315 23.2277 28.5315Z"
        fill="white"
      />
      <g opacity="0.4">
        <path
          opacity="0.6"
          d="M23.0184 28.3918C22.8788 28.2497 22.8788 28.0012 23.0184 27.8592C23.0358 27.8415 23.0707 27.8237 23.0882 27.806C23.0358 27.8237 23.0009 27.8592 22.9661 27.8948C22.8264 28.0367 22.8264 28.2853 22.9661 28.4273C23.0882 28.5515 23.2627 28.5693 23.4198 28.4806C23.2802 28.5339 23.123 28.4982 23.0184 28.3918Z"
          fill="url(#paint12_linear_84_1702)"
        />
      </g>
      <path
        opacity="0.3"
        d="M23.3511 28.0351C23.3897 28.0351 23.4209 28.0033 23.4209 27.9641C23.4209 27.9249 23.3897 27.8931 23.3511 27.8931C23.3126 27.8931 23.2812 27.9249 23.2812 27.9641C23.2812 28.0033 23.3126 28.0351 23.3511 28.0351Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M17.6084 27.7161C18.0518 27.7161 18.4112 27.3504 18.4112 26.8996C18.4112 26.4486 18.0518 26.083 17.6084 26.083C17.1651 26.083 16.8057 26.4486 16.8057 26.8996C16.8057 27.3504 17.1651 27.7161 17.6084 27.7161Z"
        fill="white"
      />
      <g opacity="0.4">
        <path
          opacity="0.6"
          d="M17.1548 27.4125C16.8406 27.0929 16.8406 26.5781 17.1548 26.2587C17.2071 26.2054 17.2595 26.1699 17.3293 26.1343C17.2246 26.1699 17.1373 26.2409 17.05 26.3119C16.7359 26.6314 16.7359 27.1462 17.05 27.4656C17.3118 27.7319 17.7132 27.7852 18.0273 27.5898C17.7306 27.7141 17.3816 27.6609 17.1548 27.4125Z"
          fill="url(#paint13_linear_84_1702)"
        />
      </g>
      <path
        opacity="0.3"
        d="M17.8884 26.6499C17.9848 26.6499 18.0629 26.5704 18.0629 26.4724C18.0629 26.3744 17.9848 26.2949 17.8884 26.2949C17.792 26.2949 17.7139 26.3744 17.7139 26.4724C17.7139 26.5704 17.792 26.6499 17.8884 26.6499Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M24.7846 25.7096C25.228 25.7096 25.5874 25.344 25.5874 24.8931C25.5874 24.4421 25.228 24.0766 24.7846 24.0766C24.3413 24.0766 23.9819 24.4421 23.9819 24.8931C23.9819 25.344 24.3413 25.7096 24.7846 25.7096Z"
        fill="white"
      />
      <g opacity="0.4">
        <path
          opacity="0.6"
          d="M24.309 25.408C23.9948 25.0886 23.9948 24.5739 24.309 24.2543C24.3613 24.201 24.4138 24.1655 24.4836 24.13C24.3787 24.1655 24.2916 24.2366 24.2044 24.3076C23.8902 24.627 23.8902 25.1418 24.2044 25.4613C24.4661 25.7276 24.8675 25.7808 25.1816 25.5855C24.8849 25.7098 24.5532 25.6566 24.309 25.408Z"
          fill="url(#paint14_linear_84_1702)"
        />
      </g>
      <path
        opacity="0.3"
        d="M25.0602 24.6459C25.1567 24.6459 25.2347 24.5663 25.2347 24.4683C25.2347 24.3702 25.1567 24.2908 25.0602 24.2908C24.9639 24.2908 24.8857 24.3702 24.8857 24.4683C24.8857 24.5663 24.9639 24.6459 25.0602 24.6459Z"
        fill="white"
      />
      <defs>
        <radialGradient
          id="paint0_radial_84_1702"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(31.1297 10.2664) scale(7.79965 7.93321)"
        >
          <stop stopColor="#FFEAFF" stopOpacity="0.6" />
          <stop offset="0.6807" stopColor="#A087C9" />
          <stop offset="1" stopColor="#10002F" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_84_1702"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(29.0873 18.7292) scale(21.844 23.6299)"
        >
          <stop stopColor="#FFEAFF" stopOpacity="0.6" />
          <stop offset="0.6807" stopColor="#A087C9" />
          <stop offset="1" stopColor="#10002F" />
        </radialGradient>
        <linearGradient
          id="paint2_linear_84_1702"
          x1="18.766"
          y1="30.2728"
          x2="21.9912"
          y2="10.7107"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#81FFFF" />
          <stop offset="0.6202" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_84_1702"
          x1="27.2055"
          y1="13.7526"
          x2="19.0139"
          y2="21.1421"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2888" stopColor="white" />
          <stop offset="0.7796" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_84_1702"
          x1="11.0547"
          y1="25.1097"
          x2="29.5929"
          y2="25.1097"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0002E9" />
          <stop offset="0.9952" stopColor="#FF00C7" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_84_1702"
          x1="30.47"
          y1="21.2297"
          x2="20.2182"
          y2="30.7551"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2888" stopColor="white" />
          <stop offset="0.7796" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_84_1702"
          x1="11.0547"
          y1="25.1097"
          x2="20.9725"
          y2="25.1097"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#000292" />
          <stop offset="0.9952" stopColor="#7D00C7" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_84_1702"
          x1="11.0672"
          y1="21.2736"
          x2="29.5804"
          y2="21.2736"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#000292" />
          <stop offset="0.9952" stopColor="#BE00C7" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_84_1702"
          x1="29.2767"
          y1="11.1764"
          x2="27.913"
          y2="13.355"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2888" stopColor="white" />
          <stop offset="0.7796" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_84_1702"
          x1="19.1914"
          y1="27.4145"
          x2="19.9297"
          y2="27.4145"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2888" stopColor="white" />
          <stop offset="0.7796" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_84_1702"
          x1="21.7385"
          y1="27.1633"
          x2="22.4767"
          y2="27.1633"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2888" stopColor="white" />
          <stop offset="0.7796" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_84_1702"
          x1="20.5996"
          y1="28.4325"
          x2="21.1539"
          y2="28.4325"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2888" stopColor="white" />
          <stop offset="0.7796" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_84_1702"
          x1="22.8515"
          y1="28.1645"
          x2="23.4058"
          y2="28.1645"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2888" stopColor="white" />
          <stop offset="0.7796" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_84_1702"
          x1="16.8064"
          y1="26.9185"
          x2="18.0122"
          y2="26.9185"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2888" stopColor="white" />
          <stop offset="0.7796" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_84_1702"
          x1="23.9675"
          y1="24.9135"
          x2="25.1731"
          y2="24.9135"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2888" stopColor="white" />
          <stop offset="0.7796" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </SvgIcon>
  )
}

export default OsmosisIcon
