import Button from "@mui/joy/Button"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useStore } from "zustand"

import { useBannerContext } from "@perps/banner/BannerContext"
import type { TargetId } from "@common/target/types"
import { useNavigate } from "@future/router/useNavigate"
import { domainConfig } from "@future/target/domain"
import type {
  PerpsMarketRoute,
  PerpsNonMarketRoute,
} from "@future/router/Router"
import type { ContextStoreProp } from "@future/context/store"
import { MarketSlug } from "@future/market/config/constants"
import { RouteRootId } from "@future/router/types"

const SeiSuspendBanner = (props: ContextStoreProp<"standard">) => {
  const bannerContext = useBannerContext()
  const { show, hide } = bannerContext.system
  const { t } = useTranslation("common")
  const navigate = useNavigate()

  const chainName = useStore(
    props.contextStore,
    (state) => state.chain.config.chainName,
  )

  useEffect(() => {
    if (chainName === "sei") {
      const key = "suspend.sei"

      const handleClick = () => {
        const { chain, markets, marketLookup } = props.contextStore.getState()

        const osmoTargetId: TargetId =
          chain.config.network === "mainnet" ? "osmomainnet1" : "osmobeta"
        const osmoTarget = domainConfig.getTargetById(osmoTargetId)

        if (!osmoTarget) {
          return
        }

        const pathComponents = window.location.pathname
          .split("/")
          .filter(Boolean)
        const pageSlug = pathComponents.at(1)
        const marketSlug = pathComponents.at(2)

        if (!pageSlug) {
          navigate({ targetSlug: osmoTarget.slug, route: RouteRootId.markets })
          return
        }

        const marketId = marketSlug
          ? marketLookup.slugToId(MarketSlug.get(marketSlug))
          : undefined
        const marketConfig = marketId
          ? markets.get(marketId)?.config
          : undefined

        if (!marketConfig) {
          const route = ((): PerpsNonMarketRoute["route"] => {
            switch (pageSlug) {
              case RouteRootId.history:
                return RouteRootId.history
              default:
                return RouteRootId.markets
            }
          })()

          navigate({ targetSlug: osmoTarget.slug, route })
          return
        }

        const route = ((): PerpsMarketRoute["route"] => {
          switch (pageSlug) {
            case RouteRootId.earn:
              return RouteRootId.earn
            case RouteRootId.leaderboard:
              return RouteRootId.leaderboard
            default:
              return RouteRootId.trade
          }
        })()

        navigate({
          targetSlug: osmoTarget.slug,
          route,
          marketSlug: marketConfig.slug,
        })
      }

      show(
        key,
        t("banner.suspend.sei.title"),
        <Button
          size="sm"
          onClick={handleClick}
          aria-label="navigate from suspended sei"
        >
          {t("banner.suspend.sei.button")}
        </Button>,
      )
      return () => hide(key)
    }
  }, [show, hide, navigate, chainName, props.contextStore, t])

  return null
}

export default SeiSuspendBanner
