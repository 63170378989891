import Box from "@mui/joy/Box"
import Typography from "@mui/joy/Typography"
import Skeleton from "@mui/joy/Skeleton"
import Button from "@mui/joy/Button"
import { useTranslation } from "react-i18next"
import HelperButton from "@levana-protocol/ui/HelperButton"

import { useReferralStatsQuery } from "@future/network/referralStats"
import type { ContextStoreProp } from "@future/context/store"
import { useNavigate } from "@future/router/useNavigate"
import { RouteRootId } from "@future/router/types"

interface TotalRewardsSectionProps extends ContextStoreProp<"standard"> {
  onCloseModal: () => void
}

const TotalRewardsSection = (props: TotalRewardsSectionProps) => {
  const { t } = useTranslation("perpsCommon")
  const referralStatsQuery = useReferralStatsQuery(props)
  const navigate = useNavigate()

  const earnPageNavigate = () => {
    navigate({
      route: RouteRootId.markets,
      targetSlug: props.contextStore.getState().targetInfo.slug,
    })
    props.onCloseModal()
  }

  return (
    <>
      <Box>
        <Typography
          level="body-sm"
          textColor="text.secondary"
          endDecorator={
            <HelperButton
              modal={{
                title: t("referral.totalRewards.title"),
                message: t("referral.totalRewards.helper"),
              }}
              aria-label="total rewards helper"
            />
          }
        >
          {t("referral.totalRewards.title")}
        </Typography>
        <Typography level="h4" textColor="text.primary">
          <Skeleton
            loading={!referralStatsQuery.data && referralStatsQuery.isFetching}
            variant="rectangular"
            height={30}
          >
            {referralStatsQuery.data?.generatedRewards.toPersonalPrice()}
          </Skeleton>
        </Typography>
      </Box>
      <Button color="neutral" variant="soft" onClick={earnPageNavigate}>
        {t("referral.totalRewards.button")}
      </Button>
    </>
  )
}

export default TotalRewardsSection
