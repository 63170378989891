var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ConnectedAgentType, isCosmosAccount, } from "../shared/account";
import { useChainStore } from "../shared/store";
import { updateEthereumChainIfNeeded, walletTypeToInjective } from "./wallet";
import { useInjectiveStore } from "./store";
import { injectiveSignAndBroadcast, injectiveSigningClient } from "./signing";
import { ErrorMessage } from "../shared/error";
import { SigningType } from "../shared/signing";
import { ctrlChromeExtensionId } from "../wallet/xdefi";
const connect = (chainId, walletType) => __awaiter(void 0, void 0, void 0, function* () {
    const { walletStrategy } = useInjectiveStore.getState();
    if (!walletStrategy) {
        throw new Error("Missing WalletStrategy for connect");
    }
    const addresses = yield (() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            return yield walletStrategy.getAddresses();
        }
        catch (error) {
            if (error instanceof Error) {
                if (error.message.includes(ctrlChromeExtensionId)) {
                    throw new Error(ErrorMessage.disableCtrl);
                }
            }
            throw error;
        }
    }))();
    const address = addresses === null || addresses === void 0 ? void 0 : addresses.at(0);
    if (!address) {
        throw new Error(ErrorMessage.noAccount);
    }
    const { getInjectiveAddress, getEthereumAddress } = yield import("@injectivelabs/sdk-ts");
    const isInjectiveAddress = address.startsWith("inj1");
    const bech32Address = isInjectiveAddress
        ? address
        : getInjectiveAddress(address);
    const ethereumAddress = isInjectiveAddress
        ? getEthereumAddress(address)
        : address;
    try {
        return {
            walletType,
            address: bech32Address,
            ethereumAddress,
            publicKey: yield walletStrategy.getPubKey(),
            chainId,
        };
    }
    catch (_a) {
        return {
            walletType,
            address: bech32Address,
            ethereumAddress,
            chainId,
        };
    }
});
export const injectiveConnect = (strategy) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const chainId = useChainStore.getState().chainId;
    const walletType = strategy.walletType;
    const injectiveWalletType = yield walletTypeToInjective(walletType);
    (_a = useInjectiveStore.getState().walletStrategy) === null || _a === void 0 ? void 0 : _a.setWallet(injectiveWalletType);
    const account = yield connect(chainId, walletType);
    updateEthereumChainIfNeeded(chainId);
    return injectiveConnectedAgent(account);
});
export const injectiveConnectedAgent = (account) => {
    if (isCosmosAccount(account)) {
        return {
            type: ConnectedAgentType.cosmos,
            account,
            signAndBroadcast: (instructions, options) => {
                return injectiveSignAndBroadcast(account, instructions, options);
            },
            signing: {
                type: SigningType.injective,
                client: () => injectiveSigningClient(account.chainId),
            },
        };
    }
    else {
        return {
            type: ConnectedAgentType.ethereum,
            account,
            signAndBroadcast: (instructions, options) => {
                return injectiveSignAndBroadcast(account, instructions, options);
            },
        };
    }
};
export const injectiveDisconnect = () => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    return (_a = useInjectiveStore.getState().walletStrategy) === null || _a === void 0 ? void 0 : _a.disconnect();
});
