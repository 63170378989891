import type { FullMarketInfo } from "@perps/sdk/client/CosmosClient"
import type { PositionAction } from "@perps/sdk/types"
import { nanoToMilli } from "@perps/sdk/utils"
import { Amount } from "@future/numerics/amount"
import {
  Collateral,
  Leverage,
  Notional,
  Quote,
  TakeProfit,
  Usd,
} from "@future/numerics"
import { MarketPrice } from "@future/market/price/types"
import { calculateMaxGains } from "@perps/tradeMarket/orderCalculations"
import { calcEstimatedPnl } from "@future/calculations/estimatedPnl"

import type { MarketOpenPosition } from "./api"
import type { OpenPosition } from "./types"

export const formatOpenPosition = (
  marketPosition: MarketOpenPosition,
  market: FullMarketInfo,
  initialLeverageValue: PositionAction["leverage"],
): OpenPosition => {
  const { position, price } = marketPosition

  const marketPrice = market.price
  const initialMarketPrice = new MarketPrice({
    priceBase: new Quote(price.base),
    priceUsd: new Usd(price.collateral),
    marketType: market.config.type,
    quoteAssetId: market.config.quote,
    marketId: market.config.id,
  })

  const maxGainsPrice = position.take_profit_price_base
    ? new Amount(position.take_profit_price_base)
    : new Amount(Number.POSITIVE_INFINITY)
  const dnfFactor = marketPrice.collateralToUsd(
    new Collateral(position.dnf_on_close_collateral),
  )
  const takeProfitOverride = position.take_profit_override
    ? new TakeProfit(position.take_profit_override)
    : undefined
  const takeProfit = takeProfitOverride
    ? new TakeProfit(
        marketPrice.priceBase
          .toAmount()
          .closer(takeProfitOverride, maxGainsPrice),
      )
    : new TakeProfit(maxGainsPrice)
  const deltaNeutralityFeeUsd = new Usd(position.delta_neutrality_fee_usd)
  const dnfOnCloseCollateral = new Collateral(position.dnf_on_close_collateral)
  const tradingFeeUsd = new Usd(position.trading_fee_usd)
  const borrowFeeUsd = new Usd(position.borrow_fee_usd)
  const fundingFeeUsd = new Usd(position.funding_fee_usd)
  const crankFeeUsd = new Usd(position.crank_fee_usd)
  const dnfTaxUsd = deltaNeutralityFeeUsd.plus(
    marketPrice.collateralToUsd(dnfOnCloseCollateral),
  )
  const notionalSize = new Notional(position.notional_size)
  const pnlUsd = new Usd(new Amount(position.pnl_usd).minus(dnfFactor))
  const directionToBase = (() => {
    switch (market.config.type) {
      case "collateral_is_quote":
        return notionalSize.isGreaterThan(0) ? "long" : "short"
      case "collateral_is_base":
        return notionalSize.isGreaterThan(0) ? "short" : "long"
    }
  })()
  const liquidationPrice = position.liquidation_price_base
    ? new Quote(position.liquidation_price_base)
    : undefined
  const activeCollateral = new Collateral(position.active_collateral).minus(
    new Collateral(position.dnf_on_close_collateral),
  )
  const stopLossOverride = position.stop_loss_override
    ? new Quote(position.stop_loss_override)
    : undefined
  const counterCollateral = new Collateral(position.counter_collateral)
  const entryPrice = new Quote(position.entry_price_base)

  // Calculate the notional prices and price delta
  const currentPriceNotional = marketPrice.priceNotionalInCollateral()
  const simulatedPriceNotional = initialMarketPrice.priceNotionalInCollateral()
  const priceDelta = currentPriceNotional.minus(simulatedPriceNotional)

  // Determine the impact on PnL from this price change. We're multiplying a
  // signed notional size (positive == long-to-notional, negative ==
  // short-to-notional) against the change in notional price. If the
  // notional price went down and we have a short-to-notional position, then
  // it's a negative times a negative giving us positive PnL. Other
  // combinations follow the same logic.
  //
  // The complication is that we're talking in terms of _notional_, not
  // _base_. For a collateral-is-base market, remember that that
  // direction-to-notional is the reverse of direction-to-base, and the sign
  // of the price delta will be the opposite for base vs notional prices.
  const deltaPnlCollateral = new Collateral(
    notionalSize.toAmount().times(priceDelta),
  )

  // And now convert that delta into USD and use it in returning an updated
  // PnL for the position.
  const deltaPnlUsd = marketPrice.collateralToUsd(deltaPnlCollateral)

  // Calculate updated values for fields that need to change
  const newPnlUsd = pnlUsd.plus(deltaPnlUsd)
  const newCounterCollateral = counterCollateral.minus(deltaPnlCollateral)
  const newActiveCollateral = activeCollateral.plus(deltaPnlCollateral)
  const activeCollateralUsd = marketPrice.collateralToUsd(newActiveCollateral)

  const initialLeverage = initialLeverageValue
    ? new Leverage(initialLeverageValue)
    : undefined

  // Take a break to calculate leverage numbers to be used below
  const leverageToNotional = marketPrice
    .notionalToCollateral(notionalSize)
    .divide(newActiveCollateral)
  const leverageToBaseSigned = (() => {
    if (marketPrice.marketType === "collateral_is_quote") {
      return leverageToNotional
    } else {
      return new Amount(1).minus(leverageToNotional)
    }
  })()

  // Continue calculating new fields
  const leverage = new Leverage(leverageToBaseSigned.abs())
  const positionSizeBase = marketPrice.collateralToBase(
    new Collateral(newActiveCollateral.toAmount().times(leverage)),
  )
  const positionSizeUsd = marketPrice.baseToUsd(positionSizeBase)
  const maxGainsInQuotePercentage = calculateMaxGains({
    activeCollateral: newActiveCollateral,
    counterCollateral: newCounterCollateral,
    notionalSize: notionalSize,
    marketPrice: marketPrice,
    marketType: marketPrice.marketType,
  }).maxGains

  // Check if this is a zombie position. The logic here is pretty involved,
  // but not that complicated. Essentially, we want to figure out the most
  // conservative price between stop loss and liquidation (which is the
  // higher price for longs and the lower price for shorts). However, both
  // of these fields may be undefined, so we have to deal with those cases.
  // Then we can use the takeProfit field, which does the same thing for max
  // gains/take profit price. Fortunately, that's always guaranteed to be
  // defined.
  //
  // Once we have those two prices, we compare against the current price,
  // based on whether we're looking at a long or short position. Again, some
  // fancy footwork, but not complicated footwork, to deal with the possibly
  // undefined stop loss/take profit.
  //
  // Alternative approach: never let stopLossOrLiquidation be undefined, and
  // instead set it to 0 (for longs) or +Inf (short shorts).
  const stopLossOrLiquidation =
    stopLossOverride === undefined
      ? liquidationPrice
      : liquidationPrice === undefined
        ? stopLossOverride
        : directionToBase === "long"
          ? liquidationPrice.max(stopLossOverride)
          : liquidationPrice.min(stopLossOverride)

  const zombie: boolean =
    directionToBase === "long"
      ? (stopLossOrLiquidation
          ? marketPrice.priceBase.isLessThanOrEqualTo(stopLossOrLiquidation)
          : false) || marketPrice.priceBase.isGreaterThanOrEqualTo(takeProfit)
      : marketPrice.priceBase.isLessThanOrEqualTo(takeProfit) ||
        (stopLossOrLiquidation
          ? marketPrice.priceBase.isGreaterThanOrEqualTo(stopLossOrLiquidation)
          : false)

  const fees = tradingFeeUsd
    .plus(borrowFeeUsd)
    .plus(fundingFeeUsd)
    .plus(dnfTaxUsd)
    .plus(crankFeeUsd)
  const { estimatedProfit, estimatedLoss } = calcEstimatedPnl({
    direction: directionToBase,
    marketConfig: market.config,
    notionalSize,
    entryPrice,
    takeProfitPrice: takeProfit,
    stopLossPrice: stopLossOverride,
    liquidationPrice,
    initialLeverage,
    fees,
  })

  return {
    fullMarketInfo: market,
    activeCollateral: newActiveCollateral,
    activeCollateralUsd,
    borrowFeeCollateral: new Collateral(position.borrow_fee_collateral),
    borrowFeeUsd,
    counterCollateral: newCounterCollateral,
    counterLeverage: new Amount(position.counter_leverage),
    crankFeeCollateral: new Collateral(position.crank_fee_collateral),
    crankFeeUsd,
    createdAtTimeMs: nanoToMilli(position.created_at),
    deltaNeutralityFeeCollateral: new Collateral(
      position.delta_neutrality_fee_collateral,
    ),
    deltaNeutralityFeeUsd,
    depositCollateral: new Collateral(position.deposit_collateral),
    depositCollateralUsd: new Usd(position.deposit_collateral_usd),
    // The contract will return the "true" direction to base.
    // Strangely, due to off-by-one leverage calculations for
    // collateral-is-base markets, this can sometimes flip the
    // direction of a position, see PERP-996 for details.
    // Instead of relying on the value from the contract, we
    // calculate the value here instead.
    directionToBase,
    dnfOnCloseCollateral,
    entryPrice,
    fundingFeeCollateral: new Collateral(position.funding_fee_collateral),
    fundingFeeUsd,
    id: position.id.toString(),
    leverage,
    initialLeverage,
    liquidationMargin: {
      borrow: new Collateral(position.liquidation_margin.borrow),
      crank: new Collateral(position.liquidation_margin.crank),
      deltaNeutrality: new Collateral(
        position.liquidation_margin.delta_neutrality,
      ),
      funding: new Collateral(position.liquidation_margin.funding),
      exposure: new Collateral(
        position.liquidation_margin.exposure ?? new Amount(0),
      ),
    },
    liquidationPrice,
    liquifundedAtMs: nanoToMilli(position.liquifunded_at),
    nextLiquifundingMs: nanoToMilli(position.next_liquifunding),
    notionalSize,
    notionalSizeInCollateral: new Collateral(
      position.notional_size_in_collateral,
    ),
    ownerAddress: position.owner,
    pnlCollateral: new Collateral(position.pnl_collateral).minus(
      new Collateral(position.dnf_on_close_collateral),
    ),
    pnlUsd: newPnlUsd,
    positionSizeBase,
    positionSizeUsd,
    stopLossOverride,
    takeProfitOverride,
    takeProfit,
    estimatedProfit,
    estimatedLoss,
    maxGainsPrice,
    maxGainsInQuotePercentage,
    takeProfitPriceBase: position.take_profit_price_base
      ? new Amount(position.take_profit_price_base)
      : undefined,
    tradingFeeCollateral: new Collateral(position.trading_fee_collateral),
    tradingFeeUsd,
    dnfTaxUsd,
    fees,
    simulatedPrice: marketPrice,
    zombie,
  }
}
