import * as Sentry from "@sentry/react"
import {
  type PropsWithChildren,
  useEffect,
  Suspense,
  lazy,
  type LazyExoticComponent,
} from "react"
import {
  type RouteObject,
  RouterProvider,
  createBrowserRouter,
  json,
  redirect,
  useNavigate as useRouterNavigate,
} from "react-router-dom"
import { useStore } from "zustand"
import { isCosmosAddress } from "@levana/chain/cosmos"
import { ModalProvider } from "@levana-protocol/utils/modal"

import { MarketSlug } from "@future/market/config/constants"
import type { TargetSlug } from "@future/target/info"
import { domainConfig } from "@future/target/domain"
import RootLayout from "@future/root/RootLayout"
import RootLayout_Inner from "@perps/root/RootLayout"
import { isProduction } from "@common/env/constants"
import LoadingContent from "@future/root/LoadingContent"
import {
  isStandardContextStore,
  type ContextStoreProp,
} from "@future/context/store"
import InvalidRootLayout from "@future/root/InvalidRootContent"
import LoadingPage from "@future/root/LoadingPage"
import { AppError } from "@future/libs/error/AppError"
import ErrorPage from "@app/not-found"
import { chainConfigMap } from "@common/target/constants"
import type { ChainNetworkId } from "@common/target/types"
import { useReferralStore } from "@future/referral/referralCode"
import { chainNetworkIdToTargetId } from "@future/chain/utils"
import { useMarketsStore } from "@future/pages/markets/marketsListStore"
import { AutoShowChooseChainModal } from "@future/chain/ChooseChainModal"

import RouteRootError, {
  RouteNotFoundError,
  TestErrorPage,
} from "./RouteRootError"
import { getDefaultMarketSlug, getDefaultTargetInfo } from "./utils"
import { RouteDynamicRootId, RouteRootId } from "./types"

const Pages: Record<
  RouteRootId,
  LazyExoticComponent<(props: ContextStoreProp<"standard">) => JSX.Element>
> = {
  earn: lazy(() => import("@app/earn/marketPage")),
  history: lazy(() => import("@app/history/page")),
  leaderboard: lazy(() => import("@app/leaderboard/marketPage")),
  markets: lazy(() => import("@app/markets/page")),
  stats: lazy(() => import("@app/stats/page")),
  trade: lazy(() => import("@app/trade/marketPage")),
}

const ShowPage = (props: ContextStoreProp<"initial">): JSX.Element => {
  const routeState = useStore(props.contextStore, (state) => state.routeState)

  switch (routeState.rootId) {
    case "unknown":
      switch (routeState.case) {
        case "initializing":
          return (
            <LoadingPage
              type="full-screen"
              desc={["Initializing"]}
              contextStore={props.contextStore}
            />
          )
        case "geoblocked":
          return (
            <InvalidRootLayout
              contextStore={props.contextStore}
              content={{ type: "geoblocked" }}
            />
          )
        case "maintenance":
          return (
            <InvalidRootLayout
              contextStore={props.contextStore}
              content={{
                type: "maintenance",
                message: routeState.message,
              }}
            />
          )
        case "unknown":
          return <ErrorPage contextStore={props.contextStore} />
      }
      break
    case RouteRootId.trade:
    case RouteRootId.earn:
    case RouteRootId.leaderboard:
      switch (routeState.case) {
        case "found":
          return (
            <PageContainer
              contextStore={props.contextStore}
              rootId={routeState.rootId}
            />
          )
        case "missing":
          return <PerformRedirect dest={renderRoute(routeState.newRoute)} />
      }
      break
    case RouteRootId.markets:
    case RouteRootId.history:
    case RouteRootId.stats:
      return (
        <PageContainer
          contextStore={props.contextStore}
          rootId={routeState.rootId}
        />
      )
  }
}

const PageContainer = (
  props: ContextStoreProp<"initial"> & { rootId: RouteRootId },
) => {
  if (!isStandardContextStore(props.contextStore)) {
    return (
      <InvalidRootLayout
        contextStore={props.contextStore}
        content={{
          type: "root-error",
          error: AppError.fromText("Invalid context store"),
        }}
      />
    )
  }

  const Page = Pages[props.rootId]

  return (
    <RootLayout_Inner contextStore={props.contextStore}>
      <AutoShowChooseChainModal contextStore={props.contextStore} />
      <Suspense
        fallback={
          <LoadingPage
            type="component"
            desc={["Loading"]}
            contextStore={props.contextStore}
          />
        }
      >
        <Page contextStore={props.contextStore} />
      </Suspense>
    </RootLayout_Inner>
  )
}

const PerformRedirect = ({ dest }: { dest: string }): JSX.Element => {
  const navigate = useRouterNavigate()
  useEffect(() => {
    navigate(dest)
  }, [navigate, dest])
  return <LoadingContent messages={[`Redirecting to ${dest}`]} />
}

export type PerpsRoute = { route: RouteRootId.stats } | PerpsTargetRoute

type PerpsTargetRoute = PerpsNonMarketRoute | PerpsMarketRoute

export type PerpsNonMarketRoute =
  | { route: RouteRootId.markets; targetSlug: TargetSlug }
  | { route: RouteRootId.history; targetSlug: TargetSlug }

export type PerpsMarketRoute =
  | {
      route: RouteRootId.trade
      targetSlug: TargetSlug
      marketSlug?: MarketSlug
    }
  | { route: RouteRootId.earn; targetSlug: TargetSlug; marketSlug?: MarketSlug }
  | {
      route: RouteRootId.leaderboard
      targetSlug: TargetSlug
      marketSlug: MarketSlug
    }

// TODO:
export type TargetRoute2 =
  | { route: "stats"; targetSlug: TargetSlug }
  | PerpsTargetRoute

export const renderRoute = (dest: PerpsRoute): string => {
  const pathComponents = ((): (string | null)[] => {
    switch (dest.route) {
      case RouteRootId.markets:
        return [dest.targetSlug, RouteRootId.markets]
      case RouteRootId.trade:
        return [
          dest.targetSlug,
          RouteRootId.trade,
          dest.marketSlug?.slug ?? null,
        ]
      case RouteRootId.earn:
        return [
          dest.targetSlug,
          RouteRootId.earn,
          dest.marketSlug?.slug ?? null,
        ]
      case RouteRootId.history:
        return [dest.targetSlug, RouteRootId.history]
      case RouteRootId.leaderboard:
        return [dest.targetSlug, RouteRootId.leaderboard, dest.marketSlug.slug]
      case RouteRootId.stats:
        return [RouteRootId.stats]
    }
  })()

  return `/${pathComponents.filter(Boolean).join("/")}`
}

const redirectRoute = (dest: PerpsRoute) => redirect(renderRoute(dest))

const LoadPerpRoute = (): JSX.Element => {
  return (
    <RootLayout>
      {(contextStore) => (
        <RouteProviders>
          <ShowPage contextStore={contextStore} />
        </RouteProviders>
      )}
    </RootLayout>
  )
}

const RouteProviders = (props: PropsWithChildren) => {
  // For providers which need to be at the highest level within a Route
  return (
    <>
      {props.children}
      <ModalProvider />
    </>
  )
}

const routeJson = (route: PerpsRoute) => json(route)

const routerObjects: RouteObject[] = [
  {
    path: "*",
    element: (
      <RootLayout>
        {(contextStore) => (
          <RouteProviders>
            <ErrorPage contextStore={contextStore} />
          </RouteProviders>
        )}
      </RootLayout>
    ),
  },
  {
    path: "/",
    loader: () => {
      const targetInfo = getDefaultTargetInfo()
      const marketSlug = getDefaultMarketSlug(targetInfo)
      return redirectRoute({
        route: RouteRootId.trade,
        targetSlug: targetInfo.slug,
        marketSlug,
      })
    },
  },
  {
    path: `/:targetSlug/${RouteRootId.markets}`,
    loader: ({ params }) => {
      const targetSlug = params.targetSlug as string
      return routeJson({ route: RouteRootId.markets, targetSlug })
    },
    element: <LoadPerpRoute />,
  },
  {
    path: `/:targetSlug/${RouteRootId.trade}/:marketSlug?`,
    loader: ({ params }) => {
      const targetSlug = params.targetSlug as string

      if (params.marketSlug === undefined) {
        const target = domainConfig.getTargetBySlug(targetSlug)

        if (!target) {
          throw new RouteNotFoundError()
        }

        useMarketsStore.setState({ category: "trade" })
        return redirectRoute({ route: RouteRootId.markets, targetSlug })
      }

      const marketSlug = MarketSlug.get(params.marketSlug as string)
      return routeJson({ route: RouteRootId.trade, targetSlug, marketSlug })
    },
    element: <LoadPerpRoute />,
  },
  {
    path: `/:targetSlug/${RouteRootId.earn}/:marketSlug?`,
    loader: ({ params }) => {
      const targetSlug = params.targetSlug as string

      if (params.marketSlug === undefined) {
        const target = domainConfig.getTargetBySlug(targetSlug)

        if (!target) {
          throw new RouteNotFoundError()
        }

        useMarketsStore.setState({ category: "earn" })
        return redirectRoute({ route: RouteRootId.markets, targetSlug })
      }

      const marketSlug = MarketSlug.get(params.marketSlug as string)
      return routeJson({ route: RouteRootId.earn, targetSlug, marketSlug })
    },
    element: <LoadPerpRoute />,
  },
  {
    path: `/:targetSlug/${RouteRootId.leaderboard}/:marketSlug?`,
    loader: ({ params }) => {
      const targetSlug = params.targetSlug as string

      if (params.marketSlug === undefined) {
        const target = domainConfig.getTargetBySlug(targetSlug)

        if (!target) {
          throw new RouteNotFoundError()
        }

        return redirectRoute({
          route: RouteRootId.leaderboard,
          targetSlug,
          marketSlug: getDefaultMarketSlug(target),
        })
      }

      const marketSlug = MarketSlug.get(params.marketSlug as string)
      return routeJson({
        route: RouteRootId.leaderboard,
        targetSlug,
        marketSlug,
      })
    },
    element: <LoadPerpRoute />,
  },
  {
    path: `/:targetSlug/${RouteRootId.history}`,
    loader: ({ params }) => {
      const targetSlug = params.targetSlug as string
      return routeJson({ route: RouteRootId.history, targetSlug })
    },
    element: <LoadPerpRoute />,
  },
  {
    path: `/:targetSlug/${RouteRootId.stats}/:marketId?`,
    loader: () => redirectRoute({ route: RouteRootId.stats }),
  },
  {
    path: `/:targetSlug/${RouteDynamicRootId.referral}/:referralCode?`,
    loader: ({ params }) => {
      const targetSlug = params.targetSlug as string
      const targetInfo = domainConfig.getTargetBySlug(targetSlug)
      const marketSlug = getDefaultMarketSlug(targetInfo)

      useReferralStore.setState({ externalReferralCode: params.referralCode })

      return redirectRoute({
        route: RouteRootId.trade,
        targetSlug,
        marketSlug,
      })
    },
  },
  {
    path: "/:targetSlug",
    loader: ({ params }) => {
      const targetSlug = params.targetSlug as TargetSlug
      const target = domainConfig.getTargetBySlug(targetSlug)

      if (target === undefined) {
        throw new RouteNotFoundError()
      }

      const marketSlug = getDefaultMarketSlug(target)
      return redirectRoute({
        route: RouteRootId.trade,
        targetSlug: target.slug,
        marketSlug,
      })
    },
  },
  {
    path: `/${RouteRootId.stats}`,
    loader: () => routeJson({ route: RouteRootId.stats }),
    element: <LoadPerpRoute />,
  },
  {
    path: `/${RouteDynamicRootId.referral}/:referralCode?`,
    loader: ({ params }) => {
      const referralCode = params.referralCode

      useReferralStore.setState({ externalReferralCode: referralCode })

      if (referralCode) {
        const { defaultTargetInfo } = domainConfig
        const isDomainMainnet = defaultTargetInfo.category === "mainnet"
        const chainConfigKeys = Object.keys(chainConfigMap) as ChainNetworkId[]

        for (const chainNetworkId of chainConfigKeys) {
          const chainNetworkConfig = chainConfigMap[chainNetworkId]
          const isChainMainnet = chainNetworkConfig.network === "mainnet"

          if (referralCode.startsWith(chainNetworkConfig.addressPrefix)) {
            const isChainAddress = isCosmosAddress(
              referralCode,
              chainNetworkConfig.addressPrefix,
            )

            if (!isChainAddress) {
              break
            }

            if (isDomainMainnet === isChainMainnet) {
              const targetId = chainNetworkIdToTargetId(chainNetworkId)
              const target = domainConfig.getTargetById(targetId)

              return redirectRoute({
                route: RouteRootId.trade,
                targetSlug: target.slug,
                marketSlug: getDefaultMarketSlug(target),
              })
            }
          }
        }
      }

      const target = getDefaultTargetInfo()

      return redirectRoute({
        route: RouteRootId.trade,
        targetSlug: target.slug,
        marketSlug: getDefaultMarketSlug(target),
      })
    },
  },
]

if (!isProduction) {
  routerObjects.push({
    path: "/test-error-page/:type?",
    loader: ({ params }) => {
      throw new TestErrorPage(params.type || "none-specified")
    },
  })
}

const Router = () => {
  const router = Sentry.wrapCreateBrowserRouter(createBrowserRouter)(
    routerObjects.map((routerObject) => {
      return {
        errorElement: (
          <RootLayout>
            {(contextStore) => (
              <RouteProviders>
                <RouteRootError contextStore={contextStore} />
              </RouteProviders>
            )}
          </RootLayout>
        ),
        ...routerObject,
      }
    }),
    {
      basename: process.env.BRAND_ID === "rujira" ? "/perps" : "/",
    },
  )

  return <RouterProvider router={router} />
}

export default Router
