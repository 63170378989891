import { lazyLoad } from "@levana-protocol/utils/lazyLoad"

import type { TargetConfig } from "@future/target/config"
import type { TargetInfo } from "@future/target/info"
import { chainConfigMap } from "@common/target/constants"
import type { ChainConfig } from "@future/libs/chain/chain"
import {
  chainNetworkIdToChainName,
  targetIdToChainNetworkId,
} from "@future/chain/utils"
import type { Chain } from "@future/chain/types"

export const createChain = async (
  targetConfig: TargetConfig,
  targetInfo: TargetInfo,
  rpcEndpointOverride?: string,
): Promise<Chain> => {
  const { factory, explorer, faucet, cw20s } = targetConfig
  const chainNetworkId = targetIdToChainNetworkId(targetInfo.id)
  const chainName = chainNetworkIdToChainName(chainNetworkId)
  const chainNetworkConfig = chainConfigMap[chainNetworkId]

  // TODO: the chain config can be improved to handle more data on init
  const chainConfig: ChainConfig = {
    ...chainNetworkConfig,
    ...(rpcEndpointOverride && {
      rpcUrl: rpcEndpointOverride,
    }),
    chainNetworkId,
    chainName,
    factoryAddress: factory,
    explorerUrl: explorer,
    explorerHashUrl: (hash) => {
      if (explorer) {
        return explorer + hash
      }
    },
  }

  const CosmosClient = await lazyLoad(
    () => import("@perps/sdk/client/CosmosClient"),
  )

  return {
    client: new CosmosClient(chainConfig),
    config: chainConfig,
    faucet,
    cw20s,
  }
}
