import { ErrorBoundary } from "@sentry/react"
import { useTranslation } from "react-i18next"
import { useModal } from "@levana-protocol/utils/modal"
import { useThemeMode } from "@levana-protocol/ui/useThemeMode"
import {
  NotifiCardModal,
  type NotifiCardModalProps,
} from "@notifi-network/notifi-react"

import { Report } from "@future/libs/error/report"
import { AppError } from "@future/libs/error/AppError"

import "@notifi-network/notifi-react/dist/index.css"
import "./notifi.css"

const NotifiCard = () => {
  const mode = useThemeMode()
  const { t } = useTranslation("perpsCommon")
  const { dismiss } = useModal()

  const copy: NotifiCardModalProps["copy"] = {
    Connect: {
      description: t("notifi.content.description"),
      title: t("notifi.content.title"),
    },
    Ftu: {
      FtuTargetEdit: {
        TargetInputs: {
          inputSeparators: {
            email: t("notifi.separator"),
            telegram: t("notifi.separator"),
          },
        },
        description: t("notifi.ftu.description"),
      },
    },
    Inbox: {
      InboxConfigTargetEdit: {
        TargetInputs: {
          inputSeparators: {
            email: t("notifi.separator"),
            telegram: t("notifi.separator"),
          },
        },
      },
    },
  }

  return (
    <ErrorBoundary
      onError={(error) => {
        Report.error(
          AppError.fromError(error, {
            text: "Presenting Notifi",
            level: "fatal",
          }),
        )
      }}
    >
      <div className="notifiCard">
        <NotifiCardModal
          copy={copy}
          darkMode={mode === "dark"}
          globalCtas={{ onClose: dismiss }}
        />
      </div>
    </ErrorBoundary>
  )
}

export default NotifiCard
