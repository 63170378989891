export interface ListOptionsTag<
  Tag extends string,
  Selected extends string | void = void,
> {
  id: Tag
  selected: Selected extends void ? boolean : boolean | Selected
}

export type ListOptionsTags<
  Prefix extends string,
  Tag extends string,
  Selected extends string | void = void,
> = Prefix extends ""
  ? {
      tags: ListOptionsTag<Tag, Selected>[]
      setTags: (tags: (Tag | ListOptionsTag<Tag, Selected>)[]) => void
      selectedTags: (filter?: (selected: Selected) => boolean) => Tag[]
      deselectTag: (selectedTag: Tag) => void
    }
  : {
      [key in `${Prefix}Tags`]: ListOptionsTag<Tag, Selected>[]
    } & {
      [key in `set${Capitalize<Prefix>}Tags`]: (
        tags: (Tag | ListOptionsTag<Tag, Selected>)[],
      ) => void
    } & {
      [key in `${Prefix}SelectedTags`]: (
        filter?: (selected: Selected) => boolean,
      ) => Tag[]
    } & {
      [key in `${Prefix}DeselectedTags`]: (selectedTag: Tag) => void
    }

/**
 * When using with type `Tag[]`, the selected values will all be `false`.
 */
export const formatTags = <
  Tag extends string,
  Selected extends string | void = void,
>(
  tags: (Tag | ListOptionsTag<Tag, Selected>)[],
) => {
  return tags.map((tag) => {
    return typeof tag === "string"
      ? ({ id: tag, selected: false } as ListOptionsTag<Tag, Selected>)
      : tag
  })
}

export const selectedTags = <
  Tag extends string,
  Selected extends string | void = void,
>(
  tags: ListOptionsTag<Tag, Selected | void>[],
  filter?: (selected: Selected) => boolean,
): Tag[] => {
  return tags
    .filter((tag) => {
      return typeof tag.selected === "string" && filter
        ? filter(tag.selected as Selected)
        : tag.selected
    })
    .map((tag) => tag.id)
}

export const deselectTag = <
  Tag extends string,
  Selected extends string | void = void,
>(
  tags: ListOptionsTag<Tag, Selected>[],
  selectedTag: Tag,
): ListOptionsTag<Tag, Selected>[] => {
  return tags.map((tag) => {
    return {
      ...tag,
      ...(tag.id === selectedTag && { selected: false }),
    } as ListOptionsTag<Tag, Selected>
  })
}

export type ListOptionsDropdown<
  Prefix extends string,
  Dropdown extends string,
> = Prefix extends ""
  ? {
      dropdown: Dropdown
      setDropdown: (dropdown: Dropdown) => void
    }
  : {
      [key in `${Prefix}Dropdown`]: Dropdown
    } & {
      [key in `set${Capitalize<Prefix>}Dropdown`]: (dropdown: Dropdown) => void
    }
