var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getNetworkEndpoints } from "@injectivelabs/networks";
import { isInjectiveMainnet, chainIdToInjectiveNetworkWithDefault, } from "./chain";
import { useInjectiveStore } from "./store";
import { getChain } from "../shared/chain";
export const injectiveSigningClient = (chainId) => __awaiter(void 0, void 0, void 0, function* () {
    const { walletStrategy } = useInjectiveStore.getState();
    if (!walletStrategy) {
        throw new Error("Missing WalletStrategy for signing");
    }
    const chain = getChain(chainId);
    const network = chainIdToInjectiveNetworkWithDefault(chainId);
    const { MsgBroadcaster } = yield import("@injectivelabs/wallet-ts");
    const signingClient = new MsgBroadcaster({
        walletStrategy,
        network,
        endpoints: Object.assign(Object.assign(Object.assign({}, getNetworkEndpoints(network)), { rpc: chain.rpc, rest: chain.rest }), (isInjectiveMainnet(network) && {
            // This line is confusing for two reasons:
            //
            // 1. It says grpc, but frontends can't use grpc. Reasoning:
            //    this is actually grpc-web, IMO misnamed in the Injective
            //    libraries.
            //
            // 2. Recently, the default port for grpc-web merged with REST/LCD:
            //    https://hub.injective.network/proposal/420
            grpc: chain.rest,
        })),
    });
    return signingClient;
});
export const injectiveSignAndBroadcast = (account, instructions) => __awaiter(void 0, void 0, void 0, function* () {
    const { MsgExecuteContractCompat } = yield import("@injectivelabs/sdk-ts");
    const msgs = instructions.map((instruction) => {
        return MsgExecuteContractCompat.fromJSON(Object.assign(Object.assign({}, instruction), { sender: account.address }));
    });
    const signingClient = yield injectiveSigningClient(account.chainId);
    const response = yield signingClient.broadcast({
        msgs,
        injectiveAddress: account.address,
    });
    return {
        transactionHash: response.txHash,
    };
});
