import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const PowerIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path d="M10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20ZM10 16C11.6667 16 13.0833 15.4167 14.25 14.25C15.4167 13.0833 16 11.6667 16 10C16 9.15 15.8417 8.3625 15.525 7.6375C15.2083 6.9125 14.7833 6.28333 14.25 5.75L12.825 7.175C13.1917 7.54167 13.4792 7.96667 13.6875 8.45C13.8958 8.93333 14 9.45 14 10C14 11.1 13.6083 12.0417 12.825 12.825C12.0417 13.6083 11.1 14 10 14C8.9 14 7.95833 13.6083 7.175 12.825C6.39167 12.0417 6 11.1 6 10C6 9.45 6.10417 8.93333 6.3125 8.45C6.52083 7.96667 6.80833 7.54167 7.175 7.175L5.75 5.75C5.21667 6.28333 4.79167 6.9125 4.475 7.6375C4.15833 8.3625 4 9.15 4 10C4 11.6667 4.58333 13.0833 5.75 14.25C6.91667 15.4167 8.33333 16 10 16ZM9 10H11V4H9V10Z" />
    </SvgIcon>
  )
}

export default PowerIcon
