// exported functions, code should import this module and call
// these functions like analytics.foo()

import type { MarketId } from "@perps/sdk/types"

import type {
  ClosePositionData,
  DepositLiquidityData,
  ErrorData,
  EventWithData,
  ExtendedLimitOrderData,
  ExtendedPositionData,
  PageViewData,
  PnlCelebrationShareData,
  PnlCelebrationViewData,
  PositionDirection,
  SelectStatsNetworkData,
  SelectStatsMarketData,
  SelectNetworkData,
  Sign,
} from "./eventData"
import { EventKey, EventName, EventValue } from "./constants"

// All methods on this class are static

// biome-ignore lint/complexity/noStaticOnlyClass: by design
export class Event {
  static networksModalViewed = (): EventWithData => ({
    eventName: EventName.VIEW_NETWORKS,
    data: undefined,
  })

  static networkSelected = (data: SelectNetworkData): EventWithData => ({
    eventName: EventName.SELECT_NETWORK,
    data,
  })

  static pageViewed = (data: PageViewData): EventWithData => ({
    eventName: EventName.VIEW_PAGE,
    data,
  })

  static statsNetworkSelected = (
    data: SelectStatsNetworkData,
  ): EventWithData => ({
    eventName: EventName.SELECT_STATS_NETWORK,
    data,
  })

  static statsMarketSelected = (
    data: SelectStatsMarketData,
  ): EventWithData => ({
    eventName: EventName.SELECT_STATS_MARKET,
    data,
  })

  static pnlCelebrationViewed = (
    data: PnlCelebrationViewData,
  ): EventWithData => ({
    eventName: EventName.VIEW_PNL_CELEBRATION,
    data,
  })

  static pnlCelebrationShared = (
    data: PnlCelebrationShareData,
  ): EventWithData => ({
    eventName: EventName.SHARE_PNL_CELEBRATION,
    data,
  })

  static tosViewed = (): EventWithData => ({
    eventName: EventName.VIEW_TOS_MODAL,
    data: undefined,
  })

  static tosApproved = (): EventWithData => ({
    eventName: EventName.APPROVE_TOS_MODAL,
    data: undefined,
  })

  static errorOccurred = (props: ErrorData): EventWithData => ({
    eventName: EventName.ERROR_OCCURRED,
    data: props,
  })

  static takeProfitExplainerViewed = (): EventWithData => ({
    eventName: EventName.VIEW_TAKE_PROFIT_EXPLAINER,
    data: undefined,
  })

  static stopLossExplainerViewed = (): EventWithData => ({
    eventName: EventName.VIEW_STOP_LOSS_EXPLAINER,
    data: undefined,
  })

  static estimatedPnlExplainerViewed = (): EventWithData => ({
    eventName: EventName.VIEW_ESTIMATED_PNL_EXPLAINER,
    data: undefined,
  })

  static connectWallet = dataWithResultBuilder<{ wallet: string }, object>(
    (result, props) => ({
      eventName: EventName.CONNECT_WALLET,
      data: {
        [EventKey.RESULT]: resultValue(result),
        [EventKey.WALLET]: "wallet" in props ? props.wallet : undefined,
      },
    }),
  )

  static tapFaucet = dataWithResultBuilder<object>((result) => ({
    eventName: EventName.TAP_FAUCET,
    data: {
      [EventKey.RESULT]: resultValue(result),
    },
  }))

  static depositLiquidity = dataWithResultBuilder<DepositLiquidityData>(
    (result, props) => ({
      eventName: EventName.DEPOSIT_LIQUIDITY,
      data: {
        ...props,
        [EventKey.RESULT]: resultValue(result),
      },
    }),
  )

  static openPosition = dataWithResultBuilder<ExtendedPositionData>(
    (result, props) => ({
      eventName: EventName.OPEN_POSITION,
      data: {
        ...props,
        [EventKey.RESULT]: resultValue(result),
      },
    }),
  )

  static updatePosition = dataWithResultBuilder<ExtendedPositionData>(
    (result, props) => ({
      eventName: EventName.UPDATE_POSITION,
      data: {
        ...props,
        [EventKey.RESULT]: resultValue(result),
      },
    }),
  )

  static closePosition = dataWithResultBuilder<ClosePositionData>(
    (result, props) => ({
      eventName: EventName.CLOSE_POSITION,
      data: {
        ...props,
        [EventKey.RESULT]: resultValue(result),
      },
    }),
  )

  static openLimitOrder = dataWithResultBuilder<ExtendedLimitOrderData>(
    (result, props) => ({
      eventName: EventName.OPEN_LIMIT_ORDER,
      data: {
        ...props,
        [EventKey.RESULT]: resultValue(result),
      },
    }),
  )

  static cancelLimitOrder = dataWithResultBuilder<ExtendedLimitOrderData>(
    (result, props) => ({
      eventName: EventName.CANCEL_LIMIT_ORDER,
      data: {
        ...props,
        [EventKey.RESULT]: resultValue(result),
      },
    }),
  )

  static initiateOpenPosition = ({
    market,
    direction,
  }: { market: string; direction: PositionDirection }): EventWithData => ({
    eventName: EventName.INITIATE_OPEN_POSITION,
    data: {
      [EventKey.MARKET]: market,
      [EventKey.DIRECTION]: direction,
    },
  })

  static initiateUpdatePosition = ({
    market,
    direction,
  }: { market: string; direction: PositionDirection }): EventWithData => ({
    eventName: EventName.INITIATE_UPDATE_POSITION,
    data: {
      [EventKey.MARKET]: market,
      [EventKey.DIRECTION]: direction,
    },
  })

  static initiateClosePosition = ({
    market,
    direction,
    source,
  }: {
    market: string
    direction: PositionDirection
    source: "positions_table" | "update_modal"
  }): EventWithData => ({
    eventName: EventName.INITIATE_CLOSE_POSITION,
    data: {
      [EventKey.MARKET]: market,
      [EventKey.DIRECTION]: direction,
      [EventKey.SOURCE]: source,
    },
  })

  static initiateOpenLimitOrder = ({
    market,
    direction,
  }: { market: string; direction: PositionDirection }): EventWithData => ({
    eventName: EventName.INITIATE_OPEN_LIMIT_ORDER,
    data: {
      [EventKey.MARKET]: market,
      [EventKey.DIRECTION]: direction,
    },
  })

  static initiateCancelLimitOrder = ({
    market,
    direction,
  }: { market: string; direction: PositionDirection }): EventWithData => ({
    eventName: EventName.INITIATE_CANCEL_LIMIT_ORDER,
    data: {
      [EventKey.MARKET]: market,
      [EventKey.DIRECTION]: direction,
    },
  })

  static changeCollateralInputCurrency = (): EventWithData => ({
    eventName: EventName.CHANGE_COLLATERAL_INPUT_CURRENCY,
    data: undefined,
  })

  static maxCollateral = (): EventWithData => ({
    eventName: EventName.USE_MAX_COLLATERAL,
    data: undefined,
  })

  static selectOrderType = (orderType: "market" | "limit"): EventWithData => ({
    eventName: EventName.SELECT_ORDER_TYPE,
    data: {
      order_type: orderType,
    },
  })

  static selectDirection = (direction: PositionDirection): EventWithData => ({
    eventName: EventName.SELECT_DIRECTION,
    data: {
      direction,
    },
  })

  static openWalletMenu = (): EventWithData => ({
    eventName: EventName.OPEN_WALLET_MENU,
    data: undefined,
  })

  static copyWalletAddress = (): EventWithData => ({
    eventName: EventName.COPY_ADDRESS,
    data: undefined,
  })

  static disconnectWallet = (): EventWithData => ({
    eventName: EventName.DISCONNECT_WALLET,
    data: undefined,
  })

  static initiateTapFaucet = (): EventWithData => ({
    eventName: EventName.INITIATE_TAP_FAUCET,
    data: undefined,
  })

  static openLevanaAppsMenu = (): EventWithData => ({
    eventName: EventName.OPEN_LEVANA_APPS_MENU,
    data: undefined,
  })

  static openChooseChainMenu = (): EventWithData => ({
    eventName: EventName.OPEN_CHOOSE_CHAIN_MENU,
    data: undefined,
  })

  static openMarketsMenu = (): EventWithData => ({
    eventName: EventName.OPEN_MARKETS_MENU,
    data: undefined,
  })

  static openMoreMenu = (): EventWithData => ({
    eventName: EventName.OPEN_MORE_MENU,
    data: undefined,
  })

  static openAllMenu = (): EventWithData => ({
    eventName: EventName.OPEN_ALL_MENU,
    data: undefined,
  })

  static viewSettings = (): EventWithData => ({
    eventName: EventName.VIEW_SETTINGS,
    data: undefined,
  })

  static changeDisplayMode = (
    displayMode: "light" | "dark" | "system",
  ): EventWithData => ({
    eventName: EventName.CHANGE_DISPLAY_MODE,
    data: {
      display_mode: displayMode,
    },
  })

  static adjustMaxSlippage = (amount: number): EventWithData => ({
    eventName: EventName.ADJUST_MAX_SLIPPAGE,
    data: {
      [EventKey.SLIPPAGE_AMOUNT]: amount,
    },
  })

  static viewPage: {
    (page: "trade" | "earn" | "leaderboard", marketId: MarketId): EventWithData
    (page: "markets" | "history" | "learn" | "stats"): EventWithData
  } = (
    page:
      | "markets"
      | "trade"
      | "earn"
      | "leaderboard"
      | "history"
      | "learn"
      | "stats",
    marketId?: MarketId,
  ): EventWithData => {
    switch (page) {
      case "markets":
        return {
          eventName: EventName.VIEW_MARKETS_PAGE,
          data: undefined,
        }
      case "trade":
        if (!marketId) {
          throw new Error("No market ID")
        }
        return {
          eventName: EventName.VIEW_TRADE_PAGE,
          data: { [EventKey.MARKET]: marketId },
        }
      case "earn":
        if (!marketId) {
          throw new Error("No market ID")
        }
        return {
          eventName: EventName.VIEW_EARN_PAGE,
          data: { [EventKey.MARKET]: marketId },
        }
      case "leaderboard":
        if (!marketId) {
          throw new Error("No market ID")
        }
        return {
          eventName: EventName.VIEW_LEADERBOARD_PAGE,
          data: { [EventKey.MARKET]: marketId },
        }
      case "history":
        return {
          eventName: EventName.VIEW_HISTORY_PAGE,
          data: undefined,
        }
      case "learn":
        return {
          eventName: EventName.VIEW_LEARN_PAGE,
          data: undefined,
        }
      case "stats":
        return {
          eventName: EventName.VIEW_STATS_PAGE,
          data: undefined,
        }
    }
  }

  static initiateDepositLiquidity = ({
    marketId,
    apr,
    source,
  }: {
    marketId: MarketId
    apr: number
    source: "pool_page" | "earn_page"
  }): EventWithData => ({
    eventName: EventName.INITIATE_DEPOSIT_LIQUIDITY,
    data: {
      [EventKey.MARKET]: marketId,
      [EventKey.APR]: apr,
      [EventKey.SOURCE]: source,
    },
  })

  static initiateReinvestYield = (marketId: MarketId): EventWithData => ({
    eventName: EventName.INITIATE_REINVEST_YIELD,
    data: {
      [EventKey.MARKET]: marketId,
    },
  })

  static reinvestYield = dataWithResultBuilder<{
    marketId: MarketId
    usdAmount: number
  }>((result, props) => ({
    eventName: EventName.REINVEST_YIELD,
    data: {
      [EventKey.MARKET]: props.marketId,
      [EventKey.AMOUNT]: props.usdAmount,
      [EventKey.RESULT]: resultValue(result),
    },
  }))

  static initiateWithdrawLiquidity = (marketId: MarketId): EventWithData => ({
    eventName: EventName.INITIATE_WITHDRAW_LIQUIDITY,
    data: {
      [EventKey.MARKET]: marketId,
    },
  })

  static withdrawLiquidity = dataWithResultBuilder<{
    marketId: MarketId
    usdAmount: number
  }>((result, props) => ({
    eventName: EventName.WITHDRAW_LIQUIDITY,
    data: {
      [EventKey.MARKET]: props.marketId,
      [EventKey.AMOUNT]: props.usdAmount,
      [EventKey.RESULT]: resultValue(result),
    },
  }))

  static withdrawYield = dataWithResultBuilder<{
    marketId: MarketId
    usdAmount: number
  }>((result, props) => ({
    eventName: EventName.WITHDRAW_YIELD,
    data: {
      [EventKey.MARKET]: props.marketId,
      [EventKey.AMOUNT]: props.usdAmount,
      [EventKey.RESULT]: resultValue(result),
    },
  }))

  static convertXlpToLp = dataWithResultBuilder<{
    marketId: MarketId
    amount: number
  }>((result, props) => ({
    eventName: EventName.CONVERT_XLP_TO_LP,
    data: {
      [EventKey.MARKET]: props.marketId,
      [EventKey.AMOUNT]: props.amount,
      [EventKey.RESULT]: resultValue(result),
    },
  }))

  static viewTradeHistoryDetails = (): EventWithData => ({
    eventName: EventName.VIEW_TRADE_HISTORY_DETAILS,
    data: undefined,
  })

  static clickAprPromoButton = ({
    marketId,
    apr,
  }: {
    marketId: MarketId
    apr: string
  }): EventWithData => ({
    eventName: EventName.CLICK_APR_PROMO_BUTTON,
    data: {
      [EventKey.MARKET]: marketId,
      [EventKey.APR]: apr,
    },
  })

  static geoblocked = ({
    countryCode,
  }: { countryCode?: string }): EventWithData => ({
    eventName: EventName.GEOBLOCKED,
    data: {
      [EventKey.COUNTRY_CODE]: countryCode,
    },
  })

  static openedBridge = (marketId?: MarketId): EventWithData => ({
    eventName: EventName.OPENED_BRIDGE,
    data: {
      [EventKey.MARKET]: marketId,
    },
  })
}

function dataWithResultBuilder<Props, FailProps = Props>(
  tracker: (result: boolean, props: Props | FailProps) => EventWithData,
) {
  return {
    succeeded: (props: Props) => tracker(true, props),
    failed: (props: FailProps, failureReason: string | undefined) =>
      tracker(false, {
        ...props,
        [EventKey.FAILURE_REASON]: failureReason,
      }),
  }
}

export const sign = (amount: number): Sign => {
  if (amount <= 0) {
    return "negative"
  } else {
    return "positive"
  }
}

const resultValue = (result: boolean): string => {
  return result ? EventValue.SUCCESS : EventValue.FAILURE
}
