import Box from "@mui/joy/Box"
import IconButton, { iconButtonClasses } from "@mui/joy/IconButton"
import MenuButton from "@mui/joy/MenuButton"
import { useStore } from "zustand"

import {
  isStandardContextStore,
  type ContextStoreProp,
} from "@future/context/store"
import { useChooseChainNavItems } from "@future/chain/chooseChainNavItems"
import { Event } from "@perps/analytics/events"
import { track } from "@perps/analytics/track"

import HeaderLogo from "./HeaderLogo"
import { useLevanaAppsNavItems } from "./navItems"
import AccountButton from "./AccountButton"
import HeaderNav from "./HeaderNav"
import HeaderNavDropdown from "./HeaderNavDropdown"
import AppsIcon from "./icons/AppsIcon"
import HeaderOtherActionsList from "./HeaderOtherActionsList"

interface HeaderProps extends ContextStoreProp<"initial"> {
  includeAccount: boolean
}

const Header = (props: HeaderProps) => {
  return (
    <Box
      sx={{
        px: { xs: 2, sm: 3 },
        pt: { xs: 3, sm: 4 },
        pb: { xs: 6, sm: 8 },
      }}
    >
      <Box
        component="nav"
        sx={({ spacing }) => ({
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
          "--Button-radius": spacing(3),
          "--Button-paddingBlock": "0",
          "--IconButton-radius": spacing(3),
          [`.${iconButtonClasses.root} > *`]: {
            maxWidth: spacing(4),
            maxHeight: spacing(4),
          },
        })}
      >
        <HeaderLogo contextStore={props.contextStore} />
        <HeaderNav contextStore={props.contextStore} />
        <HeaderAppsButton contextStore={props.contextStore} />
        <HeaderOtherActionsList contextStore={props.contextStore} />

        {isStandardContextStore(props.contextStore) && props.includeAccount && (
          <AccountButton contextStore={props.contextStore} />
        )}

        <HeaderChooseChainButton contextStore={props.contextStore} />
      </Box>
    </Box>
  )
}

const HeaderAppsButton = (props: ContextStoreProp<"initial">) => {
  const navItems = useLevanaAppsNavItems(props)

  return (
    <HeaderNavDropdown
      subitems={navItems}
      placement="bottom-end"
      button={
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{
            root: {
              variant: "outlined",
            },
          }}
          aria-label="levana apps"
        >
          <AppsIcon />
        </MenuButton>
      }
      onOpenChange={(_event, open) => {
        if (open) {
          track(Event.openLevanaAppsMenu())
        }
      }}
    />
  )
}

const HeaderChooseChainButton = (props: ContextStoreProp<"initial">) => {
  const navItems = useChooseChainNavItems(props)
  const targetSlug = useStore(
    props.contextStore,
    (state) => state.targetInfo.slug,
  )
  const ChainIcon = useStore(
    props.contextStore,
    (state) => state.targetInfo.icon,
  )
  const chainIconBackgroundColor = useStore(
    props.contextStore,
    (state) => state.targetInfo.iconBackgroundColor,
  )
  const routeId = useStore(props.contextStore, (state) => state.route.rootId)

  if (routeId === "stats") {
    return
  }

  return (
    <HeaderNavDropdown
      subitems={navItems}
      placement="bottom-end"
      selectedId={targetSlug}
      button={
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{
            root: {
              variant: "outlined",
              sx: {
                "--Icon-fontSize": "36px",
                backgroundColor: chainIconBackgroundColor,
                paddingInline: 0,
              },
            },
          }}
          aria-label="choose chain"
        >
          <ChainIcon />
        </MenuButton>
      }
      onOpenChange={(_event, open) => {
        if (open) {
          track(Event.openChooseChainMenu())
        }
      }}
    />
  )
}

export default Header
