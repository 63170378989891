export var WalletType;
(function (WalletType) {
    WalletType["compass"] = "compass";
    WalletType["cosmostation"] = "cosmostation";
    WalletType["cosmostationMobile"] = "cosmostationMobile";
    WalletType["keplr"] = "keplr";
    WalletType["keplrMobile"] = "keplrMobile";
    WalletType["leap"] = "leap";
    WalletType["leapMobile"] = "leapMobile";
    WalletType["metamask"] = "metamask";
    WalletType["okx"] = "okx";
    WalletType["walletConnect"] = "walletConnect";
    WalletType["viewing"] = "viewing";
    WalletType["xdefi"] = "xdefi";
})(WalletType || (WalletType = {}));
