var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useQuery } from "@tanstack/react-query";
import { getWallet } from "../wallet";
import { useAccount, viewingConnectedAgent } from "./account";
import { decideChainAction } from "./chain";
import { WalletType } from "../shared/wallet";
import { useChainStore } from "../shared/store";
import { isCosmosAccount, isEthereumAccount, } from "../shared/account";
import { cosmosConnectedAgent } from "../cosmos/account";
import { injectiveConnectedAgent } from "../injective/account";
export { getWallet } from "../wallet";
export { WalletType } from "../shared/wallet";
/**
 * Preferred Wallet Order
 *
 * Use a record to obtain type exhausting
 */
const preferredWalletOrder = {
    [WalletType.metamask]: 0,
    [WalletType.leap]: 1,
    [WalletType.keplr]: 2,
    [WalletType.xdefi]: 3,
    [WalletType.cosmostation]: 4,
    [WalletType.okx]: 5,
    [WalletType.compass]: 6,
    [WalletType.viewing]: 7,
    [WalletType.walletConnect]: 8,
    [WalletType.leapMobile]: 9,
    [WalletType.keplrMobile]: 10,
    [WalletType.cosmostationMobile]: 11,
};
export const getSupportedWallets = () => {
    const availableWallets = [];
    const walletTypes = Object.entries(preferredWalletOrder).sort(([, a], [, b]) => a - b);
    const { chainId } = useChainStore.getState();
    for (const [walletType] of walletTypes) {
        if (walletType === WalletType.walletConnect ||
            walletType === WalletType.leapMobile ||
            walletType === WalletType.keplrMobile ||
            walletType === WalletType.cosmostationMobile) {
            // currently not supported
            continue;
        }
        const walletInfo = getWallet(walletType).info;
        if (walletInfo.isChainSupported(chainId)) {
            availableWallets.push(walletType);
        }
    }
    return availableWallets;
};
export const isMobileWallet = (walletType) => {
    switch (walletType) {
        case WalletType.compass:
        case WalletType.cosmostation:
        case WalletType.keplr:
        case WalletType.leap:
        case WalletType.metamask:
        case WalletType.okx:
        case WalletType.viewing:
        case WalletType.xdefi:
            return false;
        case WalletType.cosmostationMobile:
        case WalletType.keplrMobile:
        case WalletType.leapMobile:
        case WalletType.walletConnect:
            return true;
    }
};
// TODO: move to account. rename to useConnectedAgent
export const useConnectedWallet = () => {
    const chainId = useChainStore((state) => state.chainId);
    const rpc = useChainStore((state) => { var _a; return (_a = state.chains.find((chain) => chain.chainId === chainId)) === null || _a === void 0 ? void 0 : _a.rpc; });
    const { data: account } = useAccount();
    return useQuery({
        queryKey: ["useConnectedWallet", chainId, account, rpc],
        enabled: account !== undefined && chainId.length > 0,
        queryFn: () => __awaiter(void 0, void 0, void 0, function* () {
            if (account && chainId) {
                if (account.walletType === WalletType.viewing) {
                    return viewingConnectedAgent(account);
                }
                const connectedAgent = decideChainAction({
                    chainId,
                    cosmos: () => {
                        if (isCosmosAccount(account)) {
                            return cosmosConnectedAgent(account);
                        }
                    },
                    injective: () => {
                        if (isCosmosAccount(account) || isEthereumAccount(account)) {
                            return injectiveConnectedAgent(account);
                        }
                    },
                })();
                if (connectedAgent) {
                    return connectedAgent;
                }
                throw new Error(`Unhandled connectedAgent with walletType: ${account.walletType} and chainId: ${account.chainId}`);
            }
        }),
    });
};
