import { Popper } from "@mui/base/Popper"
import Box from "@mui/joy/Box"
import IconButton from "@mui/joy/IconButton"
import { useTheme } from "@mui/joy/styles"
import type { BasePlacement } from "@popperjs/core"
import type { PropsWithChildren } from "react"

import XIcon from "@common/icons/XIcon"

const borderWidth = 2
const arrowWidth = 16
const dialogRadius = 16

export interface SpotlightDialogProps extends PropsWithChildren {
  anchorRef: React.MutableRefObject<HTMLElement | undefined>
  anchorPlacement: BasePlacement
  onClose?: React.MouseEventHandler<HTMLAnchorElement>
}

const SpotlightDialog = (props: SpotlightDialogProps) => {
  const { anchorRef, anchorPlacement, onClose, children } = props
  const theme = useTheme()

  return (
    <Popper
      open
      anchorEl={anchorRef.current}
      placement={anchorPlacement}
      slotProps={{
        root: {
          style: {
            zIndex: theme.vars.zIndex.tooltip,
          },
        },
      }}
    >
      <Box
        sx={({ vars }) => ({
          "--tooltip-borderWidth": `${borderWidth}px`,
          "--tooltip-arrowWidth": `${arrowWidth}px`,
          "--tooltip-backgroundColor": vars.palette.background.spotlight1,
          "--tooltip-borderColor": vars.palette.background.spotlight2,
          position: "relative",
          bgcolor: "var(--tooltip-backgroundColor)",
          border: "var(--tooltip-borderWidth) solid var(--tooltip-borderColor)",
          borderRadius: dialogRadius,
          py: { xs: 1, sm: 2 },
          px: { xs: 1, sm: 2, md: 3 },
          width: "min(360px, calc(100vw - 3rem))",
          m: 2.5,
        })}
      >
        {onClose && (
          <IconButton
            variant="plain"
            size="sm"
            color="neutral"
            onClick={onClose}
            sx={{
              float: "right",
              mt: { xs: -1, sm: -2 },
              mr: { xs: -1, sm: -2, md: -3 },
              color: "var(--palette-text-tertiary)",
              borderRadius: "var(--radius-lg)",
              "&:hover, &:active": {
                bgcolor: "unset",
              },
            }}
            aria-label="close spotlight"
          >
            <XIcon fontSize="xs" />
          </IconButton>
        )}
        {children}
        <Box
          data-popper-arrow
          sx={{
            zIndex: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            pointerEvents: "none",

            "[data-popper-placement^='top'] &, [data-popper-placement^='bottom'] &":
              {
                width: arrowWidth * 2,
                height: arrowWidth,
              },
            "[data-popper-placement^='left'] &, [data-popper-placement^='right'] &":
              {
                width: arrowWidth,
                height: arrowWidth * 2,
                paddingY: `${arrowWidth * 2}px`,
              },

            "[data-popper-placement^='top'] &": {
              bottom: -arrowWidth,
              flexDirection: "column",
            },
            "[data-popper-placement^='left'] &": {
              right: -arrowWidth,
              flexDirection: "row",
            },
            "[data-popper-placement^='bottom'] &": {
              top: -arrowWidth,
              flexDirection: "column-reverse",
            },
            "[data-popper-placement^='right'] &": {
              left: -arrowWidth,
              flexDirection: "row-reverse",
            },

            "&[data-hide]": {
              visibility: "hidden",
            },

            "&:before, &:after": {
              border: "solid transparent",
              content: "''",
              height: 0,
              width: 0,
              position: "absolute",
            },

            "&:before": {
              borderWidth: "var(--tooltip-arrowWidth)",

              "[data-popper-placement^='top'] &": {
                borderTopColor: "var(--tooltip-borderColor)",
              },
              "[data-popper-placement^='left'] &": {
                borderLeftColor: "var(--tooltip-borderColor)",
              },
              "[data-popper-placement^='bottom'] &": {
                borderBottomColor: "var(--tooltip-borderColor)",
              },
              "[data-popper-placement^='right'] &": {
                borderRightColor: "var(--tooltip-borderColor)",
              },
            },

            "&:after": {
              borderWidth:
                "calc(var(--tooltip-arrowWidth) - (var(--tooltip-borderWidth) * 1.5))",

              "[data-popper-placement^='top'] &": {
                borderTopColor: "var(--tooltip-backgroundColor)",
              },
              "[data-popper-placement^='left'] &": {
                borderLeftColor: "var(--tooltip-backgroundColor)",
              },
              "[data-popper-placement^='bottom'] &": {
                borderBottomColor: "var(--tooltip-backgroundColor)",
              },
              "[data-popper-placement^='right'] &": {
                borderRightColor: "var(--tooltip-backgroundColor)",
              },
            },
          }}
        />
      </Box>
    </Popper>
  )
}

export default SpotlightDialog
