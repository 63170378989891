import Button from "@mui/joy/Button"
import { useTheme } from "@mui/joy/styles"
import useMediaQuery from "@mui/system/useMediaQuery"
import { useStore } from "zustand"
import { useTranslation } from "react-i18next"
import { useConnectedWallet } from "@levana/chain/wallet"

import {
  isStandardContextStore,
  type ContextStoreProp,
} from "@future/context/store"
import { useEarnMarketsQuery } from "@future/network/earnMarkets"
import { Event } from "@perps/analytics/events"
import { track } from "@perps/analytics/track"
import { useNavigate } from "@future/router/useNavigate"
import { RouteRootId } from "@future/router/types"
import ChevronRightRoundedIcon from "@common/icons/ChevronRightRoundedIcon"
import HeaderDroplets from "@perps/header/HeaderDroplets"

const HeaderOtherActionsList = (props: ContextStoreProp<"initial">) => {
  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"))

  if (!isStandardContextStore(props.contextStore) || !isLargeScreen) {
    return null
  }

  return (
    <>
      <AprPromoButton contextStore={props.contextStore} />
      <NeutronDroplets contextStore={props.contextStore} />
    </>
  )
}

const AprPromoButton = (props: ContextStoreProp<"standard">) => {
  const { t } = useTranslation("perpsCommon")
  const routerNavigate = useNavigate()

  const targetSlug = useStore(
    props.contextStore,
    (state) => state.route.targetSlug,
  )
  const market = useStore(
    props.contextStore,
    (state) => state.fullMarketInfo.config,
  )

  const earnMarketsResult = useEarnMarketsQuery(props)

  const aprsData = earnMarketsResult.data
    ? earnMarketsResult.data.get(market.id)?.chartMonthly.aprWeekly
    : undefined
  const lastXlpApr =
    aprsData && aprsData.length !== 0
      ? aprsData[aprsData.length - 1].xlp
      : undefined
  const aprPercent = lastXlpApr?.toAsset()

  if (!aprPercent) {
    return null
  }

  const handleClick = () => {
    track(
      Event.clickAprPromoButton({
        marketId: market.id,
        apr: aprPercent,
      }),
    )
    routerNavigate({
      route: RouteRootId.earn,
      marketSlug: market.slug,
      targetSlug,
    })
  }

  return (
    <Button
      onClick={handleClick}
      sx={(theme) => ({
        "--Button-gap": "0",
        background: "linear-gradient(90deg, #CB91FF -1.61%, #9391FF 100%)",
        paddingInline: 1,
        fontSize: theme.vars.fontSize.xs,
      })}
      endDecorator={<ChevronRightRoundedIcon />}
      aria-label="header apr promo"
    >
      {t("nav.aprPromo", {
        apr: aprPercent,
        baseId: market.base,
      })}
    </Button>
  )
}

const NeutronDroplets = (props: ContextStoreProp<"standard">) => {
  const { data: connectedWallet } = useConnectedWallet()

  if (!connectedWallet) {
    return null
  }

  return (
    <HeaderDroplets
      connectedAgent={connectedWallet}
      contextStore={props.contextStore}
    />
  )
}

export default HeaderOtherActionsList
