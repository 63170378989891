import Button from "@mui/joy/Button"
import { useTranslation } from "react-i18next"
import { useCallback } from "react"
import { useConnect } from "@levana/chain/account"
import { WalletType } from "@levana/chain/wallet"
import { isKeplrBrowser, isLeapBrowser } from "@levana/chain/utils"
import { useModal } from "@levana-protocol/utils/modal"

import ConnectModal from "@future/connect/ConnectModal"

const ConnectButton = () => {
  const { handleClick } = useConnectButton()
  const { t } = useTranslation("common")

  return (
    <Button variant="solid" onClick={handleClick} aria-label="connect">
      {t("wallet.connect.title")}
    </Button>
  )
}

export const useConnectButton = () => {
  const { present } = useModal()
  const { connect } = useConnect()

  const handleClick = useCallback(() => {
    // Some wallets try to provide a simple transition from Keplr, such as Leap.
    // This means the those wallets might add the Keplr user agent.
    // Make sure Keplr is at the end of the conditions.
    if (isLeapBrowser) {
      connect({ walletType: WalletType.leap })
    } else if (isKeplrBrowser) {
      connect({ walletType: WalletType.keplr })
    } else {
      present(ConnectModal.name)
    }
  }, [connect, present])

  return {
    handleClick,
  }
}

export default ConnectButton
