import MenuButton from "@mui/joy/MenuButton"
import ListItemButton from "@mui/joy/ListItemButton"
import type { DropdownProps } from "@mui/joy/Dropdown"

import type { ParentNavItem } from "./navItems"
import HeaderNavDropdown from "./HeaderNavDropdown"
import NavItemContent from "./NavItemContent"

interface NavParentButtonProps
  extends Pick<DropdownProps, "open" | "onOpenChange"> {
  navItem: ParentNavItem
  selected?: boolean
  zIndex?: number
}

const NavParentButton = (props: NavParentButtonProps) => {
  const { navItem } = props

  return (
    <HeaderNavDropdown
      subitems={navItem.subitems}
      open={props.open}
      onOpenChange={props.onOpenChange}
      zIndex={props.zIndex}
      button={
        <MenuButton
          slots={{ root: ListItemButton }}
          slotProps={{
            root: {
              color: props.selected ? "primary" : navItem.color,
              sx: {
                ...(props.open &&
                  props.zIndex !== undefined && { zIndex: props.zIndex }),
              },
            },
          }}
          aria-label={`nav ${navItem.title.toLowerCase()}`}
        >
          <NavItemContent navItem={navItem} />
        </MenuButton>
      }
    />
  )
}

export default NavParentButton
