import type {
  ExecuteRouteOptions,
  RouteRequestBase,
  SkipClientOptions,
  SkipClient,
  EndpointOptions,
} from "@skip-go/client"
import { lazyLoadStore } from "@levana-protocol/utils/lazyLoad"
import { getWallet } from "@levana/chain/wallet"
import { getAccount } from "@levana/chain/account"

import { AppError } from "@future/libs/error/AppError"
import { chainConfigMap } from "@common/target/constants"

export type SkipRoutePath = Pick<
  RouteRequestBase,
  | "sourceAssetDenom"
  | "sourceAssetChainID"
  | "destAssetDenom"
  | "destAssetChainID"
>

const basisPointsFee = "100" // 1%

export const useSkip = lazyLoadStore(async () => {
  const Core = await import("@skip-go/client")
  const chainConfigs = Object.values(chainConfigMap)

  const client = new Core.SkipClient({
    // TODO: the key can be enabled once skip fixes the client issue
    // apiKey: process.env.SKIP_API_KEY,
    endpointOptions: {
      endpoints: chainConfigs.reduce(
        (accumulator, chainConfig) => {
          accumulator[chainConfig.chainId] = {
            rpc: chainConfig.rpcUrl,
            rest: chainConfig.restUrl,
          }
          return accumulator
        },
        {} as Record<string, EndpointOptions>,
      ),
    },
    getCosmosSigner: async (chainId) => {
      const account = getAccount()

      if (!account) {
        throw new Error("Missing account")
      }

      const wallet = getWallet(account.walletType)
      const getOfflineSigner = wallet?.provider().getOfflineSigner

      if (!getOfflineSigner) {
        throw new Error(
          `The Skip client is trying to sign with an unsupported wallet '${wallet.info.name}'`,
        )
      }

      return getOfflineSigner(chainId)
    },
    // TODO: implement the evm signer
    // getEVMSigner: async (chainId) => {
    // },
    // https://docs.skip.build/go/widget/configuration-options#chainidstoaffiliates
    chainIDsToAffiliates: chainConfigs.reduce(
      (accumulator, chainConfig) => {
        if (
          chainConfig.network === "mainnet" &&
          chainConfig.affiliateFeeAddress
        ) {
          accumulator[chainConfig.chainId] = {
            affiliates: [
              {
                basisPointsFee,
                address: chainConfig.affiliateFeeAddress,
              },
            ],
          }
        }
        return accumulator
      },
      {} as NonNullable<SkipClientOptions["chainIDsToAffiliates"]>,
    ),
  })

  return new Skip(client)
})

class Skip {
  client: SkipClient

  constructor(client: SkipClient) {
    this.client = client
  }

  async route(
    path: SkipRoutePath & ({ amountIn: string } | { amountOut: string }),
  ) {
    return this.client.route({
      ...path,
      smartSwapOptions: {
        splitRoutes: true,
      },
      cumulativeAffiliateFeeBPS: basisPointsFee,
    })
  }

  async executeRoute(
    args: Omit<ExecuteRouteOptions, "userAddresses"> & {
      getWalletAddress: (chainId: string) => Promise<string | undefined>
    },
  ) {
    const { getWalletAddress, ...executeRouteArgs } = args

    const userAddresses = await Promise.all(
      args.route.requiredChainAddresses.map(async (chainId) => {
        const walletAddress = await getWalletAddress(chainId)

        if (!walletAddress) {
          throw AppError.fromText("Unable to get wallet address for Skip route")
        }

        return {
          chainID: chainId,
          address: walletAddress,
        }
      }),
    )

    return this.client.executeRoute({
      ...executeRouteArgs,
      userAddresses,
    })
  }
}
