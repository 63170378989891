import { create } from "zustand";
import { subscribeWithSelector, persist, } from "zustand/middleware";
import { WalletType } from "./wallet";
export const useChainStore = create(() => ({
    chainId: "",
    chains: [],
    chainsConfig: {},
    options: {},
}));
const accountStoreOptions = {
    name: "AccountStore",
    version: 2,
    partialize: (state) => ({
        accounts: state.accounts,
    }),
    migrate: (persistedState, version) => {
        if (version === 1) {
            return { accounts: [] };
        }
        return persistedState;
    },
};
export const useAccountStore = create(subscribeWithSelector(persist((set, get) => {
    return {
        accounts: [],
        status: "disconnected",
        addAccount: (newAccount) => {
            const accounts = [...get().accounts];
            let removeAccountIndex = -1;
            for (let index = 0; index < accounts.length; index += 1) {
                const account = accounts[index];
                if (newAccount.walletType === WalletType.viewing) {
                    if (account.address === newAccount.address) {
                        removeAccountIndex = index;
                        break;
                    }
                }
                else {
                    if (account.chainId === newAccount.chainId &&
                        account.walletType !== WalletType.viewing) {
                        removeAccountIndex = index;
                    }
                }
            }
            if (removeAccountIndex > -1) {
                accounts.splice(removeAccountIndex, 1);
            }
            accounts.push(newAccount);
            set({ accounts });
        },
        removeAccount: (address) => {
            const accounts = [...get().accounts];
            const removeAccountIndex = accounts.findIndex((account) => account.address === address);
            if (removeAccountIndex > -1) {
                accounts.splice(removeAccountIndex, 1);
                set({ accounts });
            }
        },
        mainAccount: (chainId) => {
            for (const account of get().accounts) {
                if (account.chainId === chainId) {
                    if (account.walletType !== WalletType.viewing) {
                        return account;
                    }
                }
            }
        },
        activeAccount: (chainId) => {
            return get().accounts.findLast((account) => {
                return account.chainId === chainId;
            });
        },
        walletType: () => {
            let hasViewingWalletType = false;
            for (const account of get().accounts) {
                if (account.walletType === WalletType.viewing) {
                    hasViewingWalletType = true;
                }
                else {
                    return account.walletType;
                }
            }
            if (hasViewingWalletType) {
                return WalletType.viewing;
            }
        },
    };
}, accountStoreOptions)));
