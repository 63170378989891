import { queryOptions, useQuery } from "@tanstack/react-query"
import { useStore } from "zustand"
import { useAccount } from "@levana/chain/account"

import type CosmosClient from "@perps/sdk/client/CosmosClient"
import { MINUTE } from "@common/utils/date"
import type { MarketId } from "@perps/sdk/types"
import type { ContextStoreProp } from "@future/context/store"
import {
  tradeHistorySummaryFromResp,
  type TradeHistorySummaryMap,
} from "@future/market/history/types"
import { Usd } from "@future/numerics"
import type { FullMarketInfo } from "@perps/sdk/client/CosmosClient"

import type { QueryOptions } from "./types"

export const marketsTradeHistorySummaryQueryOptions = (
  chainClient: CosmosClient,
  walletAddress: string | undefined,
  markets: Map<MarketId, FullMarketInfo>,
) => {
  return queryOptions({
    queryKey: ["marketsTradeHistorySummary", walletAddress, markets],
    refetchInterval: MINUTE,
    queryFn: async (context) => {
      const fullMarketInfos = [...markets.values()]
      const map: TradeHistorySummaryMap = new Map()

      if (!walletAddress) {
        return fullMarketInfos.reduce((acc, fullMarketInfo) => {
          acc.set(fullMarketInfo.config.id, {
            realizedPnl: new Usd(0),
            tradeVolume: new Usd(0),
          })
          return acc
        }, map)
      }

      const allSummariesResp = await Promise.all(
        fullMarketInfos.map(async (fullMarketInfo) => {
          const resp = await chainClient.queryTradeHistorySummary({
            marketAddress: fullMarketInfo.config.marketAddress,
            owner: walletAddress,
            abortSignal: context.signal,
          })
          return { resp, marketId: fullMarketInfo.config.id }
        }),
      )

      return allSummariesResp.reduce((acc, { resp, marketId }) => {
        acc.set(marketId, tradeHistorySummaryFromResp(resp))
        return acc
      }, map)
    },
  })
}

export const useMarketsTradeHistorySummaryQuery = (
  props: ContextStoreProp<"standard">,
  options?: QueryOptions,
) => {
  const client = useStore(props.contextStore, (state) => state.chain.client)
  const { data: account } = useAccount()
  const markets = useStore(props.contextStore, (state) => state.markets)
  return useQuery({
    ...marketsTradeHistorySummaryQueryOptions(
      client,
      account?.address,
      markets,
    ),
    ...options,
  })
}
