import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const AppsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path d="M5.4001 4.79998C5.0701 4.79998 4.8001 5.06998 4.8001 5.39998V8.99998C4.8001 9.32998 5.0701 9.59998 5.4001 9.59998H9.0001C9.3301 9.59998 9.6001 9.32998 9.6001 8.99998V5.39998C9.6001 5.06998 9.3301 4.79998 9.0001 4.79998H5.4001ZM3.6001 5.39998C3.6001 4.40623 4.40635 3.59998 5.4001 3.59998H9.0001C9.99385 3.59998 10.8001 4.40623 10.8001 5.39998V8.99998C10.8001 9.99373 9.99385 10.8 9.0001 10.8H5.4001C4.40635 10.8 3.6001 9.99373 3.6001 8.99998V5.39998ZM5.4001 14.4C5.0701 14.4 4.8001 14.67 4.8001 15V18.6C4.8001 18.93 5.0701 19.2 5.4001 19.2H9.0001C9.3301 19.2 9.6001 18.93 9.6001 18.6V15C9.6001 14.67 9.3301 14.4 9.0001 14.4H5.4001ZM3.6001 15C3.6001 14.0062 4.40635 13.2 5.4001 13.2H9.0001C9.99385 13.2 10.8001 14.0062 10.8001 15V18.6C10.8001 19.5937 9.99385 20.4 9.0001 20.4H5.4001C4.40635 20.4 3.6001 19.5937 3.6001 18.6V15ZM18.6001 4.79998H15.0001C14.6701 4.79998 14.4001 5.06998 14.4001 5.39998V8.99998C14.4001 9.32998 14.6701 9.59998 15.0001 9.59998H18.6001C18.9301 9.59998 19.2001 9.32998 19.2001 8.99998V5.39998C19.2001 5.06998 18.9301 4.79998 18.6001 4.79998ZM15.0001 3.59998H18.6001C19.5938 3.59998 20.4001 4.40623 20.4001 5.39998V8.99998C20.4001 9.99373 19.5938 10.8 18.6001 10.8H15.0001C14.0063 10.8 13.2001 9.99373 13.2001 8.99998V5.39998C13.2001 4.40623 14.0063 3.59998 15.0001 3.59998ZM15.0001 14.4C14.6701 14.4 14.4001 14.67 14.4001 15V18.6C14.4001 18.93 14.6701 19.2 15.0001 19.2H18.6001C18.9301 19.2 19.2001 18.93 19.2001 18.6V15C19.2001 14.67 18.9301 14.4 18.6001 14.4H15.0001ZM13.2001 15C13.2001 14.0062 14.0063 13.2 15.0001 13.2H18.6001C19.5938 13.2 20.4001 14.0062 20.4001 15V18.6C20.4001 19.5937 19.5938 20.4 18.6001 20.4H15.0001C14.0063 20.4 13.2001 19.5937 13.2001 18.6V15Z" />
    </SvgIcon>
  )
}

export default AppsIcon
