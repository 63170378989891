import { useEffect, useMemo, useState } from "react"
import { QueryObserver } from "@tanstack/react-query"
import { useLoaderData } from "react-router-dom"
import { setChainInfo } from "@levana/chain/provider"
import type { PropsWithFunctionChildren } from "@levana-protocol/utils/react"

import { queryClient } from "@future/network/client"
import { targetConfigQueryOptions } from "@future/network/targetConfig"
// import { AppError } from "@future/libs/error/AppError"
import type { PerpsRoute } from "@future/router/Router"
import { marketsQueryOptions } from "@future/network/markets"
import type { FullMarketInfo } from "@perps/sdk/client/CosmosClient"
import {
  defaultMarketSlug,
  getDefaultTargetInfo,
  visitedTargetMarketSlugStorage,
  visitedTargetSlugStorage,
} from "@future/router/utils"
import type { MarketId } from "@perps/sdk/types"
import { MarketSlug } from "@future/market/config/constants"
import { geoblockedQueryOptions } from "@future/network/geoblocked"
import type { TargetInfo } from "@future/target/info"
import { setAnalyticsChain, track } from "@perps/analytics/track"
import { Event } from "@perps/analytics/events"
import {
  RouteRootId,
  type InitialRouteState,
  type MarketRouteState,
  type Route,
  type StandardRouteState,
} from "@future/router/types"
import { createChain } from "@future/context/createChain"
import { useSettingsStore } from "@perps/settings/settingsStore"
import type { EventWithData } from "@perps/analytics/eventData"

import { domainConfig } from "../target/domain"
import {
  type ContextStore,
  createContextStore,
  type InitialContextStoreState,
  type StandardContextStoreProps,
} from "./store"
import { type MarketLookup, createMarketLookup } from "./createMarketLookup"

const SetupContext = (
  props: PropsWithFunctionChildren<unknown, ContextStore<"initial">>,
) => {
  const initialContextStoreState = useInitialContextStoreState()

  const [contextStore] = useState(() =>
    createContextStore({
      route: initialContextStoreState.route,
      routeState: { rootId: "unknown", case: "initializing" },
      targetInfo: initialContextStoreState.targetInfo,
    }),
  )

  const rpcEndpoint = useSettingsStore((state) =>
    state.endpoint(initialContextStoreState.targetInfo.id),
  )

  useEffect(() => {
    const { route, targetInfo, isFallbackTargetInfo } = initialContextStoreState

    if (isFallbackTargetInfo) {
      contextStore.setState({
        routeState: { rootId: "unknown", case: "unknown" },
      })
      return
    }

    const lifecycle = { unmount: false }
    let unsubscribeMarketsObserver: () => void | undefined

    const handleContextState = async (route: Route, targetInfo: TargetInfo) => {
      try {
        const geoblocked = await queryClient.fetchQuery(
          geoblockedQueryOptions(),
        )
        if (lifecycle.unmount) {
          return
        }

        const isGeoblocked =
          route.rootId === "stats" ? false : !geoblocked.allowed

        if (isGeoblocked) {
          track(Event.geoblocked({ countryCode: geoblocked.countryCode }))

          contextStore.setState({
            routeState: { rootId: "unknown", case: "geoblocked" },
          })
          return
        }

        const targetConfig = await queryClient.fetchQuery(
          targetConfigQueryOptions(targetInfo),
        )
        if (lifecycle.unmount) {
          return
        }

        if (targetConfig.maintenance) {
          contextStore.setState({
            routeState: {
              rootId: "unknown",
              case: "maintenance",
              message: targetConfig.maintenance,
            },
          })
          return
        }

        const chain = await createChain(targetConfig, targetInfo, rpcEndpoint)
        if (lifecycle.unmount) {
          return
        }

        setAnalyticsChain(targetInfo.id)

        const marketsOptions = marketsQueryOptions(chain.client, targetInfo)
        const markets = await queryClient.fetchQuery(marketsOptions)
        if (lifecycle.unmount) {
          return
        }

        const marketLookup = createMarketLookup(
          contextStore.getState().marketLookup,
          markets,
        )

        const [routeState, fullMarketInfo] = getContextStoreRouteProps({
          route,
          markets,
          marketLookup,
        })

        if (!fullMarketInfo) {
          contextStore.setState({ routeState })
          return
        }

        updateFallbackMarketSlug(targetInfo, fullMarketInfo.config.slug)

        setChainInfo({
          chainId: chain.config.chainId,
          rpc: chain.config.rpcUrl,
        })
        contextStore.setState({
          route,
          routeState,
          targetInfo,
          chain,
          fullMarketInfo,
          markets,
          marketLookup,
        } satisfies StandardContextStoreProps)

        const marketsObserver = new QueryObserver(queryClient, marketsOptions)

        unsubscribeMarketsObserver = marketsObserver.subscribe((result) => {
          if (result.data) {
            const fullMarketInfoProps: GetFullMarketInfoProps = {
              route,
              markets: result.data,
              marketLookup,
            }

            const fullMarketInfo =
              getFullMarketInfo(fullMarketInfoProps) ??
              getFallbackFullMarketInfo(fullMarketInfoProps)

            contextStore.setState({
              markets: result.data,
              ...(fullMarketInfo && { fullMarketInfo }),
            })
          }
        })
      } catch (_error) {
        // TODO: combine these results with the target query error
      }
    }

    handleContextState(route, targetInfo)

    return () => {
      lifecycle.unmount = true
      unsubscribeMarketsObserver?.()
    }
  }, [contextStore, initialContextStoreState, rpcEndpoint])

  // TODO: an error thrown inside the handleContextState should be thrown here so the error boundary catches it since this is a non-recoverable stage
  // if (targetConfigQuery.isError) {
  //   throw AppError.fromText(
  //     `Could not fetch target config for ${targetInfo.id}`,
  //     { cause: targetConfigQuery.error },
  //   )
  // } else

  return props.children?.(contextStore)
}

interface UseInitialContextStoreStateProps
  extends Pick<InitialContextStoreState, "route" | "targetInfo"> {
  isFallbackTargetInfo: boolean
}

const useInitialContextStoreState = (): UseInitialContextStoreStateProps => {
  // TODO: improve the types here. a lot of casting is happening
  const route = useLoaderData() as PerpsRoute | undefined

  const [targetInfo, isFallbackTargetInfo] = (() => {
    const targetSlug =
      route && "targetSlug" in route ? route.targetSlug : undefined

    if (targetSlug) {
      const targetInfo = domainConfig.getTargetBySlug(targetSlug)

      if (targetInfo) {
        return [targetInfo, false]
      } else {
        return [getDefaultTargetInfo(), true]
      }
    }

    return [getDefaultTargetInfo(), false]
  })()

  const marketSlug =
    route && "marketSlug" in route
      ? typeof route.marketSlug === "string"
        ? MarketSlug.get(route.marketSlug)
        : route.marketSlug
      : undefined

  const fallbackMarketSlug = (() => {
    const marketSlug = visitedTargetMarketSlugStorage.get()[targetInfo.slug]

    if (marketSlug) {
      return MarketSlug.get(marketSlug)
    }

    return defaultMarketSlug(targetInfo.id)
  })()

  useEffect(() => {
    if (targetInfo.slug) {
      visitedTargetSlugStorage.set(targetInfo.slug)
    }
  }, [targetInfo.slug])

  const trackMarketSlug = marketSlug?.slug ?? fallbackMarketSlug.slug

  useEffect(() => {
    if (!route?.route) {
      return
    }

    const event = (): EventWithData => {
      switch (route.route) {
        case RouteRootId.earn:
          return Event.viewPage("earn", trackMarketSlug)
        case RouteRootId.history:
          return Event.viewPage("history")
        case RouteRootId.leaderboard:
          return Event.viewPage("leaderboard", trackMarketSlug)
        case RouteRootId.markets:
          return Event.viewPage("markets")
        case RouteRootId.stats:
          return Event.viewPage("stats")
        case RouteRootId.trade:
          return Event.viewPage("trade", trackMarketSlug)
      }
    }

    track(event())
  }, [route, trackMarketSlug])

  return useMemo(
    () => ({
      route: {
        targetSlug: targetInfo.slug,
        rootId: route?.route,
        fallbackRootId: RouteRootId.trade,
        marketSlug,
        fallbackMarketSlug,
      },
      targetInfo,
      isFallbackTargetInfo,
    }),
    [
      route?.route,
      marketSlug,
      fallbackMarketSlug,
      targetInfo,
      isFallbackTargetInfo,
    ],
  )
}

interface GetFullMarketInfoProps {
  route: Route
  markets: Map<MarketId, FullMarketInfo>
  marketLookup: MarketLookup
}

const getFullMarketInfo = (props: GetFullMarketInfoProps) => {
  const marketId = props.route.marketSlug
    ? props.marketLookup.slugToId(props.route.marketSlug)
    : undefined
  const market = marketId ? props.markets.get(marketId) : undefined

  if (market) {
    return props.markets.get(market.config.id)
  }
}

const getFallbackFullMarketInfo = (props: GetFullMarketInfoProps) => {
  const marketId =
    props.marketLookup.slugToId(props.route.fallbackMarketSlug) ??
    props.marketLookup.ids.at(0)
  const market = marketId ? props.markets.get(marketId) : undefined

  if (market) {
    return props.markets.get(market.config.id)
  }
}

const getContextStoreRouteProps = (
  props: GetFullMarketInfoProps,
):
  | [InitialRouteState, undefined]
  | [StandardRouteState | MarketRouteState, FullMarketInfo] => {
  const fullMarketInfo = getFullMarketInfo(props)
  const fallbackFullMarketInfo = getFallbackFullMarketInfo(props)
  const eitherFullMarketInfo = fullMarketInfo ?? fallbackFullMarketInfo

  switch (props.route.rootId) {
    case undefined:
      return [
        {
          rootId: "unknown",
          case: "unknown",
        },
        undefined,
      ]

    case RouteRootId.markets:
    case RouteRootId.history:
    case RouteRootId.stats:
      if (eitherFullMarketInfo) {
        return [
          {
            rootId: props.route.rootId,
            case: "found",
          },
          eitherFullMarketInfo,
        ]
      }

      return [
        {
          rootId: "unknown",
          case: "unknown",
        },
        undefined,
      ]

    case RouteRootId.trade:
    case RouteRootId.earn:
    case RouteRootId.leaderboard: {
      if (!props.route.marketSlug) {
        return [
          {
            rootId: "unknown",
            case: "unknown",
          },
          undefined,
        ]
      }

      if (fullMarketInfo) {
        return [
          {
            rootId: props.route.rootId,
            case: "found",
          },
          fullMarketInfo,
        ]
      }

      if (fallbackFullMarketInfo) {
        return [
          {
            rootId: props.route.rootId,
            case: "missing",
            newRoute: {
              route: props.route.rootId,
              targetSlug: props.route.targetSlug,
              marketSlug: fallbackFullMarketInfo.config.slug,
            },
          },
          fallbackFullMarketInfo,
        ]
      }

      return [
        {
          rootId: "unknown",
          case: "unknown",
        },
        undefined,
      ]
    }
  }
}

const updateFallbackMarketSlug = (
  targetInfo: TargetInfo,
  marketSlug: MarketSlug,
) => {
  const newMap = {
    ...visitedTargetMarketSlugStorage.get(),
    [targetInfo.slug]: marketSlug.slug,
  }
  visitedTargetMarketSlugStorage.set(newMap)
}

export default SetupContext
