import { useTheme } from "@mui/joy/styles"
import useMediaQuery from "@mui/system/useMediaQuery"
import { useEffect, type PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"
import { useModalTopKey } from "@levana-protocol/utils/modal"

import {
  OnboardStepPresenter,
  useCreateOnboardCategory,
  useOnboardStepLayout,
} from "@future/utils/onboard"

type TradeMarketOnboardStep =
  | "collateralField"
  | "takeProfitField"
  | "earnNavItem"
  | "discordMenu"

const tradeMarketSteps: TradeMarketOnboardStep[] = [
  "collateralField",
  "takeProfitField",
  "earnNavItem",
  "discordMenu",
]

export const useTradeMarketOnboard = () => {
  return useCreateOnboardCategory("TradeMarket", tradeMarketSteps)
}

export const CollateralFieldOnboard = (props: PropsWithChildren) => {
  const onboard = useTradeMarketOnboard()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"))
  const { t } = useTranslation("perpsTradeMarket")

  return (
    <OnboardStepPresenter<TradeMarketOnboardStep>
      onboard={onboard}
      step="collateralField"
      title={t("onboarding.step", { index: 1, total: 4 })}
      content={t("onboarding.collateral.title")}
      button={t("onboarding.collateral.button")}
      buttonAriaLabel="onboarding goto take profit"
      anchorPlacement={isSmallScreen ? "top" : "left"}
    >
      {props.children}
    </OnboardStepPresenter>
  )
}

export const TakeProfitFieldOnboard = (props: PropsWithChildren) => {
  const onboard = useTradeMarketOnboard()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"))
  const { t } = useTranslation("perpsTradeMarket")

  return (
    <OnboardStepPresenter<TradeMarketOnboardStep>
      onboard={onboard}
      step="takeProfitField"
      title={t("onboarding.step", { index: 2, total: 4 })}
      content={t("onboarding.takeProfit.title")}
      button={t("onboarding.takeProfit.button")}
      buttonAriaLabel="onboarding goto earn"
      anchorPlacement={isSmallScreen ? "top" : "left"}
    >
      {props.children}
    </OnboardStepPresenter>
  )
}

export const EarnNavItemOnboard = (props: PropsWithChildren) => {
  const onboard = useTradeMarketOnboard()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"))
  const { t } = useTranslation("perpsTradeMarket")

  return (
    <OnboardStepPresenter<TradeMarketOnboardStep>
      onboard={onboard}
      step="earnNavItem"
      title={t("onboarding.step", { index: 3, total: 4 })}
      content={t("onboarding.earn.title")}
      button={t("onboarding.earn.button")}
      buttonAriaLabel="onboarding goto discord"
      anchorPlacement={isSmallScreen ? "bottom" : "right"}
    >
      {props.children}
    </OnboardStepPresenter>
  )
}

export const DiscordNavItemOnboard = (props: PropsWithChildren) => {
  const onboard = useTradeMarketOnboard()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"))
  const { t } = useTranslation("perpsTradeMarket")

  return (
    <OnboardStepPresenter<TradeMarketOnboardStep>
      onboard={onboard}
      step="discordMenu"
      title={t("onboarding.step", { index: 4, total: 4 })}
      content={t("onboarding.discord.title")}
      button={t("onboarding.discord.button")}
      buttonAriaLabel="onboarding done"
      anchorPlacement={isSmallScreen ? "bottom" : "left"}
    >
      {props.children}
    </OnboardStepPresenter>
  )
}

export const useMenuOnboard = () => {
  const onboard = useTradeMarketOnboard()
  const topKey = useModalTopKey()
  const { zIndex } = useOnboardStepLayout()

  return {
    step: onboard.step,
    enabled: onboard.enabled,
    zIndex:
      topKey === "earnNavItem" || topKey === "discordMenu" ? zIndex : undefined,
  }
}

type TradeFeeOnboardStep = "pnlFee"

const tradeFeeSteps: TradeFeeOnboardStep[] = ["pnlFee"]

export const useTradeFeeOnboard = () => {
  return useCreateOnboardCategory("TradeFee", tradeFeeSteps)
}

export const PnlFeeOnboard = (props: PropsWithChildren<{ index: number }>) => {
  if (props.index === 0) {
    return <PnlFeeOnboardContent>{props.children}</PnlFeeOnboardContent>
  } else {
    return props.children
  }
}

const PnlFeeOnboardContent = (props: PropsWithChildren) => {
  const onboard = useTradeFeeOnboard()
  const tradeMarketOnboard = useTradeMarketOnboard()
  const { t } = useTranslation("perpsTradeMarket")

  useEffect(() => {
    onboard.setup()
  }, [onboard.setup])

  useEffect(() => {
    if (tradeMarketOnboard.done) {
      onboard.start()
    }
  }, [onboard.start, tradeMarketOnboard.done])

  return (
    <OnboardStepPresenter<TradeFeeOnboardStep>
      onboard={onboard}
      step="pnlFee"
      content={t("onboarding.fee.title")}
      button={t("onboarding.fee.button")}
      buttonAriaLabel="fee onboarding done"
      anchorPlacement="top"
    >
      {props.children}
    </OnboardStepPresenter>
  )
}
