import { useNavigate } from "@future/router/useNavigate"
import { useTradeMarketOnboard } from "@future/pages/tradeMarket/onboard"

import type { ActionNavItem } from "./navItems"

export interface UseActionButtonProps {
  navItem: ActionNavItem
}

export const useActionButton = (props: UseActionButtonProps) => {
  const { navItem } = props
  const navigate = useNavigate()
  const onboard = useTradeMarketOnboard()

  const handleAction = (): null => {
    if (navItem.onboardWrapper) {
      onboard.next()
    }

    switch (navItem.action.type) {
      case "handler":
        navItem.action.handler()
        return null

      case "href":
        window.open(navItem.action.href, "_blank", "noopener")
        return null

      case "router":
        navigate(navItem.action.router)
        return null
    }
  }

  return { handleAction }
}
