import { useChainStore } from "../shared/store";
import { WalletType } from "../shared/wallet";
import { compassWallet } from "./compass";
import { cosmostationWallet } from "./cosmostation";
import { keplrWallet } from "./keplr";
import { leapWallet } from "./leap";
import { leapMetaMaskWallet } from "./leapMetaMask";
import { metaMaskWallet } from "./metaMask";
import { okxWallet } from "./okx";
import { viewingWallet } from "./viewing";
import { walletConnectWallet } from "./walletConnect";
import { xdefiWallet } from "./xdefi";
export const getWallet = (walletType) => {
    const { chainId } = useChainStore.getState();
    switch (walletType) {
        case WalletType.compass:
            return compassWallet;
        case WalletType.cosmostation:
        case WalletType.cosmostationMobile:
            return cosmostationWallet;
        case WalletType.keplr:
        case WalletType.keplrMobile:
            return keplrWallet;
        case WalletType.leap:
        case WalletType.leapMobile:
            return leapWallet;
        case WalletType.metamask:
            return metaMaskWallet.info.isChainSupported(chainId)
                ? metaMaskWallet
                : leapMetaMaskWallet;
        case WalletType.okx:
            return okxWallet;
        case WalletType.viewing:
            return viewingWallet;
        case WalletType.walletConnect:
            return walletConnectWallet;
        case WalletType.xdefi:
            return xdefiWallet;
    }
};
