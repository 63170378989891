import Box from "@mui/joy/Box"
import Button from "@mui/joy/Button"
import FormControl, { type FormControlProps } from "@mui/joy/FormControl"
import FormHelperText from "@mui/joy/FormHelperText"
import FormLabel from "@mui/joy/FormLabel"
import Input from "@mui/joy/Input"
import Stack from "@mui/joy/Stack"
import Skeleton from "@mui/joy/Skeleton"
import Link from "@mui/joy/Link"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import { useStore } from "zustand"
import { useConnectedWallet } from "@levana/chain/wallet"
import { isCosmosAddress } from "@levana/chain/cosmos"
import { useAccount } from "@levana/chain/account"

import CircleCheckIcon from "@common/icons/CircleCheckIcon"
import type { ContextStore, ContextStoreProp } from "@future/context/store"
import {
  type ReferralStats,
  useReferralStatsQuery,
} from "@future/network/referralStats"
import { Report } from "@future/libs/error/report"
import { AppError } from "@future/libs/error/AppError"
import { notification } from "@future/notification"
import { govUrl } from "@future/target/domain"
import { RouteDynamicRootId } from "@future/router/types"

import { useReferralStore, referralMap } from "./referralCode"

const ReferrerSection = (props: ContextStoreProp<"standard">) => {
  const { t } = useTranslation("perpsCommon")
  const [submitting, setSubmitting] = useState(false)
  const [referral, setReferral] = useState<string>()

  const { data: connectedWallet } = useConnectedWallet()
  const explorerHashUrl = useStore(
    props.contextStore,
    (state) => state.chain.config.explorerHashUrl,
  )
  const externalReferralCode = useReferralStore(
    (state) => state.externalReferralCode,
  )
  const referralStatsQuery = useReferralStatsQuery(props)
  const referrerCodeState = useReferrerCodeState(
    props.contextStore,
    referralStatsQuery.data,
    referral ?? externalReferralCode,
  )

  const handleSubmit = async () => {
    if (!connectedWallet) {
      Report.message(
        "Submit execRegisterReferrer without walletSession",
        "warning",
      )
      return
    }

    if (!referrerCodeState.referrerAddress) {
      Report.message(
        "Submit execRegisterReferrer without referrerAddress",
        "warning",
      )
      return
    }

    const { client } = props.contextStore.getState().chain

    try {
      setSubmitting(true)

      const transaction = await client.execRegisterReferrer({
        walletAddress: connectedWallet.account.address,
        signAndBroadcast: connectedWallet.signAndBroadcast,
        referrerAddress: referrerCodeState.referrerAddress,
      })

      await referralStatsQuery.refetch()

      notification.transactionSuccess({
        href: explorerHashUrl(transaction.transactionHash),
      })
    } catch (error) {
      notification.error(
        AppError.fromError(error, {
          text: "Unable to register referrer",
          presentable: true,
        }),
      )
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Stack spacing={1}>
      <FormControl color={stepToColor(referrerCodeState.step)}>
        <FormLabel>{t("referral.referrer.title")}</FormLabel>
        <Skeleton
          loading={!referralStatsQuery.data && referralStatsQuery.isFetching}
          variant="rectangular"
        >
          <Input
            endDecorator={
              referrerCodeState.step === ReferrerCodeStep.present && (
                <CircleCheckIcon color="success" />
              )
            }
            value={referrerCodeState.referrerCode}
            placeholder={t("referral.referrer.placeholder")}
            onChange={(event) => setReferral(event.target.value)}
            disabled={submitting}
            size="lg"
            slotProps={{
              input: {
                spellCheck: "false",
                sx: ({ vars }) => ({
                  color: vars.palette.text.secondary,
                }),
              },
            }}
            aria-label="referrer code"
          />
        </Skeleton>
        {referralStatsQuery.data && referrerCodeState.message && (
          <FormHelperText>{referrerCodeState.message}</FormHelperText>
        )}
      </FormControl>
      {!referralStatsQuery.data?.referrerAddress && (
        <Button
          onClick={handleSubmit}
          disabled={
            !connectedWallet ||
            !referralStatsQuery.data ||
            !referrerCodeState.referrerAddress
          }
          loading={submitting}
          aria-label="referrer accept"
        >
          {t("referral.referrer.button")}
        </Button>
      )}
    </Stack>
  )
}

enum ReferrerCodeStep {
  absent,
  invalid,
  valid,
  present,
}

interface ReferrerCodeState {
  step: ReferrerCodeStep
  message: React.ReactNode | undefined
  referrerCode: string
  referrerAddress?: string
}

const useReferrerCodeState = (
  contextStore: ContextStore<"standard">,
  referralStats: ReferralStats | undefined,
  referrer: string | undefined,
): ReferrerCodeState => {
  const { t } = useTranslation("perpsCommon")
  const addressPrefix = useStore(
    contextStore,
    (state) => state.chain.config.addressPrefix,
  )
  const { data: account } = useAccount()

  if (referralStats?.referrerAddress) {
    return {
      step: ReferrerCodeStep.present,
      message: (
        <Box>
          {t("referral.referrer.message.associated.start")}
          <Link
            target="_blank"
            href={`${govUrl}/vesting`}
            sx={({ vars }) => ({ fontSize: vars.fontSize.xs2 })}
            aria-label="vesting page"
          >
            {t("referral.referrer.message.associated.link")}
          </Link>
          {t("referral.referrer.message.associated.end")}
        </Box>
      ),
      referrerCode:
        referralMap.getAddressToHandle(referralStats.referrerAddress) ??
        referralStats.referrerAddress,
      referrerAddress: referralStats.referrerAddress,
    }
  }

  if (referrer && account) {
    const parsedAddress = parseReferrerAddress(referrer, addressPrefix)

    if (parsedAddress === account.address) {
      return {
        step: ReferrerCodeStep.invalid,
        message: t("referral.referrer.message.selfReferral"),
        referrerCode: referrer,
      }
    }

    if (parsedAddress) {
      return {
        step: ReferrerCodeStep.valid,
        message: t("referral.referrer.message.valid"),
        referrerCode: referrer,
        referrerAddress: parsedAddress,
      }
    }

    return {
      step: ReferrerCodeStep.invalid,
      message: t("referral.referrer.message.invalid"),
      referrerCode: referrer,
    }
  }

  return {
    step: ReferrerCodeStep.absent,
    message: undefined,
    referrerCode: "",
  }
}

const parseReferrerAddress = (referrer: string, addressPrefix: string) => {
  try {
    const hostname = "trade.levana.finance"

    const referrerUrl = (() => {
      try {
        return new URL(referrer)
      } catch (error) {
        const url = new URL(`https://${referrer}`)

        if (url.hostname === hostname) {
          return url
        }

        throw error
      }
    })()

    if (referrerUrl.hostname === hostname) {
      const pathComponents = referrerUrl.pathname.split("/")

      if (
        pathComponents.length === 3 &&
        pathComponents[1] === RouteDynamicRootId.referral
      ) {
        const referrerCode = pathComponents[2]

        if (isCosmosAddress(referrerCode, addressPrefix)) {
          return referrerCode
        } else {
          return referralMap.getHandleToAddress(referrerCode, addressPrefix)
        }
      }
    }
  } catch {
    if (isCosmosAddress(referrer, addressPrefix)) {
      return referrer
    } else {
      return referralMap.getHandleToAddress(referrer, addressPrefix)
    }
  }
}

const stepToColor = (
  step: ReferrerCodeStep,
): NonNullable<FormControlProps["color"]> => {
  switch (step) {
    case ReferrerCodeStep.invalid:
      return "danger"
    case ReferrerCodeStep.valid:
      return "success"
    case ReferrerCodeStep.absent:
    case ReferrerCodeStep.present:
      return "neutral"
  }
}

export default ReferrerSection
