import { queryOptions } from "@tanstack/react-query"
import { SECOND } from "@levana-protocol/utils/date"

import { queryQuerier } from "@perps/sdk/client/query"

interface DropRewardsResponse {
  balance: string | null
}

export const dropletsQueryOptions = (walletAddress: string) => {
  return queryOptions({
    queryKey: ["droplets", walletAddress],
    refetchInterval: SECOND * 30,
    queryFn: async (context) => {
      return queryQuerier<DropRewardsResponse>(
        "/v1/chain/neutron-drop-rewards",
        "Unable to fetch drop rewards",
        context.signal,
        { wallet: walletAddress },
        { disablePresentation: true },
      )
    },
  })
}
