import { ChainProvider, type ChainProviderProps } from "@levana/chain/provider"
import {
  ErrorMessage,
  WalletErrorType,
  getWalletErrorType,
} from "@levana/chain/error"
import { setUser as SentrySetUser } from "@sentry/react"
import { useMemo, type PropsWithChildren } from "react"
import i18next from "i18next"

import { Report } from "@future/libs/error/report"
import { extractFailureReason } from "@future/libs/error/utils"
import { appDescription, appTitle, appUrl } from "@perps/constants"
import { Event } from "@perps/analytics/events"
import { track } from "@perps/analytics/track"
import { notification } from "@future/notification"
import { AppError } from "@future/libs/error/AppError"
import { querierUrl } from "@future/target/domain"
import { useSettingsStore } from "@perps/settings/settingsStore"

import {
  chainIdToChainNetworkId,
  chainInfos,
  chainNetworkIdToChainName,
} from "./utils"
import { walletErrorToKeyOptions } from "./error"

const ChainContainer = (props: PropsWithChildren) => {
  const options = useMemo<ChainProviderProps["options"]>(() => {
    return {
      cosmos: {
        /**
         * All chains need to be supported up front for proper multi-chain
         * support. For instance, connecting to Osmosis, closing the tab and
         * opening a new tab on Neutron.
         */
        chains: chainInfos,
        chainsConfig: chainInfos.reduce(
          (chainsConfig, chainInfo) => {
            const feeCurrencie = chainInfo.feeCurrencies.at(0)

            if (feeCurrencie?.gasPriceStep) {
              chainsConfig[chainInfo.chainId] = {
                gas: {
                  price: feeCurrencie.gasPriceStep.low.toString(),
                  denom: feeCurrencie.coinMinimalDenom,
                },
              }
            }

            return chainsConfig
          },
          {} as NonNullable<
            NonNullable<
              NonNullable<ChainProviderProps["options"]>["cosmos"]
            >["chainsConfig"]
          >,
        ),
        querierUrl,
        networkId: (chainId) => {
          const chainNetworkId = chainIdToChainNetworkId(chainId)

          if (!chainNetworkId) {
            throw new Error(`Unable to map chain ID (${chainId}) to network ID`)
          }

          return chainNetworkId
        },
        gasMultiplier: (chainId) => {
          const override = useSettingsStore.getState().gasMultiplier(chainId)

          if (override) {
            return Number(override)
          }

          const chainNetworkId = chainIdToChainNetworkId(chainId)
          const defaultGasMultiplier = 1.4

          if (!chainNetworkId) {
            return defaultGasMultiplier
          }

          switch (chainNetworkIdToChainName(chainNetworkId)) {
            case "osmosis":
            case "neutron":
              return 1.6
            case "injective":
            case "sei":
            case "dymension":
              return defaultGasMultiplier
          }
        },
        ...(process.env.WALLET_CONNECT_ID && {
          walletConnect: {
            options: {
              projectId: process.env.WALLET_CONNECT_ID,
              metadata: {
                name: appTitle,
                description: appDescription,
                url: appUrl,
                icons: [
                  "https://static.levana.finance/icons/levana-silhouette-round.svg",
                ],
              },
            },
          },
        }),
      },
    }
  }, [])

  return (
    <ChainProvider
      options={options}
      onConnected={handleConnected}
      onDisconnected={handleDisconnected}
      onConnectError={handleError}
    >
      {props.children}
    </ChainProvider>
  )
}

const identifyUser = (walletAddress: string | null | undefined) => {
  if (walletAddress) {
    SentrySetUser({ id: walletAddress })
    gtag("event", "login", { address: walletAddress })
  } else {
    SentrySetUser(null)
  }
}

const handleConnected: ChainProviderProps["onConnected"] = (account) => {
  identifyUser(account.address)

  Report.setTag("connector.id", account.walletType)

  Report.addBreadcrumb({
    level: "info",
    type: "user",
    category: "wallet",
    message: "Wallet connected",
    data: { address: account.address },
  })

  track(
    Event.connectWallet.succeeded({
      wallet: account.walletType,
    }),
  )
}

const handleDisconnected = () => {
  identifyUser(null)

  Report.addBreadcrumb({
    level: "info",
    type: "user",
    category: "wallet",
    message: "Wallet disconnected",
  })
}

const handleError: ChainProviderProps["onConnectError"] = (error) => {
  const errorType = getWalletErrorType(error.message)

  if (errorType === WalletErrorType.rejected) {
    Report.addBreadcrumb({
      level: "info",
      type: "user",
      category: "wallet",
      message: "Wallet rejected",
    })
  }

  const errorOptions = walletErrorToKeyOptions(errorType) ?? {
    key: "wallet.connectingFailed",
  }

  const appError = (() => {
    if (error.message === ErrorMessage.disableCtrl) {
      return AppError.fromText(error.message)
    }

    return AppError.fromError(error, errorOptions)
  })()

  if (appError.level === "warning") {
    const t = i18next.getFixedT(null, "error")
    notification.warning(appError.toString(t))
  } else {
    notification.error(appError)
  }

  if (!appError.disablePresentation) {
    track(Event.connectWallet.failed({}, extractFailureReason(appError)))
  }
}

export default ChainContainer
