import type { MarketConfig } from "@future/market/config/types"
import { tokenId } from "@perps/sdk/token"

import {
  type ElementsAssetToken,
  type ElementsChainName,
  elementsAssetTokenRecord,
} from "./config"
import type { ElementsContentProps } from "./ElementsContent"

interface ElementsValuesFromProps<From extends ElementsChainName> {
  fromChainName: From
  fromToken: ElementsAssetToken<From>
}

interface ElementsValuesToProps<To extends ElementsChainName> {
  toChainName: To
  toMarketConfig: MarketConfig | undefined
}

type ElementsValuesProps<
  From extends ElementsChainName,
  To extends ElementsChainName,
> = ElementsValuesFromProps<From> & ElementsValuesToProps<To>

export const elementsValues = <
  From extends ElementsChainName,
  To extends ElementsChainName,
>(
  props: ElementsValuesProps<From, To>,
): ElementsContentProps["values"] => {
  const fromChainId = elementsAssetTokenRecord[props.fromChainName].chainId
  const toChainId = elementsAssetTokenRecord[props.toChainName].chainId
  const fromChainTokens: Record<string, string> =
    elementsAssetTokenRecord[props.fromChainName].token
  const toTokenId = props.toMarketConfig
    ? tokenId(props.toMarketConfig.collateralToken)
    : ""

  return {
    sourceChainId: fromChainId,
    sourceAsset: fromChainTokens[props.fromToken as string],
    destinationChainId: toChainId,
    destinationAsset: toTokenId,
  }
}
