import SvgIcon, { type SvgIconProps } from "@mui/joy/SvgIcon"

const InjectiveIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      <path
        d="M11.6674 12.8024C11.804 12.6307 11.9487 12.4655 12.0935 12.3003C12.1 12.2921 12.1147 12.2904 12.1212 12.2823C12.1342 12.2659 12.1554 12.2561 12.1684 12.2397L12.1814 12.2233C12.2823 12.1301 12.3897 12.0285 12.5135 11.9401C12.9515 11.6076 13.4061 11.3549 13.8854 11.1887C15.4228 10.649 17.1342 10.9816 18.4763 12.2486C20.3502 14.0051 20.1824 16.8349 18.6865 18.7157C16.7962 21.5195 13.5477 25.4313 18.0455 28.9354C18.8543 29.5655 19.4542 30.085 22.0017 30.8212C20.3356 31.1281 18.7907 31.0326 17.0712 30.5934C15.8549 29.9069 13.9427 28.437 13.2921 26.4516C12.3088 23.441 15.0233 18.9403 16.3351 17.2069C18.1363 14.808 15.2219 12.211 13.0762 15.1103C11.9546 16.6212 9.99243 20.8968 10.6743 24.0682C11.073 25.8666 11.6044 27.1776 13.7115 28.9784C13.3208 28.7478 12.9413 28.4863 12.5733 28.1937C7.67763 23.6334 8.2456 16.5823 11.6674 12.8024Z"
        fill="url(#paint0_linear_2391_95891)"
      />
      <path
        d="M28.3328 27.1977C28.1962 27.3694 28.0515 27.5346 27.9067 27.6998C27.9002 27.708 27.8855 27.7097 27.879 27.7179C27.866 27.7342 27.8448 27.744 27.8318 27.7604L27.8188 27.7768C27.7179 27.87 27.6105 27.9716 27.4867 28.06C27.0487 28.3925 26.5941 28.6452 26.1148 28.8114C24.5774 29.3511 22.866 29.0185 21.5239 27.7515C19.65 25.995 19.8178 23.1652 21.3137 21.2845C23.204 18.4806 26.4525 14.5688 21.9547 11.0647C21.1459 10.4346 20.546 9.91509 17.9985 9.17891C19.6646 8.87199 21.2095 8.96746 22.9289 9.40667C24.1453 10.0932 26.0575 11.5631 26.7081 13.5485C27.6914 16.5591 24.9769 21.0598 23.6651 22.7932C21.8639 25.1921 24.7783 27.7891 26.924 24.8898C28.0456 23.3789 30.0078 19.1034 29.3259 15.9319C28.9272 14.1335 28.3958 12.8225 26.2887 11.0217C26.6794 11.2523 27.0589 11.5138 27.4269 11.8064C32.3226 16.3667 31.7546 23.4178 28.3328 27.1977Z"
        fill="url(#paint1_linear_2391_95891)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2391_95891"
          x1="9"
          y1="20.0001"
          x2="31.0002"
          y2="20.0001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0082FA" />
          <stop offset="1" stopColor="#00F2FE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2391_95891"
          x1="9.00002"
          y1="20"
          x2="31.0002"
          y2="20"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0082FA" />
          <stop offset="1" stopColor="#00F2FE" />
        </linearGradient>
      </defs>
    </SvgIcon>
  )
}

export default InjectiveIcon
