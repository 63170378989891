import type { OfflineAminoSigner } from "@cosmjs/amino"
import { ConnectedAgentType, type ConnectedAgent } from "@levana/chain/account"
import { SigningType } from "@levana/chain/signing"
import {
  NotifiContextProvider,
  type NotifiContextProviderProps,
} from "@notifi-network/notifi-react"
import { ErrorBoundary } from "@sentry/react"

import { Report } from "@future/libs/error/report"
import { AppError } from "@future/libs/error/AppError"

import NotifiButton from "./NotifiButton"

export interface NotifiContextWrapperProps {
  connectedAgent: ConnectedAgent
}

const NotifiContextWrapper = (props: NotifiContextWrapperProps) => {
  const { connectedAgent } = props

  if (connectedAgent.type !== ConnectedAgentType.cosmos) {
    return null
  }

  const walletBlockchain = ((): Extract<
    NotifiContextProviderProps["walletBlockchain"],
    "OSMOSIS" | "INJECTIVE"
  > => {
    switch (connectedAgent.signing.type) {
      case SigningType.cosmWasm:
        return "OSMOSIS"
      case SigningType.injective:
        return "INJECTIVE"
    }
  })()

  const cardId = ((): string => {
    switch (connectedAgent.signing.type) {
      case SigningType.cosmWasm:
        return "362f11d13aaa450ab46931b0fd736d7b"
      case SigningType.injective:
        return "061be5d273ae49209b0c925d738e5131"
    }
  })()

  const publicKey =
    "publicKey" in connectedAgent.account
      ? connectedAgent.account.publicKey
      : undefined

  if (!walletBlockchain || !publicKey) {
    // account and signer is required
    return null
  }

  const signMessageOsmosis = async (
    message: string,
  ): Promise<{ signatureBase64: string; signedMessage: string }> => {
    switch (connectedAgent.signing.type) {
      case SigningType.cosmWasm: {
        const signingClient = await connectedAgent.signing.client()

        if ("signer" in signingClient) {
          const hackSigner = signingClient[`${"signer"}`] as OfflineAminoSigner
          const signer = connectedAgent.account.address

          if ("leap" in hackSigner) {
            const leap = hackSigner[`${"leap"}`] as {
              signArbitrary: (
                chainId: string,
                signer: string,
                data: unknown,
              ) => Promise<{
                signature: string
              }>
            }

            if ("signArbitrary" in leap) {
              const chainId = await signingClient.getChainId()
              const result = await leap.signArbitrary(chainId, signer, message)
              return {
                signatureBase64: result.signature,
                signedMessage: message,
              }
            } else {
              Report.message("Unable to connect Leap to notifi", "error")
            }
          } else if ("keplr" in hackSigner) {
            const keplr = hackSigner[`${"keplr"}`] as {
              signArbitrary: (
                chainId: string,
                signer: string,
                data: unknown,
              ) => Promise<{
                signature: string
              }>
            }

            if ("signArbitrary" in keplr) {
              const chainId = await signingClient.getChainId()
              const result = await keplr.signArbitrary(chainId, signer, message)
              return {
                signatureBase64: result.signature,
                signedMessage: message,
              }
            } else {
              Report.message("Unable to connect keplr to notifi", "error")
            }
          } else if ("signAmino" in hackSigner) {
            const signdoc = {
              chain_id: await signingClient.getChainId(),
              account_number: "0",
              sequence: "0",
              fee: {
                gas: "0",
                amount: [],
              },
              msgs: [
                {
                  type: "sign/MsgSignData",
                  value: {
                    signer,
                    data: Buffer.from(message).toString("base64"),
                  },
                },
              ],
              memo: "",
            }
            const result = await hackSigner.signAmino(signer, signdoc)
            return {
              signatureBase64: result.signature.signature,
              signedMessage: message,
            }
          }
        }
      }
    }
    return { signatureBase64: "", signedMessage: message }
  }

  const signMessageInjective = async (
    message: Uint8Array,
  ): Promise<Uint8Array> => {
    switch (connectedAgent.signing.type) {
      case SigningType.injective: {
        const signingClient = await connectedAgent.signing.client()

        const result = await signingClient.options.walletStrategy.signArbitrary(
          connectedAgent.account.address,
          message,
        )
        if (!result) {
          return null as unknown as Uint8Array
        }
        return Buffer.from(result, "base64")
      }
      default:
        return null as unknown as Uint8Array
    }
  }

  const contextProviderProps = (() => {
    switch (walletBlockchain) {
      case "OSMOSIS":
        return { walletBlockchain, signMessage: signMessageOsmosis }
      case "INJECTIVE":
        return { walletBlockchain, signMessage: signMessageInjective }
    }
  })()

  return (
    <ErrorBoundary
      onError={(error) => {
        Report.error(
          AppError.fromError(error, {
            text: "Presenting Notifi",
            level: "fatal",
          }),
        )
      }}
    >
      <NotifiContextProvider
        {...contextProviderProps}
        tenantId="levanafinance"
        env="Production"
        cardId={cardId}
        walletPublicKey={publicKey}
        accountAddress={connectedAgent?.account?.address}
        notificationCountPerPage={15}
        inputs={{
          walletAddress: [
            { label: "", value: connectedAgent?.account?.address },
          ],
        }}
      >
        <NotifiButton />
      </NotifiContextProvider>
    </ErrorBoundary>
  )
}

export default NotifiContextWrapper
