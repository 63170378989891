var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useChainStore } from "../shared/store";
import { ConnectedAgentType, } from "../shared/account";
import { getChain } from "../shared/chain";
import { cosmWasmSignAndBroadcast, cosmWasmSigningClient } from "./signing";
import { SigningType } from "../shared/signing";
import { suggestChain } from "./chain";
import { getWallet } from "../wallet";
const connect = (chainId, walletType) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c;
    const wallet = getWallet(walletType);
    const provider = wallet.provider();
    yield ((_a = provider.init) === null || _a === void 0 ? void 0 : _a.call(provider));
    yield ((_b = provider.enable) === null || _b === void 0 ? void 0 : _b.call(provider, chainId));
    return yield ((_c = provider.getKey) === null || _c === void 0 ? void 0 : _c.call(provider, chainId));
});
export const cosmosConnect = (strategy) => __awaiter(void 0, void 0, void 0, function* () {
    const { chainId } = useChainStore.getState();
    yield suggestChain({
        chainInfo: getChain(chainId),
        walletType: strategy.walletType,
    });
    const key = yield connect(chainId, strategy.walletType);
    if (!key) {
        throw new Error("Unable to get key from provider");
    }
    const publicKey = Buffer.from(key.pubKey).toString("base64");
    const account = {
        walletType: strategy.walletType,
        address: key.bech32Address,
        ethereumAddress: key.ethereumHexAddress,
        publicKey,
        chainId,
    };
    return cosmosConnectedAgent(account);
});
export const cosmosConnectedAgent = (account) => {
    return {
        type: ConnectedAgentType.cosmos,
        account,
        signAndBroadcast: (instructions, options) => {
            return cosmWasmSignAndBroadcast(account, instructions, options);
        },
        signing: {
            type: SigningType.cosmWasm,
            client: () => cosmWasmSigningClient(account.chainId),
        },
    };
};
export const cosmosDisconnect = () => {
    return Promise.resolve();
};
