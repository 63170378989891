var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { fromBech32 } from "@cosmjs/encoding";
import { getWallet } from "../wallet";
import { useChainStore } from "../shared/store";
export const isCosmosAddress = (address, prefix) => {
    try {
        const decoded = fromBech32(address);
        if (decoded.data.length === 20 || decoded.data.length === 32) {
            return prefix !== undefined ? decoded.prefix === prefix : true;
        }
        return false;
    }
    catch (_a) {
        return false;
    }
};
export const suggestChain = (args) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const wallet = getWallet(args.walletType);
    yield ((_b = (_a = wallet.provider()).experimentalSuggestChain) === null || _b === void 0 ? void 0 : _b.call(_a, args.chainInfo));
});
export const getChainInfo = (chainId) => {
    return useChainStore
        .getState()
        .chains.find((chain) => chain.chainId === chainId);
};
