import type {
  LpActionKind,
  LpActionResp,
  MarketId,
  TradeHistorySummaryResp,
} from "@perps/sdk/types"
import { nanoToMilli } from "@perps/sdk/utils"
import { Collateral, LpToken, Usd } from "@future/numerics"

export type TradeHistorySummaryMap = Map<MarketId, TradeHistorySummary>

export interface TradeHistorySummary {
  /**
   * Given in usd
   */
  realizedPnl: Usd
  /**
   * Given in usd
   */
  tradeVolume: Usd
}

export const tradeHistorySummaryFromResp = (
  resp: TradeHistorySummaryResp,
): TradeHistorySummary => {
  return {
    realizedPnl: new Usd(resp.realized_pnl),
    tradeVolume: new Usd(resp.trade_volume),
  }
}

export type LpActionHistoryMap = Map<MarketId, LpAction[]>
export interface LpAction {
  /**
   * Amount of collateral
   */
  collateral: Collateral
  /**
   * Value of that collateral in USD at the time
   */
  collateralUsd: Usd
  /**
   * Kind of action
   */
  kind: LpActionKind
  /**
   * When the action happened
   */
  timestampMs: number
  /**
   * How many tokens were involved, if relevant
   */
  tokens?: LpToken
}

export const lpActionFromResp = (resp: LpActionResp): LpAction => {
  return {
    collateral: new Collateral(resp.collateral),
    collateralUsd: new Usd(resp.collateral_usd),
    kind: resp.kind,
    timestampMs: nanoToMilli(resp.timestamp),
    tokens: resp.tokens ? new LpToken(resp.tokens) : undefined,
  }
}
