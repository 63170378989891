import type { MarketConfig } from "@future/market/config/types"
import {
  type Leverage,
  type Notional,
  Quote,
  type TakeProfit,
  type Usd,
} from "@future/numerics"
import type { DirectionToBase } from "@perps/sdk/types"

/**
 * Params for the calcEstimatedPnl method
 * @param  {DirectionToBase}  direction          The position's direction. Long, or short
 * @param  {MarketType}       marketType         The type of market. Either `collateral_is_base` or `collateral_is_quote`
 * @param  {Notional}         notionalSize       The notional size of the new or old position.
 * @param  {Quote}            entryPrice         The entry price of the new or old position.
 * @param  {TakeProfit}       takeProfitPrice    The take profit price of the new or old position.
 * @param  {Quote}            stopLossPrice      The stop loss price of the new or old position.
 * @param  {Quote}            liquidationPrice   The liquidation of the new or old position.
 * @param  {Leverage}         initialLeverage    The leverage of the new or old position.
 * @param  {Usd}              fees               The current total fees of the new or old position.
 */
interface CalcEstimatedPnlProps {
  direction: DirectionToBase
  marketConfig: MarketConfig
  notionalSize: Notional
  entryPrice: Quote
  takeProfitPrice: TakeProfit
  stopLossPrice: Quote | undefined
  liquidationPrice: Quote | undefined
  initialLeverage: Leverage | undefined
  fees: Usd
}

/**
 * Method that will return the estimated profit and loss of a position, or of a new we wish to create.
 * It take a CalcEstimatedPnlProps as param.
 * Please ensure that the values passed are that of either an active position, or of a new position. Do NOT pass the current market price.
 *
 * @param  {CalcEstimatedPnlProps} props The params for the calcEstimatedPnl method
 * @return {CalcEstimatedPnlProps} props The params for the calcEstimatedPnl method
 */
export const calcEstimatedPnl = (props: CalcEstimatedPnlProps) => {
  let newEntryPrice = props.entryPrice
  let newTakeProfitPrice = new Quote(props.takeProfitPrice.toAmount())
  let newStopLossPrice = props.stopLossPrice ?? props.liquidationPrice
  const originalStopLoss = props.stopLossPrice ?? props.liquidationPrice
  const fees = new Quote(props.fees.toNumber())
  const marketConfig = props.marketConfig
  const notionalSize = new Quote(props.notionalSize.toNumber())

  if (!newStopLossPrice || !props.initialLeverage || !originalStopLoss) {
    return {
      estimatedProfit: new Quote(0),
      estimatedLoss: new Quote(0),
    }
  } else {
    let estimatedProfit = new Quote(0)
    let estimatedLoss = new Quote(0)

    if (marketConfig.type === "collateral_is_quote") {
      estimatedProfit = notionalSize.times(
        newTakeProfitPrice.minus(newEntryPrice),
      )
      estimatedLoss = notionalSize.times(newStopLossPrice.minus(newEntryPrice))
    } else {
      const leverage_to_notional =
        props.direction === "long"
          ? 1 - props.initialLeverage.toNumber()
          : 1 - props.initialLeverage.toNumber() * -1
      newEntryPrice = new Quote(1).divide(newEntryPrice)
      newTakeProfitPrice = new Quote(1).divide(newTakeProfitPrice)
      newStopLossPrice = new Quote(1).divide(newStopLossPrice)

      const collateral = notionalSize
        .times(newEntryPrice)
        .divide(leverage_to_notional)

      const original_value = collateral.times(props.entryPrice)

      estimatedProfit = notionalSize
        .times(newTakeProfitPrice.minus(newEntryPrice))
        .plus(collateral)
      estimatedProfit = estimatedProfit
        .times(props.takeProfitPrice)
        .minus(original_value)

      estimatedLoss = notionalSize
        .times(newStopLossPrice.minus(newEntryPrice))
        .plus(collateral)
      estimatedLoss = estimatedLoss
        .times(originalStopLoss)
        .minus(original_value)
    }

    estimatedProfit = estimatedProfit.minus(fees)
    estimatedLoss = estimatedLoss.minus(fees)

    return {
      estimatedProfit,
      estimatedLoss,
    }
  }
}
