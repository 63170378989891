import { useStore } from "zustand"

import type { ContextStoreProp } from "@future/context/store"
import type { ActionNavItem } from "@future/header/navItems"
import { domainConfig } from "@future/target/domain"
import { useNavigate } from "@future/router/useNavigate"
import { Event } from "@perps/analytics/events"
import { EventKey } from "@perps/analytics/constants"
import { track } from "@perps/analytics/track"

const chooseChainTargetInfos = domainConfig.availableTargets
  .filter((targetInfo) => !targetInfo.hideInNetworkList)
  .sort((a, b) => {
    const aFirst = a.name.at(0)
    const bFirst = b.name.at(0)
    const aLower = (aFirst ?? a.name).toLowerCase()
    const bLower = (bFirst ?? b.name).toLowerCase()
    const lowerCaseComparison = aLower.localeCompare(bLower)

    if (lowerCaseComparison !== 0) {
      return lowerCaseComparison
    }

    const isACapitalized = aFirst === aFirst?.toUpperCase()
    const isBCapitalized = bFirst === bFirst?.toUpperCase()

    if (isACapitalized && !isBCapitalized) {
      return -1
    }
    if (!isACapitalized && isBCapitalized) {
      return 1
    }

    return 0
  })

export const useChooseChainNavItems = (props: ContextStoreProp<"initial">) => {
  const navigate = useNavigate()
  const route = useStore(
    props.contextStore,
    (state) => state.route.rootId ?? state.route.fallbackRootId,
  )
  const marketSlug = useStore(
    props.contextStore,
    (state) => state.route.marketSlug ?? state.route.fallbackMarketSlug,
  )

  return chooseChainTargetInfos.map((targetInfo): ActionNavItem => {
    return {
      title: targetInfo.name,
      icon: () => (
        <targetInfo.icon
          sx={{
            backgroundColor: targetInfo.iconBackgroundColor,
            borderRadius: "50%",
          }}
        />
      ),
      id: targetInfo.slug,
      type: "action",
      action: {
        type: "handler",
        handler: () => {
          track(
            Event.networkSelected({
              [EventKey.CHAIN]: targetInfo.id,
              [EventKey.NETWORK]: targetInfo.slug,
            }),
          )
          navigate({
            targetSlug: targetInfo.slug,
            route,
            marketSlug,
          })
        },
      },
    }
  })
}
