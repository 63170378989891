import {
  truncateAddress,
  useConnect,
  useDisconnect,
  useMainAccount,
  useViewingAccounts,
  type ConnectedAgent,
} from "@levana/chain/account"
import Box from "@mui/joy/Box"
import Button from "@mui/joy/Button"
import Drawer from "@mui/joy/Drawer"
import IconButton, { iconButtonClasses } from "@mui/joy/IconButton"
import List from "@mui/joy/List"
import ListItem from "@mui/joy/ListItem"
import ListItemButton from "@mui/joy/ListItemButton"
import ListItemContent from "@mui/joy/ListItemContent"
import Stack from "@mui/joy/Stack"
import Typography from "@mui/joy/Typography"
import { create, useStore } from "zustand"
import { useTranslation } from "react-i18next"
import { useEffect } from "react"
import { WalletType } from "@levana/chain/wallet"

import type { ContextStoreProp } from "@future/context/store"
import { track } from "@perps/analytics/track"
import { Event } from "@perps/analytics/events"
import NotifiContextWrapper from "@future/notifi/NotifiContextWrapper"
import { notification } from "@future/notification"
import CopyIcon from "@common/icons/CopyIcon"
import XIcon from "@common/icons/XIcon"
import { useNotifiNavItemOnboard } from "@future/notifi/onboard"

import PowerIcon from "./icons/PowerIcon"
import { useNavItemFactory } from "./navItems"
import { useActionButton, type UseActionButtonProps } from "./useActionButton"
import AccountSummary from "./AccountSummary"

interface AccountDrawerProps extends ContextStoreProp<"standard"> {
  connectedWallet: ConnectedAgent
}

const AccountDrawer = (props: AccountDrawerProps) => {
  const { t } = useTranslation("common")
  const open = useAccountDrawerStore((state) => state.open)

  useEffect(() => {
    if (open) {
      track(Event.openWalletMenu())
    }
  }, [open])

  const handleClose = () => {
    useAccountDrawerStore.setState({ open: false })
  }

  const onboard = useNotifiNavItemOnboard()

  const handleNotifiClick = () => {
    onboard.next()
    handleClose()
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      slotProps={{
        content: {
          sx: { maxWidth: 330, p: 2 },
        },
      }}
    >
      <Stack
        direction="column"
        spacing={2}
        role="presentation"
        sx={{ maxHeight: "100%" }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={({ spacing }) => ({
            "--Button-radius": spacing(3),
            "--Button-paddingBlock": "0",
            "--IconButton-radius": spacing(3),
            [`.${iconButtonClasses.root} > *`]: {
              maxWidth: spacing(3),
              maxHeight: spacing(3),
            },
          })}
        >
          <Typography
            level="body-md"
            sx={{ alignSelf: "flex-end", lineHeight: 1 }}
          >
            {t("wallet.menu.address.title")}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box onClick={handleNotifiClick}>
            <NotifiContextWrapper connectedAgent={props.connectedWallet} />
          </Box>
          <DisconnectButton />
        </Stack>
        <AccountList
          contextStore={props.contextStore}
          connectedWallet={props.connectedWallet}
        />
        <AccountActions
          contextStore={props.contextStore}
          connectedWallet={props.connectedWallet}
        />
        <AccountSummary contextStore={props.contextStore} />
      </Stack>
    </Drawer>
  )
}

const AccountList = (props: AccountDrawerProps) => {
  const { t } = useTranslation("common")
  const mainAccount = useMainAccount()
  const viewingAccounts = useViewingAccounts()
  const { createCopyAccount } = useCopyAccount()

  const chainName = useStore(
    props.contextStore,
    (state) => state.chain.config.chainName,
  )

  const { connect } = useConnect()

  const handleConnect = (account: ReturnType<typeof createCopyAccount>) => {
    if (account.walletType === WalletType.viewing) {
      connect({
        walletType: WalletType.viewing,
        address: account.address,
      })
    } else if (account.walletType) {
      connect({ walletType: account.walletType })
    }
  }

  const { disconnect } = useDisconnect()

  const handleRemove = (address: string) => {
    disconnect(address)
  }

  if (!mainAccount) {
    return null
  }

  const accounts: ReturnType<typeof createCopyAccount>[] = []

  for (const viewingAccount of viewingAccounts) {
    accounts.push(
      createCopyAccount({
        title: t("wallet.menu.address.viewing"),
        address: viewingAccount.address,
        walletType: viewingAccount.walletType,
      }),
    )
  }

  accounts.sort((a, b) => a.address.localeCompare(b.address))

  if (chainName === "injective" && "ethereumAddress" in mainAccount) {
    const { ethereumAddress } = mainAccount
    accounts.unshift(
      createCopyAccount({
        title: t("wallet.menu.address.ethereum"),
        address: ethereumAddress,
      }),
    )
  }

  accounts.unshift(
    createCopyAccount({
      title: t("wallet.menu.address.main"),
      address: mainAccount.address,
      walletType: mainAccount.walletType,
    }),
  )

  if (accounts.length === 0) {
    return null
  }

  return (
    <List
      sx={({ spacing }) => ({
        "--List-gap": spacing(1),
        "--List-padding": spacing(2),
        mx: spacing(-2),
        py: 0,
        overflowY: "auto",
        minHeight: accounts.length > 2 ? 145 : 44, // 44 ~= 1 row
      })}
    >
      {accounts.map((account) => (
        <ListItem
          key={account.address}
          startAction={
            <IconButton
              onClick={account.handleCopy}
              aria-label={`copy ${account.title.toLowerCase()}`}
            >
              <CopyIcon fontSize="xl2" />
            </IconButton>
          }
          endAction={
            account.walletType === WalletType.viewing ? (
              <IconButton
                color="danger"
                onClick={() => handleRemove(account.address)}
                aria-label="remove account"
              >
                <XIcon fontSize="xs3" />
              </IconButton>
            ) : undefined
          }
        >
          <ListItemButton
            variant="outlined"
            color={
              account.address === props.connectedWallet.account.address
                ? "primary"
                : "neutral"
            }
            disabled={account.walletType === undefined}
            onClick={() => handleConnect(account)}
            aria-label="switch account"
          >
            <ListItemContent>
              <Typography level="body-md" textColor="text.primary">
                {account.title}
              </Typography>
              <Typography level="body-sm" textColor="text.secondary">
                {account.subtitle}
              </Typography>
            </ListItemContent>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
}

interface CopyAccount {
  title: string
  address: string
  walletType?: WalletType
}

const useCopyAccount = () => {
  const { t } = useTranslation("common")

  const handleCopy = (account: CopyAccount) => {
    track(Event.copyWalletAddress())
    useAccountDrawerStore.setState({ open: false })
    navigator.clipboard.writeText(account.address)
    notification.success(t("wallet.menu.address.notification"))
  }

  const createCopyAccount = (account: CopyAccount) => {
    return {
      walletType: account.walletType,
      address: account.address,
      title: account.title,
      subtitle: truncateAddress(account.address),
      handleCopy: () => handleCopy(account),
    }
  }

  return { createCopyAccount }
}

const DisconnectButton = () => {
  const { disconnect } = useDisconnect()

  const handleDisconnect = () => {
    track(Event.disconnectWallet())
    useAccountDrawerStore.setState({ open: false })
    // Timeout allows the animation to start
    setTimeout(disconnect, 0)
  }

  return (
    <IconButton
      variant="outlined"
      onClick={handleDisconnect}
      aria-label="disconnect"
    >
      <PowerIcon size="sm" />
    </IconButton>
  )
}

const AccountActions = (props: AccountDrawerProps) => {
  const factory = useNavItemFactory(props)
  const marketConfig = useStore(
    props.contextStore,
    (state) => state.fullMarketInfo.config,
  )
  const network = useStore(
    props.contextStore,
    (state) => state.chain.config.network,
  )

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        "& > *": {
          flex: 1,
        },
      }}
    >
      {network === "testnet" && (
        <AccountActionButton
          navItem={factory.faucet(props.contextStore, props.connectedWallet)}
        />
      )}
      {marketConfig && (
        <AccountActionButton
          navItem={factory.crank(
            props.contextStore,
            marketConfig,
            props.connectedWallet,
          )}
        />
      )}
      <AccountActionButton navItem={factory.settings(props.contextStore)} />
    </Stack>
  )
}

const AccountActionButton = (props: UseActionButtonProps) => {
  const { handleAction } = useActionButton(props)

  return (
    <Button
      color="neutral"
      variant="outlined"
      size="sm"
      onClick={() => {
        useAccountDrawerStore.setState({ open: false })
        handleAction()
      }}
      aria-label={props.navItem.title.toLowerCase()}
    >
      {props.navItem.title}
    </Button>
  )
}

interface AccountDrawerStoreProps {
  open: boolean
}

export const useAccountDrawerStore = create<AccountDrawerStoreProps>(() => ({
  open: false,
}))

export default AccountDrawer
